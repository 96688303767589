export const initialState = {
  companyLogo: '',
  companyLogoBlobName: '',
  bannerImage: '',
  bannerImageBlobName: '',
  bannerText: '',
  botGreeting: '',
  cards: [
    {
      id: 1,
      title: '',
      description: '',
      type: null,
      icon: null,
      static_link: '',
      intent_hierarchy: [],
      intent_hierarchy_url: '',
      offer: null,
    },
  ],
  isRezolveFeaturesEnabled: false,
  isShowBrowseServiceCatalog: false,
  rezolveFeatures: {
    recentTickets: {
      label: 'Recent Tickets',
      title: 'Recent Tickets',
      icon: `<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="37" height="37" rx="9.5" fill="#8463F9" fill-opacity="0.15"/>
<rect x="0.5" y="0.5" width="37" height="37" rx="9.5" stroke="#8463F9"/>
<path d="M10.75 19H27.25M10.75 22.75H27.25M10.75 26.5H27.25M12.625 11.5H25.375C26.4105 11.5 27.25 12.3395 27.25 13.375C27.25 14.4105 26.4105 15.25 25.375 15.25H12.625C11.5895 15.25 10.75 14.4105 10.75 13.375C10.75 12.3395 11.5895 11.5 12.625 11.5Z" stroke="#8463F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
    },
  },
  icons: [],
};

export const CARD_TYPES = [
  { value: 'static_link', label: 'Static Link' },
  { value: 'intent_hierarchy', label: 'Intent Hierarchy' },
  { value: 'offer', label: 'Offer' },
];

export const ICONS = ['./Icons/icon1.png'];

export const importAll = (r) => r.keys().map(r);

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
  reader.onerror = (error) => {
    console.error('Error: ', error);
  };
};

export const convertIntentIdToPath = (intents, targetId) => {
  const search = (items, path) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const formattedName = item.name.toLowerCase().replace(/ /g, '_');
      const currentPath = path ? `${path}/${formattedName}` : formattedName;

      if (item.id === targetId) {
        return currentPath;
      }

      if (item.items && item.items.length > 0) {
        const result = search(item.items, currentPath);
        if (result) return result;
      }
    }
    return null;
  };

  return search(intents, '');
};
