import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomDatePicker from '../../../components/Datepicker/AntdDatePicker';

import { COLORS } from '../../../utils/enums/colors';
import {
  STATUSES,
  createFilterDropdown,
  createFilterDropdownMap,
  getDateRange,
  setDateRange,
  statusesMap,
} from '../utils';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';

import scssStyles from '../cardLayout.module.scss';
import quickFilterStyles from './quickFilterStyles.module.scss';
import { QUICK_FILTERS_LABELS } from '../../../utils/enums/labels';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

dayjs.extend(utc);

const styles = {
  ...customStyleFromRuleForSelect,
  menu: (...selectProps) => {
    const menuStyle = customStyleFromRuleForSelect.menu(...selectProps);
    delete menuStyle.top;
    return {
      ...menuStyle,
      fontSize: 14,
    };
  },
  input: (provided) => {
    const inputStyles = customStyleFromRuleForSelect.input(provided);
    return {
      ...inputStyles,
      fontSize: 14,
    };
  },
};

export const DateFilter = ({
  dateRangeColumn,
  handleChange,
  dateRanges,
  fromCreateFilter,
}) => {
  const dateRangeStyles = useMemo(
    () => ({
      ...styles,
      control: (provided, state) => {
        const controlValues = styles.control(provided, state);
        const controlStyles = {
          ...controlValues,
          minWidth: 150,
          height: fromCreateFilter ? '36px' : '40px',
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          borderRightColor: 'transparent',
          '&:hover': {
            // borderRadius: 6,
            borderColor: '#d1d5db',
          },
        };

        if (state.isFocused || state.menuIsOpen) {
          Object.assign(controlStyles, {
            // borderTopRightRadius: 6,
            // borderBottomRightRadius: 6,
            borderRightColor: '#2563EB',
          });
        }

        return controlStyles;
      },
      // menu: (...props) => {
      //   const menuStyles = styles.menu(...props);
      //   // if (!fromCreateFilter) {
      //   //   delete menuStyles.fontSize;
      //   // }
      //   return menuStyles;
      // },
      // input: (provided) => {
      //   const inputStyles = styles.menu(provided);
      //   // if (!fromCreateFilter) {
      //   //   delete inputStyles.fontSize;
      //   // }
      //   return inputStyles;
      // },
    }),
    [fromCreateFilter]
  );

  const dateRangeValue = useMemo(() => {
    const formattedDateRange = getDateRange(dateRanges);
    return formattedDateRange;
  }, [dateRanges]);

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <>
      <Box>
        <CustomSelectBox
          value={dateRangeColumn}
          options={createFilterDropdown}
          onChange={(selectedOption) => {
            handleChange('dateRangeColumn', selectedOption);
          }}
          size="md"
          styles={dateRangeStyles}
          id="DateFilterSelectBox"
        />
      </Box>

      <Box
        className={scssStyles.datePicker}
        data-testid="DateFilterDatePickerBox"
      >
        <CustomDatePicker
          value={dateRangeValue}
          onChange={(val) => {
            handleChange('dateRanges', setDateRange(val));
          }}
          dateFormat="MMM DD, YYYY"
          isRangeSelector
          popupStyle={{
            zIndex: 9999,
          }}
          customHeight={fromCreateFilter ? 36 : 40}
          disabledDate={disableFutureDates}
        />
      </Box>
    </>
  );
};

const AddQuickFilter = ({
  activeTab,
  selectedQuickFilter,
  onSaveApplyFilterClick,
  onApplyQuickFilter,
}) => {
  const initialNewFilterState = useMemo(
    () => ({
      filterName: '',
      dateRangeColumn: createFilterDropdown[0],
      dateRanges: [],
      selectedLifeCycle: STATUSES[0],
    }),
    []
  );

  const [filterData, setFilterData] = useState(initialNewFilterState);

  const handleChange = (key, val) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const onReset = () => {
    setFilterData({ ...initialNewFilterState });
  };

  const onButtonClick = (e, type) => {
    e.stopPropagation();
    const payload={
      ...filterData,
      filterName:filterData?.filterName?.trim()
    }
    switch (type) {
      case 'APPLY':
        onApplyQuickFilter(payload);
        break;
      case 'SAVE_AND_APPLY':
        onSaveApplyFilterClick(payload);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onReset();
  }, [activeTab]);

  useEffect(() => {
    if (selectedQuickFilter) {
      setFilterData({
        ...selectedQuickFilter,
        dateRanges: selectedQuickFilter.dateRanges,
        dateRangeColumn: {
          label: createFilterDropdownMap[selectedQuickFilter.dateRangeColumn],
          value: selectedQuickFilter.dateRangeColumn,
        },
        selectedLifeCycle: {
          label: statusesMap[selectedQuickFilter.selectedLifeCycle],
          value: selectedQuickFilter.selectedLifeCycle,
        },
      });
    } else {
      onReset();
    }
  }, [selectedQuickFilter]);

  const isButtonsDisabled = useMemo(
    () =>
      !(
        filterData?.dateRanges?.length === 1 &&
        filterData?.dateRanges[0]?.split('-')?.length === 2
      ) || !filterData?.filterName,
    [filterData]
  );

  return (
    <>
      <Box>
        <FormControl isRequired data-testid="AddQuickFilterFormControl">
          <FormLabel fontSize="14px" data-testid="AddQuickFilterFormLabel">
            {QUICK_FILTERS_LABELS.FILTER_NAME}
          </FormLabel>
          <Input
            value={filterData?.filterName}
            onChange={(e) => e.target.value === '' || /\S/.test(e.target.value) ? handleChange('filterName', e.target.value) : null}
            placeholder={QUICK_FILTERS_LABELS.FILTER_NAME}
            height="36px"
            fontSize="14px"
            data-testid="AddQuickFilterNameInput"
          />
        </FormControl>

        <FormControl
          mt="15px"
          isRequired
          data-testid="AddQuickFilterLifeCycleFoemControl"
        >
          <FormLabel
            fontSize="14px"
            data-testid="AddQuickFilterLifeCycleFormLabel"
          >
            {QUICK_FILTERS_LABELS.STATUS}
          </FormLabel>
          <CustomSelectBox
            value={filterData?.selectedLifeCycle}
            options={STATUSES}
            onChange={(selectedOption) => {
              handleChange('selectedLifeCycle', selectedOption);
            }}
            size="md"
            styles={styles}
          />
        </FormControl>

        <FormControl
          mt="15px"
          isRequired
          data-testid="AddQuickFilterContainerFormComtrol"
        >
          <FormLabel fontSize="14px">{QUICK_FILTERS_LABELS.FILTERS}</FormLabel>
          <Flex data-testid="AddQuickFilterDateFlex">
            <DateFilter
              dateRangeColumn={filterData?.dateRangeColumn}
              dateRanges={filterData?.dateRanges}
              handleChange={handleChange}
              fromCreateFilter
            />
          </Flex>
        </FormControl>
      </Box>

      <Box
        data-testid="AddQuickFilterButtonBox"
        className={quickFilterStyles.createfilter__button__parent}
        background={useColorModeValue('white', 'var(--drawer-bg)')}
      >
        <Box className={quickFilterStyles.createfilter__button__container}>
          <SecondaryCustomButton
            classname="calculatedHeight"
            buttonText={QUICK_FILTERS_LABELS.CLEAR_FILTER}
            onClick={onReset}
            id="AddQuickFilterButtonClearBtn"
          />
          <SecondaryCustomButton
            buttonText={QUICK_FILTERS_LABELS.APPLY}
            classname="calculatedHeight"
            // variant="outline"
            // borderColor={COLORS.LIGHT_BORDER}
            // colorScheme="black"
            isDisabled={isButtonsDisabled}
            onClick={(e) => {
              onButtonClick(e, 'APPLY');
            }}
            id="AddQuickFilterButtonApplyBtn"
          />

          <PrimaryCustomButton
            buttonText={QUICK_FILTERS_LABELS.SAVE_AND_APPLY}
            onClick={(e) => {
              onButtonClick(e, 'SAVE_AND_APPLY');
            }}
            onMouseDown={(e) => e.preventDefault()}
            isDisabled={isButtonsDisabled}
            id="AddQuickFilterButtonSabeBtn"
          />
        </Box>
      </Box>
    </>
  );
};

DateFilter.propTypes = {
  dateRangeColumn: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  dateRanges: PropTypes.array.isRequired,
  fromCreateFilter: PropTypes.bool,
};

DateFilter.defaultProps = {
  fromCreateFilter: false,
};

AddQuickFilter.propTypes = {
  selectedQuickFilter: PropTypes.object,
  onSaveApplyFilterClick: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  onApplyQuickFilter: PropTypes.func.isRequired,
};

AddQuickFilter.defaultProps = {
  selectedQuickFilter: {},
};

export default AddQuickFilter;
