/* eslint-disable indent */
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  useTheme,
  Icon,
  Box
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa'; // FaTrashAlt is an outlined trash icon

import { DeleteIcon } from '@chakra-ui/icons';
import { ReactComponent as DelIcon } from '../../assets/icons/DeleteIcon.svg';
import { ReactSVG } from 'react-svg';
import TableColumnText from '../../components/Text/TableColumnText';
import metaLinkIcon from '../../assets/icons/metaLinkIcon.svg';
import {
  META_FIELD_KEY,
} from './LinkedMetaFieldDrawer/utils';
import { PRE_FIELDS_WITH_ASSOCIATIONS, PRE_FIELDS_NO_ASSOCIATED } from '../../redux/MetaFieldsSlice/MetaFieldsService';
import CustomToast from '../../components/Notification/Notification';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { DangerCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const valueWithPlus = (value) => {
  const thresholds = [10, 100, 1000, 10000, 100000];

  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (value >= thresholds[i]) {
      const roundedValue = Math.floor(value / thresholds[i]) * thresholds[i];
      return `${roundedValue}+`;
    }
  }
  if (value === 0 || value < 10) {
    return value;
  }
  return 0;
};
export const renderUpdatedInfo = (rowData) => (
  <div className="text-center inline-flex align-middle">
    <p className="text-[14px]">
      {valueWithPlus(rowData[META_FIELD_KEY.FIELD_DATA]?.length)}
{' '}
Fields
    </p>
  </div>
);

export const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  deleteModalPayload,
}) => {
  const { title, description, payload } = deleteModalPayload;

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        // flex="1"
        // align="center"
        // p="2"
        // mx="4"
        // borderRadius="xl"
        role="group"
        // cursor="pointer"
        // justifyContent="center"
        // height="46%"
      >
        {/* <Box
          m="3rem auto .5rem auto"
          data-testid="metaDeleteModalReactSvgBox"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            border: '1px solid red',
            borderRadius: '7px',
            padding: '8px',
          }}
        >
          <FaTrashAlt
            color="red"
            size="30px" // Adjust size to make the icon appear thinner
            style={{
              // Simulate a thinner appearance by reducing the size
              lineHeight: 1,
              fontWeight: 'normal', // Ensure the icon is not bold
            }}
          />
        </Box> */}
        {/* <ModalHeader
          boxShadow="none"
          fontSize="30px"
          fontWeight="600"
          color="rgba(0, 0, 0, 0.88)"
          lineHeight={'35px'}
          padding="10px 0px 0px 33px"
          marginTop={'15px'}
        >
          {title}
        </ModalHeader> */}

<ModalBody>
{/* 
          <Box display="flex" alignItems="start" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="start">
              <Box padding="7px" borderRadius="100px" backgroundColor="#FEE2E2">
                <ExclamationTriangleIcon height="24px" width="24px" color="#DC2626" />
              </Box>
              <Box display="flex" flexDir="column" justifyContent="start" alignItems="start">
                <Box fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal">{title}</Box>
                <Box noOfLines={2}>{description}</Box>
              </Box>
            </Box>
          </Box> */}
          <Box display="flex" alignItems="center" gap="10px">
             <Box padding="7px" borderRadius="100px" backgroundColor="#FEE2E2" marginTop="5px">
               <ExclamationTriangleIcon height="24px" width="24px" color="#DC2626" />
             </Box>
             <Box fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal">{title}</Box>
          </Box>
          <Box  fontSize="14px" color="#6B7280" fontWeight="500" lineHeight="normal" paddingLeft="47px">{description}</Box>
        </ModalBody>

        <ModalFooter m="0px" justifyContent="end" boxShadow="none">
          <Box display="flex" alignItems="center" gap="10px">
          <SecondaryCustomButton
            variant="outline"
            onClick={onClose}
            // sx={{
            //   fontWeight: '400',
            //   width: '40%',
            //   minWidth: 'var(--ck-sizes-10);',
            //   marginInlineStart: '8px',
            //   background: '#ffffff',
            //   color: 'rgba(0, 0, 0, 0.88)',
            //   boxShadow: 'none',
            //   fontSize: 'var(--ck-fontSizes-sm)',
            //   lineHeight: '1.5714285714285714',
            //   height: 'var(--ck-sizes-10)',
            //   padding: '0px 7px',
            //   borderRadius: '4px',
            //   _hover: {
            //     borderColor: 'black',
            //   },
            // }}
            buttonText='Cancel'
          />
          <DangerCustomButton
            // colorScheme="blue"
            // mr={3}
            // isLoading={loading}
            onClick={() => {
              onConfirm(payload);
              onClose();
            }}
            // sx={{
            //   fontWeight: '400',
            //   width: '40%',
            //   minWidth: 'var(--ck-sizes-10);',
            //   marginInlineStart: '8px',
            //   boxShadow: 'none',
            //   fontSize: 'var(--ck-fontSizes-sm)',
            //   lineHeight: '1.5714285714285714',
            //   height: 'var(--ck-sizes-10)',
            //   padding: '0px 7px',
            //   borderRadius: '4px',
            //   marginInlineStart: '8px',
            //   backgroundColor: 'red',
            //   color: '#ffffff',
            //   _hover: {
            //     borderColor: '#1677ff',
            //     color: '#ffffff',
            //     backgroundColor: 'rgb(229, 62, 62)',
            //   },
            // }}
            buttonText='Delete'
          />
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const deleteRenderer = ({
  title = 'Delete this',
  description = 'Are you sure you want to delete?',
  loading = false,
  dataTestId = '',
  openDeleteModal,
  rowData
}) => {

  return (
    <DeleteIcon
    data-testid={dataTestId}
    className="cursor-pointer"
    onClick={() => openDeleteModal({
      title,
      description,
      payload: { rowData }
    })}
  />
  );
};

export const columns = ({ showModal, handleDeleteField }) => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
  {
    name: 'ASSOCIATED TO',
    col_name: 'associated_to',
    type: 'string',
    cellRenderer: ({ rowData }) => renderUpdatedInfo(rowData),
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => {
      const { addToast } = CustomToast();
      const arr = [...PRE_FIELDS_NO_ASSOCIATED];
      const noAssociatedFieldArray = [...PRE_FIELDS_NO_ASSOCIATED];
      const isDeleteButtonVisible = arr.every(
        (field) => field.meta_field_id !== rowData.meta_field_id
      );
     const isNotAssociated = noAssociatedFieldArray.every(
      (field) => field.meta_field_id !== rowData.meta_field_id
    );
      return (
        <div
          className="flex justify-center items-center"
          style={{ gap: '0.8rem' }}
        >
          <ReactSVG
            className="cursor-pointer"
            data-testid={`metaFields-${rowData.name}-link`}
            src={metaLinkIcon}
            onClick={() => {
              if (rowData?.meta_field_id !== 'objectId') {
                showModal({
                  rowData,
                });
              } else {
                addToast({
                  title: `Meta field can't be edited`,
                  message: `${rowData.name} is a pre-defined field and can't be edited`,
                  type: 'info',
                });
              }
              // showModal({
              //   rowData,
              // });
            }
          }
          />
          {isDeleteButtonVisible && deleteRenderer({
            title: 'Delete this meta-field',
            description: `Are you sure you want to delete ${rowData?.name}?`,
            dataTestId: `metaFields-${rowData.name}-delete`,
            openDeleteModal: handleDeleteField,
            rowData
          })}
        </div>
      );
    },
  },
];
