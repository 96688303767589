import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Wrap } from '@chakra-ui/react';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import styles from './mailServer.module.scss';
import CustomInput from '../../components/InputBox/Input';
import CustomButton from '../../components/Button/SimpleButton';
import CustomWrapItemWithRemoveAction from './CustomWrapItemWithRemoveAction';

const InputWithAddButton = ({
  label,
  values,
  onChange,
  buttonLabel,
  inputWidth,
}) => {
  const [expressionList, setExpressionsList] = useState(
    !_isEmpty(values) ? values : []
  );
  const [innerState, setInnerState] = useState('');
  const addExpression = () => {
    const newExpressions = _cloneDeep(expressionList);
    if (!newExpressions.some((obj) => obj === innerState)) {
      newExpressions.push(innerState);
      setExpressionsList(newExpressions);
      onChange(newExpressions);
    }
    setInnerState('');
  };
  const onAddExpressionInList = () => {
    if (innerState) {
      addExpression();
    }
  };
  const removeExpression = (expression) => {
    const newExpressions = _cloneDeep(expressionList);
    _remove(newExpressions, (ex) => ex === expression);
    setExpressionsList(newExpressions);
    onChange(newExpressions);
  };

  return (
    <Box width="100%">
      <FormLabel className="SCLabel">{label}</FormLabel>
      <div className={styles.flexFormBox}>
        <Box minW={inputWidth}>
          <CustomInput
            value={innerState || ''}
            onChange={(e) => {
              setInnerState(e.target.value);
            }}
            height="38px"
          />
        </Box>
        <CustomButton
          buttonStyle={{paddingTop:'2px'}}
          variant="solid"
          buttonColor="customBlue"
          buttonText={buttonLabel}
          onClick={() => {
            onAddExpressionInList();
          }}
        />
      </div>
      {!_isEmpty(expressionList) && (
        <Wrap className={styles.right__left_wrap} style={{marginTop:"10px"}}>
          {expressionList.map((exp) => (
            <CustomWrapItemWithRemoveAction
              wrapTitle={exp}
              onRemoved={() => {
                removeExpression(exp);
              }}
            />
          ))}
        </Wrap>
      )}
    </Box>
  );
};

InputWithAddButton.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  onChange: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  inputWidth: PropTypes.string,
};
InputWithAddButton.defaultProps = {
  values: [],
  onChange: () => {},
  inputWidth: '88%',
};
export default InputWithAddButton;
