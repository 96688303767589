/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { components } from 'react-select';
import { isEmpty, forEach } from 'lodash';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
// import CustomModal from '../../../components/Modal/Modal';
import { LABELS } from '../../../utils/enums/labels';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelect from '../../../components/SelectBox/Select';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import axiosInstance from '../../../utils/axios/axios';
import { getTenantData } from '../../../helpers/tenantUrl';
import { isV1FieldType } from '../../../utils/helper/fieldHelper';
import { convertFieldType } from '../../MasterScreen/ConfigJson/helpers';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const AddFieldFromMasterList = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const onClose = () => {
    setIsOpen(false);
  };

  const [selectedFields, setSelectedFields] = useState([]);
  const [options, setOptions] = useState([]);
  const [isError, setIsError] = useState(false);

  const { ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState
  );
  const { id } = useParams();

  const addViewerAndEditor = (permission) => {
    const orgObj = { org: [getTenantData()?.tenantId] };

    if (isEmpty(permission.editors)) {
      permission = { ...permission, editors: orgObj };
    }

    if (isEmpty(permission.viewers)) {
      permission = { ...permission, viewers: orgObj };
    }

    return permission;
  };

  const loadData = async () => {
    const response = await axiosInstance.get('fields/list?$select=*');
    const data = [];
    forEach(response?.data?.rows, (field) => {
      let fieldFlag = false;
      forEach(ticket_template_fields_list_view?.data, (item) => {
        if (item.label === field.label) {
          fieldFlag = true;
        }
      });
      if (!fieldFlag && !isV1FieldType(field)) {
        data.push(field);
      }
    });
    setOptions(
      data?.map((i) => ({
        ...i,
        id: uuidv4(),
        value: i?.label,
        scope: i?.scope || 'TICKET',
        permissions: addViewerAndEditor(i.permissions),
      }))
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const addFieldsetsFields = async () => {
    if (!selectedFields || selectedFields.length === 0) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const newData = [
      ...ticket_template_fields_list_view.data,
      ...selectedFields,
    ];

    const response = await patchDataForTemplate({
      id,
      data: newData,
      key: 'fields',
      defaultErrorMessage: 'Failed to add field(s)',
      successMessage: 'Successfully added field(s)',
    });

    if (!response.error) {
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_fields_list_view',
          value: {
            data: response?.response?.data?.fields,
          },
        })
      );
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: response?.response?.data,
        })
      );
    }
    onClose();
  };

  const onChange = (val) => {
    setSelectedFields(val);
    if (val.length > 0) {
      setIsError(false);
    }
  };

  const { Option } = components;
  // eslint-disable-next-line react/no-unstable-nested-components
  const IconOption = (props) => {
    const { data } = props;
    const { label } = data;
    return (
      <Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{label}</div>
          <div
            style={{ marginLeft: '10px', color: '#ccc', fontStyle: 'italic' }}
          >
            {convertFieldType({ rowData: data })}
          </div>
        </div>
      </Option>
    );
  };

  return (
    <CustomDrawer
      drawerBody={
        <>
          <h4
            style={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '600',
              lineHeight: '19px',
              paddingTop: '16px',
              paddingBottom: '20px',
            }}
          >
            {LABELS.TITLE.SELECT_FIELDS_TO_ADD_IN_TEMPLATE}
          </h4>
          <CustomSelect
            options={options}
            value={selectedFields}
            onChange={(val) => onChange(val)}
            isMulti
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            components={{ Option: IconOption }}
            closeMenuOnSelect={false}
            isError={isError}
            errorMessage={LABELS.FIELD_ADD_ERROR.FIELD}
          />
        </>
      }
      drawerFooter={
        <Flex gap="8px" data-testid="MasterListModalFlexBtn">
          <SecondaryCustomButton
            classname="calculatedHeight"
            // variant="outline"
            // buttonColor="customBlue"
            size="md"
            buttonText={LABELS.ACTIONS.CANCEL}
            onClick={() => setIsOpen(false)}
            id="MasterListModalDiscardBtn"
          />
          <PrimaryCustomButton
            classname="calculatedHeight"
            // variant="solid"
            // buttonColor="customBlue"
            size="md"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={addFieldsetsFields}
            id="MasterListModalAddBtn"
            isDisabled={isError}
          />
        </Flex>
      }
      modalTitle={LABELS.ACTIONS.ADD_NEW_FIELD}
      openDrawer={isOpen}
      setOpenDrawer={onClose}
    />
  );
};

AddFieldFromMasterList.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddFieldFromMasterList;
