/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/prefer-default-export */

import { getTenantData } from '../../../helpers/tenantUrl';
import {
  dummyEdge,
  dummyNode,
  dummyStatusCategories,
} from '../../TicketTemplateWorkflow/DummyData';

export const workFlowMasterListJson = {
  name: 'workFlowList',
  label: 'Ticket Workflows',
  table_name: 'workFlowList_table',
  route: '/workflow-list',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'work_flow_list_container',
      label: 'Work Flow List Container',
      show_label: false,
      type: 'container',
      style: { boxSizing: 'borderBox' },
      elements: [
        {
          name: 'workFlowList_title',
          type: 'text',
          value: 'Ticket Workflows',
          style: { fontSize: '20px', fontWeight: '600', marginTop: '1px' },
        },
        // {
        //   name: 'workFlowList_count',
        //   type: 'count_div',
        //   listKey: 'workflow_expanded_list',
        // },
      ],
      views: [
        {
          name: 'workflow_expanded_list',
          label: 'Fields List',
          type: 'list_exp',
          calculatedHight: 230,
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on asc',
            filter: 'template_id',
            filterValue:'null',
            endPoint: 'workflows/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'name',
              type: 'string',
            },
            {
              col_name: 'created_by',
              type: 'string',
            },
            {
              col_name: 'created_on',
              type: 'date',
            },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ], // ['name', 'created_by', 'created_on', 'actions'],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            // {
            //   name: 'view_workFlow',
            //   icon: 'viewIcon',
            //   type: 'button',
            //   target: '/work-flow',
            //   isUrlParams: true,
            //   source_route: 'workflow-list', // It will be appended as a parameter to identify whether the rule will be shown or not.
            //   target_type: 'navigate',
            // },
            {
              name: 'view_workFlow',
              icon: 'editIcon',
              type: 'button',
              target: '/work-flow',
              isUrlParams: true,
              source_route: 'workflow-list', // It will be appended as a parameter to identify whether the rule will be shown or not.
              target_type: 'navigate',
            },
            {
              name: 'delete_fields',
              icon: 'deleteIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'name', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'workflow',
              listKey: 'workflow_expanded_list', // identify the redux status for update it
              target_url: '/workflows/delete?$filter=id eq ${id}',
            },
            {
              name: 'copy_workFlow',
              icon: 'copyIcon',
              type: 'button',
              target_url: '/workflows/clone', // *for api call
              method: 'post',
              listKey: 'workflow_expanded_list', // identify the redux status for update it
              target_type: 'popup',
              target_modal_id: 'crudTemplateCloneModal',
              master_type: 'workflow',
            },
          ],
          filterKey: 'name',
          listKey: 'workflow_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_workflow_list_item',
              type: 'search',
              label: 'Search for a workflow...',
              placeholder: 'Search for a workflow...',
              entity: 'Workflows',
              listKey: 'workflow_expanded_list',
              filterKey: '',
              style: { height: '15px' },
              inputGroupStyle: { width: 'fit-content' },
            },
            {
              name: 'workflow_count_display',
              type: 'count_div',
              listKey: 'workflow_expanded_list',
              style: { padding: '8px', fontSize: '14px', fontWeight: '600' },
            },
            {
              name: 'create_new_workflow',
              label: 'Add New Workflow',
              show_label: true,
              is_button: true,
              icon: 'add.ico',
              action_type: 'get',
              permission: 'edit',
              view: 'edit_view',
              navigation: 'popup',
              target_type: 'popup',
              target_modal_id: 'addWorkFlowInListModal',
              type: 'button',
              classname: 'calculatedHeight',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_workflow_list_item',
                x: 8,
                y: 0,
                w: 5,
              },
              {
                i: 'create_new_workflow',
                x: 8,
                y: 1,
                w: 5,
              },
              {
                i: 'workflow_count_display',
                x: 8,
                y: 2,
                w: 2,
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'workFlowList_title',
            x: 10,
            y: 1,
            w: 6,
            maxH: 'Infinity',
          },
          // {
          //   i: 'workFlowList_count',
          //   x: 0,
          //   y: 1,
          //   w: 1,
          //   maxH: 'Infinity',
          // },
          {
            i: 'action_container',
            x: 0,
            y: 0,
            w: 6,
            h: 1,
          },
          {
            i: 'workflow_expanded_list',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },
    {
      name: 'create_workflow_modal',
      type: 'modal',
      modal_id: 'create_workflow_modal',
      modal_title: 'Create New Workflow',
      isOpen: false,
      mandatoryFields: ['name'],
      elements: [
        {
          name: 'workflow_name',
          type: 'input',
          value: 'name',
          inputType: 'string',
          label: 'Name',
          isMandatory: true,
        },
        // {
        //   name: 'workflow_slug',
        //   type: 'input',
        //   value: 'slug',
        //   inputType: 'string',
        //   label: 'Slug',
        //   isMandatory: true,
        // },
      ],
      actions: [
        {
          name: 'fields_count',
          type: 'count_div',
          listKey: 'fields_expanded_list',
        },
        {
          name: 'continue',
          label: 'Continue',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'api_call',
          type: 'button',
          is_modal_footer: true,
          listKey: 'workflow_expanded_list', // after post it will added inside listKey Redux state
          target_url: '/workflows/create',
          method: 'post',
          payloadKeysMaps: {
            // slug: '',
            name: '',
            tenant_id: getTenantData()?.id,
            version: '1',
            workflow_status: [...dummyNode],
            workflow_transitions: [...dummyEdge],
            workflow_category: [...dummyStatusCategories],
          },
          style: {
            color: '#FFFFFF',
            fontWeight: '600',
            backgroundColor: '#2563EB',
            padding: '20px',
          },
          target_route: '/work-flow', // after add in state it redirect to this path
          source_route: 'workflow-list', // It will be appended as a parameter to identify whether the rule will be shown or not.
        },
        {
          name: 'cancel',
          label: 'Cancel',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'state_change',
          type: 'button',
          is_modal_footer: true,
          style: {
            color: '#868E96',
            fontWeight: '600',
            padding: '20px',
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'workflow_name',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          // {
          //   i: 'workflow_slug',
          //   x: 0,
          //   y: 1,
          //   w: 12,
          //   // maxH: 'Infinity',
          // },
          {
            i: 'cancel',
            w: 6,
            h: 1,
          },
          {
            i: 'continue',
            w: 6,
            h: 1,
          },
        ],
      },
    },
    {
      name: 'edit_workflow_modal',
      type: 'modal',
      modal_id: 'edit_workflow_modal',
      modal_title: 'Edit WorkFlow',
      isEdit: true,
      isOpen: false,
      elements: [
        {
          name: 'workflow_name',
          type: 'input',
          value: 'name',
          inputType: 'string',
          label: 'Name',
          isMandatory: true,
          // linked_slug_field: 'slug',
          // slug_prefix: 'Workflow',
        },
        // {
        //   name: 'workflow_slug',
        //   type: 'input',
        //   value: 'slug',
        //   inputType: 'string',
        //   label: 'Slug',
        //   isMandatory: true,
        // },
      ],
      actions: [
        {
          name: 'continue',
          label: 'Edit WorkFlow',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'api_call',
          type: 'button',
          is_modal_footer: true,
          listKey: 'workflow_expanded_list',
          listIdentifier: 'id',
          target_url: '/workflows/update?$filter=id eq ${id}',
          method: 'patch',
          payloadKeysMaps: {
            // slug: '',
            name: '',
            workflow_status: [],
            workflow_transitions: [],
            workflow_category: [],
          },
          style: {
            color: '#FFFFFF',
            fontWeight: '600',
            backgroundColor: '#2563EB',
            padding: '20px',
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'state_change',
          type: 'button',
          is_modal_footer: true,
          style: {
            color: '#868E96',
            fontWeight: '600',
            padding: '20px',
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'workflow_name',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          // {
          //   i: 'workflow_slug',
          //   x: 0,
          //   y: 1,
          //   w: 12,
          //   // maxH: 'Infinity',
          // },
          {
            i: 'cancel',
            w: 6,
            h: 1,
          },
          {
            i: 'continue',
            w: 6,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'work_flow_list_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
      // {
      //   i: 'create_workflow_modal',
      //   x: 0,
      //   y: 0,
      //   w: 12,
      //   h: 1,
      // },
      // {
      //   i: 'edit_workflow_modal',
      //   x: 0,value
      //   y: 0,
      //   w: 12,
      //   h: 1,
      // },
    ],
  },
};
