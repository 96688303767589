import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { COLORS } from '../../../utils/enums/colors';
import DeleteIcon from '../../../assets/icons/DeleteIcon.svg';
import { LABELS } from '../../../utils/enums/labels';
import styles from '../styles/templatebuilder.module.scss';
import { DangerCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const TemplateDeleteModal = ({
  isDeleteModal,
  setIsDeleteModal,
  templateName,
  onConfirmation,
  titelText,
}) => {
  const colorType = useColorModeValue('light', 'dark');
  return (
    <CustomModal
      size="sm"
      // className={styles.footer}
      modalBodyStyle={{margin:"1rem"}}
      showModalCloseButton={false}
      id="delete-modal"
      modalContent={
        <Box
          role="group"
        >
          <Box
            // bg={useColorModeValue('white', 'gray.900')}
            align="center"
            justifyContent="center"
            data-testid="TemplateDeletteModalContaineBox"
          >
            {/* <Box width="50" height="50" mt="5" data-testid="TemplateDeletteModalReactSvgBox">
              <ReactSVG
                src={DeleteIcon}
                className={`${styles.templateDelete} ${
                  colorType === 'dark' ? styles.colorWhite : ''
                }`}
              />
            </Box> */}
            <Box display="flex" alignItems="center" gap="10px">
            <Box padding="7px" borderRadius="100px" backgroundColor="#FEE2E2" marginTop="5px"><ExclamationTriangleIcon height="24px" width="24px" color="#DC2626"/></Box>
            <Box fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal">Delete</Box>
            </Box>
            <Box
              // className={styles.createHeadText}
              fontSize="14px"
              fontWeight="400"
              color={useColorModeValue('#6B7280', 'white')}
              data-testid="TemplateDeletteModalHeaderTextBox"
               display="flex"
              paddingLeft="47px"
            >
              {isEmpty(titelText) ? LABELS.TITLE.DELETE_TEMPLATE_WARNING : titelText}
            </Box>
            <div style={{fontSize:"16px", color:"#111827", fontWeight:"500", lineHeight:"normal", paddingTop:"10px", display:"flex", marginLeft:"47px"}} data-testid="TemplateDeletteModalTempNameDiv">{templateName}</div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"end", gap:"10px", marginTop:"20px"}} data-testid="TemplateDeletteModalCBtnDiv">
              <SecondaryCustomButton
                id="TemplateDeteleModalcancel-delete-modal"
                onClick={() => setIsDeleteModal(false)}
                buttonText={LABELS.ACTIONS.CANCEL}
                // variant="outline"
                // className="mr-4"
                // width="40%"
                // size="md"
                // color={useColorModeValue(COLORS.BLACK, COLORS.DARK)}
                // _hover={{
                //   background: 'white',
                //   color: 'black',
                //   border: '1px solid black',
                // }}
              />
              <DangerCustomButton
                id="TemplateDeleteModalconfirm-delete"
                onClick={() => onConfirmation()}
                buttonText={LABELS.ACTIONS.DELETE}
                // variant="solid"
                // size="md"
                // width="40%"
                // bg={useColorModeValue(COLORS.RED, COLORS.DARK)}
                // _hover={{
                //   background: '#DC3636',
                //   color: 'white',
                // }}
              />
            </div>
          </Box>
        </Box>
      }
      open={isDeleteModal}
      setOpen={setIsDeleteModal}
    />
  );
};

TemplateDeleteModal.propTypes = {
  templateName: PropTypes.string.isRequired,
  isDeleteModal: PropTypes.bool.isRequired,
  setIsDeleteModal: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  titelText: PropTypes.string,
};
TemplateDeleteModal.defaultProps = {
  titelText: '',
};
export default TemplateDeleteModal;
