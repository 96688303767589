import React,{useState,useEffect} from 'react'
import { Box,InputGroup,Input,InputRightElement,Button,Text,Select,Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Portal } from '@chakra-ui/react'
import CustomTable from '../../../DynamicRenderer/customTable'
import './SilentInputs.scss'
import { IoSearch } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from 'react-icons/bs';
import {axiosInstanceWithoutBaseUrl} from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const SilentInputsTableWithSearch = ({tableData, columns, isFetching, dropdownFields, selectedTemplate, silentInputsData,setSilentInputsData,deletedFields,setDeletedFields,getFields,initialFields}) => {
    const [filter, setFilter] = useState(silentInputsData);
    const [fieldValue, setFieldValue] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [descriptionPopover, setDescriptionPopover] = useState(false);
    const [crossRefPopover, setCrossRefPopover] = useState(false);
    const [triggers, setTriggers] = useState([])

    const FIELDS_TOAST = {
      SUCCESS: 'Fields Updated Successfully',
      ERROR: 'Fields Not Updated',
      PARENT_ERROR:'Ancestor fields must be added first'
    };
    const DescriptionHeaderRenderer = (text) => (
      <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={descriptionPopover} onOpen={() => setDescriptionPopover(true)} onClose={() => setDescriptionPopover(false)} closeOnBlur={false}>
        <PopoverTrigger>
          <Box display="flex" alignItems="center">
          <Text me="5px" color="#111827" fontSize="14px" textTransform={"none"}>{text}</Text>
          <BsInfoCircle color="#94A3B8" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)} />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)}>
            <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
              <BsInfoCircle color="#E79B04" />
              <Text>Description For Bot:</Text>
            </PopoverHeader>
            <PopoverBody padding="10px" className="popover-body">
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">This field should contain a detailed explanation of the field's purpose and what kind of information it should contain.</Text>
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" mt="10px" color="#6B7280" className="popover-example">Eg. The email address of the customer, used for communication and account recovery.</Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
    const crossRefRenderer = (text) => (
      <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={crossRefPopover} onOpen={() => setCrossRefPopover(true)} onClose={() => setCrossRefPopover(false)} closeOnBlur={false}>
        <PopoverTrigger>
          <Box display="flex" alignItems="center">
          <Text me="5px" color="#111827" fontSize="14px" textTransform={"none"} >{text}</Text>
          <BsInfoCircle color="#94A3B8" onMouseEnter={() => setCrossRefPopover(true)} onMouseLeave={() => setCrossRefPopover(false)} />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setCrossRefPopover(true)} onMouseLeave={() => setCrossRefPopover(false)}>
            <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
              <BsInfoCircle color="#E79B04" />
              <Text>Cross Referenced Fields</Text>
            </PopoverHeader>
            <PopoverBody padding="10px" className="popover-body">
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">Specify the fields that bot should use to deduce the value for this field.</Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
    const columnsData=columns.map((item)=>{
      if (item.label === 'Description for Bot') {
        return { ...item, label: DescriptionHeaderRenderer(item?.label) };
      }
      if (item.label === 'Cross Referenced Fields') {
        return { ...item, label: crossRefRenderer(item?.label) };
      }
        return item
    });

    const getTriggerRecords = async () => {
      try {
        const response = await axiosInstanceWithoutBaseUrl.get(
          `/botmgmt-service/BotIntegration/getTriggers?$skip=0&$top=1000`
        );
        if (Array.isArray(response?.data?.data)) {
          setTriggers(response?.data?.data)
        }
        return [];
      } catch (err) {
        return []
      }
    };
    useEffect(() => {
      getTriggerRecords();
    }, []);
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setFieldValue(e.target.value);
        const filteredData = silentInputsData.filter((item) =>
        item.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilter(filteredData.length > 0 ? filteredData : []);
    };

    useEffect(()=>{
     if(Array.isArray(silentInputsData) && silentInputsData.length>0){
      const updatedTableData = silentInputsData.map(item => {
        const matchingSI = triggers
            .flatMap(trigger => trigger.botInputs) 
            .flatMap(botInput => botInput?.SI)
            .find(siItem => siItem?.id === item?.id);
        if (matchingSI) {
            return {
                ...item,
                selectedOffers: matchingSI.selectedOffers,
                crossReferenceFields: matchingSI.crossReferenceFields,
                botDescription: matchingSI.botDescription
            };
        }
        return item;
      });
      setFilter(updatedTableData);
     }
     else if(Array.isArray(silentInputsData) && silentInputsData.length===0){
      setFilter([])
     }
    },[silentInputsData,deletedFields])


    const onSaveSilentInputs=async ()=>{
        const fields = selectedTemplate[0]?.fields
        const convertedFields = fields.map((item) => {
          const { silentInput, ...rest } = item;
          return {
            ...rest,
            type: item.type.replace(/ /g, '_').toUpperCase()
          };
        });
        const silentInputValues = convertedFields.map((item) => {
          const value = silentInputsData.find((element) => element?.id === item?.id);
          if (value && value !== undefined) {
            return {
              ...item,
              silentInput:true,
              botDescription: value?.botDescription,
              crossReferencedFields:value?.crossReferencedFields,
              selectedOffers:value?.selectedOffers
            };
          }
          return item;
        });
        const values = silentInputValues.map((item) => {
          const fieldsToBeDelete=deletedFields.find((element)=>element.id===item.id)
            if (fieldsToBeDelete) {
              return {
                ...item,
                silentInput: false,
                crossReferencedFields: [],
                selectedOffers:[],
                botDescription:''
              };
            }
            else {return item}
        });
        const SI = values.filter(item=>item.silentInput).map((element)=>{
          return{
          ...element,
          id:element.id,
          botDescription:element.botDescription,
          crossReferencedFields:element.crossReferencedFields,
          selectedOffers:element?.selectedOffers
          } 
        })
        const botInputs = triggers.filter(element => element.itemType === selectedTemplate[0]?.id).find(item => item?.botInputs)?.botInputs ?? [];

        const newBotInput = botInputs.length > 0 
          ? botInputs.map(botInput => ({
              ...botInput,
              BI: botInput.BI,
              SI: SI
            }))
          : [{ BI: [], SI: SI }]; // Add a default entry if empty

        const payload = {
          id: triggers
            .filter(element => element.itemType === selectedTemplate[0]?.id)
            .map(item => item.id),
          botInputs: newBotInput
        };
        try {
            const response = await axiosInstanceWithoutBaseUrl.put(
              `/botmgmt-service/BotIntegration/updateTrigger`, payload);
            if (response) {
              setDeletedFields([]);
              getTriggerRecords();
              showToastMessage({
                title: 'Success',
                description: FIELDS_TOAST.SUCCESS,
                status: 'success',
              });
            }
        } catch (err) {
            setDeletedFields([]);
            showToastMessage({
              title: 'Error',
              description: FIELDS_TOAST.ERROR,
              status: 'error',
           });
            return [];
        }
      }
      // const onAddDropdownField = () => {
        // const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
        // const isExisting = filter.filter((item) => item?.id === selectedField?.id).length > 0;
        // selectedField !== undefined && !isExisting && setFilter([...filter, selectedField]);
        // selectedField !== undefined && !isExisting && setSilentInputsData([...silentInputsData, selectedField]);
        // setIsDropdownVisible(false);
        // setDropdownValue('');
      // };
      const onAddDropdownField = () => {
        const isDependent=dropdownFields.some(item=>{
          if(item.id===dropdownValue && item.type_based_attributes?.collection?.collection_type==='DEPENDENT') return true
          else return false
        })
        if(isDependent===true){
          const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
          const hierarchyMapItems = selectedTemplate[0]?.field_hierarchy_map|| [];
      
          let selectedHierarchy = null;
          let selectedIndex = null;
      
          for (const hierarchy of hierarchyMapItems) {
              const hierarchyMap = hierarchy.map;
              const index = hierarchyMap.indexOf(selectedField?.id);
              if (index !== -1) {
                  selectedHierarchy = hierarchy; 
                  selectedIndex = index; 
                  break;
              }
          }
    
        if (selectedIndex > 0 && selectedHierarchy) {
          const hierarchyMap = selectedHierarchy.map || [];
          const parentFieldId = selectedIndex && selectedIndex!==0 && hierarchyMap[selectedIndex - 1];
          const isParentPresentInDropdown = dropdownFields.find(item=>item.id===parentFieldId)
          const isParentPresent = silentInputsData.some((item) => item.id === parentFieldId);
          if(isParentPresentInDropdown || isParentPresent){
            if (!isParentPresent) {
              showToastMessage({
                title: 'Error',
                description: FIELDS_TOAST.PARENT_ERROR,
                status: 'error',
              });
              return;
            }else{
              const isExisting = silentInputsData.some((item) => item?.id === selectedField?.id);
              selectedField !== undefined && !isExisting && setFilter([...filter, selectedField]);
              selectedField !== undefined && !isExisting && setSilentInputsData([...silentInputsData, selectedField]);
              setIsDropdownVisible(false);
              setDropdownValue('');
              return;
            }
          }
        }
        const isExisting = silentInputsData.some((item) => item?.id === selectedField?.id);
        selectedField !== undefined && !isExisting && setFilter([...filter, selectedField]);
        selectedField !== undefined && !isExisting && setSilentInputsData([...silentInputsData, selectedField]);
        setIsDropdownVisible(false);
        setDropdownValue('');
        }
        else{
          const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
          const isExisting = filter.filter((item) => item?.id === selectedField?.id).length > 0;
          selectedField !== undefined && !isExisting && setFilter([...filter, selectedField]);
          selectedField !== undefined && !isExisting && setSilentInputsData([...silentInputsData, selectedField]);
          setIsDropdownVisible(false);
          setDropdownValue('');
        }
      };

      const handleReset = () => {
        setFilter(initialFields)
        setSilentInputsData(initialFields)
        setFieldValue('') 
      }
    return (
        <Box className="silent-inputs-section">
          <Box data-testid="search-section" className="search-section" display="flex" gap="10px" justifyContent="end" my="2">
              <InputGroup w="500px">
                  <Input placeholder="Search" value={fieldValue} onChange={handleChange} />
                  <InputRightElement><IoSearch /></InputRightElement>
              </InputGroup>
              {tableData && <Button data-testid="reset-button" onClick={handleReset}>Reset</Button>}
              {tableData && <Button data-testid="save-button" onClick={onSaveSilentInputs}>Save</Button>}
          </Box>
          <Box mt="20px">
              <CustomTable
                  data-testid="SilentInputsTable"
                  height="calc(100vh - 18rem)"
                  columns={columnsData}
                  tableData={filter}
                  botIntegrationTable="true"
                  isFetching={isFetching}
              />
          </Box>
          <Box className='add-field' data-testid="add-field" display="flex" justifyContent="flex-end" gap="20px" mt="20px" >
          {isDropdownVisible && <Select placeholder="Select field" color="#6B7280" width="370px" onChange={(e) => setDropdownValue(e.target.value)} maxWidth="370px">
              {Array.isArray(dropdownFields) && dropdownFields.map((item) => {
                if (!silentInputsData.some((element) => element?.id === item?.id)) {
                    return (<option value={item?.id} key={item?.id}>{item?.label}</option>);
                  }
              })}
            </Select>}
            {!dropdownValue ? 
            <Button variant="outline" onClick={()=>{setIsDropdownVisible(true);}} disabled={dropdownFields?.length==0} >
                <AddIcon color='#6B7280' />
                <Text as='span' ms="10px" color='#6B7280' fontSize="14px" fontWeight="500" >Add field</Text>
            </Button>
            :
            <Button backgroundColor="#2563EB" onClick={onAddDropdownField}>
                <Text as='span' color="#fff" >Add</Text>
            </Button>
            }
          </Box>
        </Box>
    )
}

export default SilentInputsTableWithSearch