/* eslint-disable import/prefer-default-export */
/* eslint-disable no-template-curly-in-string */

import { getTenantData } from '../../../helpers/tenantUrl';
import { calculatedHight } from '../../../utils/enums/constVariable';

export const createActorModel = {
  name: 'create_actor_modal',
  type: 'modal',
  modal_id: 'create_actor_modal',
  modal_title: 'Create New Actor',
  isOpen: false,
  mandatoryFields: ['name'],
  elements: [
    {
      name: 'actor_name',
      type: 'input',
      value: 'name',
      inputType: 'string',
      label: 'Name',
      isMandatory: true,
      // linked_slug_field: 'slug',
      // slug_prefix: 'Actors',
    },
    // {
    //   name: 'actor_slug',
    //   type: 'input',
    //   value: 'slug',
    //   inputType: 'string',
    //   label: 'Slug',
    //   isMandatory: true,
    // },
    {
      name: 'actor_description',
      type: 'textarea',
      value: 'description',
      inputType: 'string',
      label: 'Description',
      isMandatory: false,
    },
  ],
  actions: [
    {
      name: 'continue',
      label: 'Create actor',
      show_label: true,
      icon: 'edit.ico',
      is_button: true,
      action_type: 'get',
      permission: 'edit',
      view: 'details_view',
      target_type: 'api_call',
      type: 'button',
      is_modal_footer: true,
      listKey: 'actors_expanded_list',
      target_url: '/actors/create',
      method: 'post',
      payloadKeysMaps: {
        // slug: '',
        name: '',
        description: '',
        tenant_id: getTenantData()?.id,
        // status: 'DRAFT',
      },
      style: {
        color: '#FFFFFF',
        fontWeight: '600',
        backgroundColor: '#2563EB',
      },
    },
    {
      name: 'cancel',
      label: 'Cancel',
      show_label: true,
      icon: '',
      is_button: true,
      action_type: 'get',
      permission: 'edit',
      view: 'details_view',
      target_type: 'state_change',
      type: 'button',
      is_modal_footer: true,
      classname: 'editbutton calculatedHeight',
      style: {
        color: '#868E96',
        fontWeight: '600',
      },
    },
  ],
  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'actor_name',
        x: 0,
        y: 1,
        w: 12,
        // maxH: 'Infinity',
      },
      // {
      //   i: 'actor_slug',
      //   x: 0,
      //   y: 1,
      //   w: 12,
      //   // maxH: 'Infinity',
      // },
      {
        i: 'actor_description',
        x: 0,
        y: 1,
        w: 12,
        // maxH: 'Infinity',
      },
      {
        i: 'cancel',
        w: 6,
        h: 1,
      },
      {
        i: 'continue',
        w: 6,
        h: 1,
      },
    ],
  },
};
export const actorJson = {
  name: 'actors',
  label: 'Actors',
  table_name: 'actor_table',
  route: '/actors',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'actors_container',
      label: 'Actors Container',
      show_label: false,
      type: 'container',
      style: { boxSizing: 'borderBox' },
      elements: [
        {
          name: 'actors_title',
          type: 'text',
          value: 'Actors',
          style: { fontSize: '20px', fontWeight: '600' },
        },
      ],
      views: [
        {
          name: 'actors_expanded_list',
          label: 'Actors List',
          type: 'list_exp',
          calculatedHight: "239",
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on desc',
            endPoint: 'actors/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'name',
              type: 'string',
            },
            {
              col_name: 'description',
              type: 'string',
            },
            {
              col_name: 'created_on',
              type: 'date',
            },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            {
              name: 'edit_actor',
              icon: 'editIcon',
              type: 'button',
              target_type: 'popup',
              target_modal_id: 'edit_actor_modal',
            },
            {
              name: 'delete_actor',
              icon: 'deleteIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'name', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'Field',
              listKey: 'actors_expanded_list', // identify the redux status for update it
              target_url: '/actors/delete?$filter=id eq ${id}',
            },
            {
              name: 'copy_fieldsets',
              icon: 'copyIcon',
              type: 'button',
              target_url: '/actors/clone', // *for api call
              method: 'post',
              listKey: 'actors_expanded_list', // identify the redux status for update it
              target_type: 'popup',
              target_modal_id: 'crudTemplateCloneModal',
              master_type: 'actor',
            },
          ],
          filterKey: 'name',
          listKey: 'actors_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_actor_item',
              type: 'search',
              label: 'Search actors...',
              placeholder: 'Search actors...',
              entity: 'Actors',
              listKey: 'actors_expanded_list',
              filterKey: '',
              classname: 'calculatedHeight',
              style: {
                height:"15px"
              }
            },
            {
              name: 'create_new_action',
              label: 'Add New Actor',
              show_label: true,
              is_button: true,
              icon: '',
              action_type: 'get',
              permission: 'edit',
              view: 'edit_view',
              navigation: 'popup',
              target_type: 'popup',
              target_modal_id: 'create_actor_modal', // TODO: Update modal id
              type: 'button',
              classname: 'editbutton calculatedHeight',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_actor_item',
                x: 6,
                y: 0,
                w: 8,
                // maxH: 'Infinity',
              },
              {
                i: 'create_new_action',
                x: 0,
                y: 1,
                w: 4,
                // colEnd: -1,
                // maxH: 'Infinity',
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'actors_title',
            x: 0,
            y: 0,
            w: 8,
            maxH: 'Infinity',
          },
          {
            i: 'action_container',
            x: 0,
            y: 1,
            w: 4,
            h: 1,
          },
          {
            i: 'actors_expanded_list',
            x: 0,
            y: 2,
            w: 12,
            h: 1,
          },
        ],
      },
    },
    createActorModel,
    {
      name: 'edit_actor_modal',
      type: 'modal',
      modal_id: 'edit_actor_modal',
      modal_title: 'Edit Actor',
      isEdit: true,
      isOpen: false,
      mandatoryFields: ['name'],
      elements: [
        {
          name: 'actor_name',
          type: 'input',
          value: 'name',
          inputType: 'string',
          label: 'Name',
          isMandatory: true,
          // linked_slug_field: 'slug',
          // slug_prefix: 'Actors',
        },
        // {
        //   name: 'actor_slug',
        //   type: 'input',
        //   value: 'slug',
        //   inputType: 'string',
        //   label: 'Slug',
        //   isMandatory: true,
        // },
        {
          name: 'actor_description',
          type: 'textarea',
          value: 'description',
          inputType: 'string',
          label: 'Description',
          isMandatory: false,
        },
      ],
      actions: [
        {
          name: 'continue',
          label: 'Save',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'api_call',
          type: 'button',
          is_modal_footer: true,
          listKey: 'actors_expanded_list',
          listIdentifier: 'id',
          target_url: '/actors/update?$filter=id eq ${id}',
          method: 'patch',
          payloadKeysMaps: {
            // slug: '',
            name: '',
            description: '',
            // status: 'DRAFT',
          },
          style: {
            color: '#FFFFFF',
            fontWeight: '600',
            backgroundColor: '#2563EB',
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'state_change',
          type: 'button',
          is_modal_footer: true,
          style: {
            color: '#868E96',
            fontWeight: '600',
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'actor_name',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          // {
          //   i: 'actor_slug',
          //   x: 0,
          //   y: 1,
          //   w: 12,
          //   // maxH: 'Infinity',
          // },
          {
            i: 'actor_description',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          {
            i: 'cancel',
            w: 6,
            h: 1,
          },
          {
            i: 'continue',
            w: 6,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'actors_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
    ],
  },
};
