/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../../../components/InputBox/Input';
import { cloneTemplate } from '../../TemplateCardListView/helpers';
import CustomModal from '../../../components/Modal/Modal';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import {
  selectAllCards,
  storeFetchedCards,
} from '../../../redux/CardReducer/AllCardSlice';
import axiosInstance from '../../../utils/axios/axios';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';

const CloneTemplate = ({
  id,
  templateName,
  isOpen,
  setIsOpen,
  pathOfClone,
}) => {
  const cardData = useSelector(selectAllCards);
  const { ticket_template_list_short_view } = useSelector(
    (state) => state.viewState
  );
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessgae, setErrorMessgae] = useState(null);
  const dispatch = useDispatch();


  const onClone = async () => {
    if (!name) {
      setErrorMessgae('Please input name');
      return;
    }
    setIsLoading(true);
    const res = await cloneTemplate(id, name);
    if (!res.error) {
      setName('');
      if (pathOfClone === 'cardList') {
        dispatch(storeFetchedCards([...cardData, res?.response]));
      }
      if (pathOfClone === 'templateMaker') {
        axiosInstance
          .get(`/templates/tree`)
          .then((responseData) => {
            const responceTreeData = responseData?.data;
            dispatch(
              updateViewState({
                stateKey: 'ticket_template_list_short_view',
                value: {
                  ...ticket_template_list_short_view,
                  data: [...responceTreeData],
                },
              })
            );
          })
          .catch((e) => {
            dispatch(
              updateViewState({
                stateKey: 'ticket_template_list_short_view',
                value: {
                  ...ticket_template_list_short_view,
                },
              })
            );
          });
      }
      setIsOpen(false);
    } else {
      setErrorMessgae(res?.error);
    }
    setIsLoading(false);
  };

  return (
    <CustomModal
      id="selected-field-modal"
      modalTitle={<h1>Clone Template</h1>}
      scrollOverflowFlag={false}
      modalContent={
        <>
          <Box data-testid="CloneTemplateInputContainerBox">
            {`Provide a new name for cloned template -> ${templateName || ''}`}
          </Box>
          <CustomInput
            label="Name"
            isMandatory
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrorMessgae(null);
            }}
            isError={errorMessgae?.length}
            errorMessage={errorMessgae}
            id="CloneTemplateDivCInput"
          />
        </>
      }
      modalFooter={
        <Flex gap="8px" data-testid="CloneTemplateButtonContainerFlex">
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor="#868E96"
            buttonText={LABELS.ACTIONS.CANCEL}
            classname="calculatedHeight"
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setErrorMessgae(null);
              setName('');
              setIsOpen(false);
            }}
            id="cloneTemplate_cancel_btn"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            buttonColor="customBlue"
            isDisabled={!name}
            buttonText="Submit"
            onClick={() => {
              onClone();
            }}
            id="cloneTemplate_submit_btn"
            isLoading={isLoading}
            classname="calculatedHeight"
          />
        </Flex>
      }
      open={isOpen}
      setOpen={setIsOpen}
      setName={setName}
    />
  );
};

CloneTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  pathOfClone: PropTypes.string.isRequired,
};

export default CloneTemplate;
