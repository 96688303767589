import React from 'react';
import { Switch } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CustomSwitch = ({ id, onChange, size, isChecked }) => (
  <Switch
    _checked={{
      '& .chakra-switch__track': { background: '#2563EB', }
    }}
    defaultChecked
    onChange={onChange}
    data-testid={id}
    size={size}
    isChecked={isChecked}
  />
);

CustomSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
};

CustomSwitch.defaultProps = {
  size: 'lg',
};
export default CustomSwitch;
