import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Spinner,
  useColorMode,
  useColorModeValue,
  Icon,
  useToast,
  Tooltip
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Tree, ConfigProvider, Flex } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FaUserPlus } from 'react-icons/fa6';
import { MdQueue } from 'react-icons/md';

import CustomInput from '../InputBox/Input';
import CustomButton from '../Button/SimpleButton';
import HierarchyEditImageDrawer from './HierarchyDrawer';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';

import {
  addElementByKey,
  deleteElementByKey,
  editNodeInfoByKey,
  editTitleByKey,
  editNumberValueByKey,
  findElementAndPath,
  addNewKey,
  updateChildUsers
} from './utils';
import { TO_DO_STATUS } from '../../utils/enums/colors';
import { deleteAttachment, getImage } from '../AttachmentUpload/utils';
import { PREDEFINED_INTENT_GROUP_KEY_LIST } from '../../pages/CreateEditIntentBuilder/utils';
import { SecondaryCustomButton } from '../Button/PrimarySecondaryButton';
import CustomSelectBox from '../SelectBox/Select';
import { getQueueList } from '../../pages/CreateMailServer/utils';
import { getUserList } from '../selectFieldFromTemplateWithDelete/utils';

const HierarchyTree = ({
  value,
  onChange,
  label,
  showImage,
  extraContent,
  allowEditImage,
  onImageChange,
  defaultExpanded,
  onExpand,
  isActorHierarchy,
  positionInput,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const updatedTreeData = useMemo(() => {
    if (!isEmpty(value)) {
      const clonedTreeData = cloneDeep(value);
      return clonedTreeData;
    }
    return [];
  }, [value]);

  const [selectedKey, setSelectedKey] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [treeDataState, setTreeDateState] = useState(updatedTreeData);
  const [inputTitle, setInputTitle] = useState('');
  const [showEditImageDrawer, setShowEditImageDrawer] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [queueList, setQueueList] = useState([]);
  const [numberInputValue, setNumberInputValue] = useState(0);

  const selectedNode = useRef(null);
  const imageBase64Map = useRef({});
  const currentImageUrl = useRef('');
  const currentNodeImageInfo = useRef({});
  const [description, setDescription] = useState('');

  const showNodeImage = useCallback((blobName) => {
    if (typeof blobName !== 'string' || !blobName.trim()) {
      currentImageUrl.current = '';
      return;
    }

    if (imageBase64Map.current[blobName]) {
      currentImageUrl.current = imageBase64Map.current[blobName];
      return;
    }

    setIsImageLoading(true);
    getImage(blobName).then((res) => {
      imageBase64Map.current[blobName] = res.fileSrc;
      currentImageUrl.current = res.fileSrc;
      setIsImageLoading(false);
    });
  }, []);

  useEffect(() => {
    onChange(treeDataState);
  }, [treeDataState]);

  const onSelect = (selectedKeys, info) => {
    if (info.selected) {
      const imageUrl = info?.selectedNodes?.[0]?.imageUrlInfo;
      const selectedDescription = info?.selectedNodes?.[0]?.description || '';
      currentNodeImageInfo.current = imageUrl;
      setDescription(selectedDescription);
      showNodeImage(imageUrl?.blobName);
      setSelectedKey(selectedKeys[0]);
      setSelectedTitle(info?.selectedNodes[0]?.title);
      setInputTitle(info?.selectedNodes[0]?.title);
      if (positionInput) setNumberInputValue(info?.selectedNodes[0]?.position || 0);

      const nodeData = info?.selectedNodes[0];
      if (nodeData && nodeData.isActor) {
        const parentQueueId = findActor( treeDataState, selectedKeys[0]);
        if (parentQueueId) {
          fetchActorsForQueue(parentQueueId); // Fetch actors for this queue
        } else {
          const users = availableUsersListInTenantLevel();
          setUsersList(users);
        }
      }
    }
  };

  const fetchActorsForQueue = async (queueId) => {
    if (queueId) {
      try {
        const res = await getUserList(queueId);
        const users = res?.users?.map(user => ({
          label: user?.email,
          value: user?.email
        }));
        setUsersList(users);
        return users;
      } catch (err) {
        console.error("Failed to fetch users for the queue:", err);
        setUsersList([]); // Clear the user list on error or if no users found
      }
    }
  };

  const findActor = (tree, selectedKey, parent = null) => {
    for (const node of tree) {
      if (node.key === selectedKey && node.isActor) {
        if (parent && parent.isQueue) {
          return parent.queueId;
        }
      }
      if (node.children && node.children.length > 0) {
        const result = findActor(node.children, selectedKey, node);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const onAdd = () => {
    const selectedNodeInfoWithPath = findElementAndPath(
      treeDataState,
      selectedNode?.current?.key || selectedKey
    );

    const defaultTreeAdd = {
      key: uuidv4(),
      children: [],
      title: `default-${
        (selectedNodeInfoWithPath?.element?.children?.length || 0) + 1
      } is child of  ${selectedNodeInfoWithPath?.element?.title}`,
      imageUrlInfo: {
        imageUrl: '',
        blobName: '',
      },
      position:
        parseInt(selectedNodeInfoWithPath?.element?.position || 0, 10) + 1,
    };
    const updateState = addElementByKey(
      selectedNode?.current?.key || selectedKey,
      treeDataState,
      defaultTreeAdd
    );
    setTreeDateState([...updateState]);
    setInputTitle('');
    if (positionInput) setNumberInputValue(0);
    setSelectedKey('');
    setSelectedTitle('');
  };

  const onAddActor = () => {
    const defaultTreeAdd = {
      key: uuidv4(),
      children: [],
      title: usersList[0]?.value,
      position: 0,
      isActor: true,
      imageUrlInfo: {
        imageUrl: '',
        blobName: '',
      },
    };
    const updateState = addElementByKey(
      selectedNode?.current?.key || selectedKey,
      treeDataState,
      defaultTreeAdd
    );
    setTreeDateState([...updateState]);
  };

  const onAddQueue = () => {
    const defaultTreeAdd = {
      key: uuidv4(),
      children: [],
      title: queueList[0]?.label,
      position: 0,
      isQueue: true,
      queueId: queueList[0]?.value,
      imageUrlInfo: {
        imageUrl: '',
        blobName: '',
      },
    };
    const updateState = addElementByKey(
      selectedNode?.current?.key || selectedKey,
      treeDataState,
      defaultTreeAdd
    );
    setTreeDateState([...updateState]);
  };

  const onAddMainNode = () => {
    setSelectedKey('');
    setSelectedTitle('');
    selectedNode.current = {};
    const tempData = cloneDeep(treeDataState);
    const treeLength = treeDataState.length;
    const tempNode = {
      title: `default ${treeLength + 1}`,
      position: 0,
      key: uuidv4(),
      children: [],
      imageUrlInfo: {
        imageUrl: '',
        blobName: '',
      },
    };
    setTreeDateState([...tempData, tempNode]);
  };

  const onEdit = () => {
    if (!inputTitle) {
      return;
    }

    const tempData = editTitleByKey(
      selectedNode.current?.key || selectedKey,
      treeDataState,
      inputTitle
    );
    setTreeDateState(tempData);
    setSelectedKey('');
    setSelectedTitle('');
  };

  const onSaveNumberValue = (position) => {
    setNumberInputValue(position);
    const tempData = editNumberValueByKey(
      selectedKey || selectedNode.current?.key,
      treeDataState,
      position
    );
    setTreeDateState(tempData);
  };

  const onEditActor = (val) => {
    if (!val) {
      return;
    }

    const tempData = editTitleByKey(
      selectedNode.current?.key || selectedKey,
      treeDataState,
      val
    );
    setTreeDateState(tempData);
    setSelectedKey('');
    setSelectedTitle('');
  };


  const onEditQueue = (val) => {
    if (!val) {
      return;
    }
    const queue = queueList.find((q) => q.value === `${val}`);

    let tempData = editTitleByKey(
      selectedNode.current?.key || selectedKey,
      treeDataState,
      queue?.label
    );

    tempData = addNewKey(
      selectedNode?.current?.key || selectedKey,
      tempData,
      { queueId: queue?.value },
    );
    fetchActorsForQueue(queue?.value).then((updatedUsersList) => {
      // Recursively update child nodes based on the new queue's user list
      const updatedTree = updateChildUsers(
        selectedNode?.current?.key || selectedKey,
        tempData,
        updatedUsersList
      );
  
      setTreeDateState(updatedTree);
      setSelectedKey('');
    });
  };

  const onSaveImageInfo = useCallback(
    (updatedImageInfo, desc) => {
      const tempData = editNodeInfoByKey({
        key: selectedKey,
        state: treeDataState,
        newImageInfo: updatedImageInfo,
        description: desc,
      });
      setTreeDateState(tempData);
      if (typeof onImageChange === 'function') {
        onImageChange(tempData);
      }
      setSelectedKey('');
      setSelectedTitle('');
    },
    [selectedKey, treeDataState, onImageChange]
  );

  const onDelete = (key) => {
    const selectedNodeInfoWithPath = findElementAndPath(treeDataState, key);

    if (selectedNodeInfoWithPath?.element?.children && selectedNodeInfoWithPath.element.children.length > 0) {
      toast({
        title: "Contains Child Nodes",
        description: "Please remove all child elements first.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }
    const tempData = deleteElementByKey(key, treeDataState);
    // Deleting the image along with the node
    if (currentNodeImageInfo?.current?.blobName) {
      deleteAttachment(currentNodeImageInfo?.current?.blobName);
    }

    setTreeDateState(tempData);
    setSelectedKey('');
    setSelectedTitle('');
  };

  const handleEnterPress = (e) => {
    selectedNode.current = { key: selectedKey, mode: 'edit' };
    if (e.key === 'Enter') {
      onEdit();
    }
  };

  const shouldRenderImage = useMemo(() => {
    if (!showImage) {
      return false;
    }

    if (isImageLoading) {
      return true;
    }

    const imageUrl = currentImageUrl.current;
    if (typeof imageUrl === 'string' && imageUrl?.trim()) {
      return true;
    }

    return false;
  }, [showImage, isImageLoading, selectedKey]);

  useEffect(() => {
    setTimeout(() => {
      const users = availableUsersListInTenantLevel();
      if (usersList.length === 0) setUsersList(users);
    }, 1000);
  }, [availableUsersListInTenantLevel]);

  useEffect(() => {
    setTimeout(async () => {
      const queues = await getQueueList();
      if (queueList.length === 0) setQueueList(queues?.response);
    }, 1000);
  }, []);

  const titleRenderer = ({ key, title, isActor, isQueue }) => {
    if (key === selectedKey) {
      return (
        <div
          style={{
            display: 'flex',
            gap: 15,
            alignItems: 'center',
            backgroundColor: colorMode === 'dark' ? 'rgb(0,0,0)' : 'white',
          }}
        >
          {/* {title} */}
          {!isActor && !isQueue && (
            <CustomInput
              type="text"
              h="32px"
              value={inputTitle}
              onChange={(e) => {
                setInputTitle(e.target.value);
              }}
              onBlur={() => {
                /**
                  Use setTimeout with a delay of 0 to defer the execution of the blur logic
                  This allows the click event on the onAdd button to be registered properly
               */
                setTimeout(() => {
                  handleEnterPress({ key: 'Enter' });
                }, 0);
              }}
              onKeyPress={handleEnterPress}
              id="test"
              minWidth="320px"
              w="100%"
              isReadOnly={PREDEFINED_INTENT_GROUP_KEY_LIST.includes(key)}
            />
          )}

          {isActor && !isQueue && (
            <CustomSelectBox
              id="actorSelectBox"
              options={usersList}
              h="32px"
              value={{ label: title, value: title }}
              onChange={(val) => {
                selectedNode.current = { key: selectedKey, mode: 'edit' };
                onEditActor(val?.value || null);
              }}
              minWidth="320px"
              w="100%"
              isReadOnly={PREDEFINED_INTENT_GROUP_KEY_LIST.includes(key)}
            />
          )}

          {isQueue && !isActor && (
            <CustomSelectBox
              id="queueSelectBox"
              options={queueList}
              h="32px"
              value={{ label: title, value: title }}
              onChange={(val) => {
                selectedNode.current = { key: selectedKey, mode: 'edit' };
                onEditQueue(val?.value || null);
              }}
              minWidth="320px"
              w="100%"
              isReadOnly={PREDEFINED_INTENT_GROUP_KEY_LIST.includes(key)}
            />
          )}

          {shouldRenderImage ? (
            <div className="w-[35px] h-[32px] rouded-[5px]">
              {isImageLoading ? (
                <Spinner size="xs" />
              ) : (
                <img
                  src={currentImageUrl.current}
                  alt="node"
                  className="w-full h-full"
                />
              )}
            </div>
          ) : null}

        {positionInput && (
            <input
              type="number"
              value={numberInputValue}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue === '' || /^[0-9]*$/.test(newValue)) {
                  onSaveNumberValue(newValue);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === '-') {
                  e.preventDefault();
                }
              }}
              style={{
                width: '50px',
                height: '32px',
                borderRadius: '5px',
                padding: '0 5px',
                border: '1px solid #ccc',
              }}
            />
        )}
          <Box
            w="35px"
            bg={TO_DO_STATUS}
            h="32px"
            borderRadius="5px"
            justifyContent="center"
            alignItems="center"
            data-testid="StatusActionBox"
            display="flex"
            onClick={() => {
              selectedNode.current = { key, mode: 'Create' };
              onAdd();
            }}
          >
            <AddIcon color="white" style={{ display: 'block' }} />
          </Box>

          {isActorHierarchy && (
            <Flex direction="row" alignItems="center" gap={4}>
            <Tooltip label="Add User" placement="top">
              <Box
                w="35px"
                h="32px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                data-testid="ActorActionBox"
                display="flex"
                cursor="pointer"
                onClick={() => {
                  selectedNode.current = { key, mode: 'Create' };
                  onAddActor();
                }}
              >
                <Icon as={FaUserPlus} w={6} h={6} bg="white" />
              </Box>
            </Tooltip>
            <Tooltip label="Add Queue" placement="top">

              <Box
                w="35px"
                h="32px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                data-testid="QueueActionBox"
                display="flex"
                cursor="pointer"
                onClick={() => {
                  selectedNode.current = { key, mode: 'Create' };
                  onAddQueue();
                }}
              >
                <Icon as={MdQueue} w={6} h={6} bg="white" />
              </Box>
            </Tooltip>
            </Flex>
          )}

          {allowEditImage ? (
            <div
              className={`
                w-[35px]
                h-[32px]
                flex
                items-center
                justify-center
                border
                border-[#D1D5DB]
                rounded-[5px]
              `}
            >
              <ExternalLinkIcon
                className="cursor-pointer"
                onClick={() => setShowEditImageDrawer(true)}
              />
            </div>
          ) : null}

          {extraContent}

          <CustomButton
            style={{
              borderColor: '#ED7474',
              color: '#ED7474',
              fontSize: '19px',
              width: '32px',
              height: '31px',
              paddingRight: '10px',
              paddingLeft: '10px'
            }}
            leftIcon={<DeleteIcon />}
            onClick={() => onDelete(key)}
            height="calculatedHeight"
            buttonText=""
            variant="outline"
            className="iconButton"
            isDisabled={PREDEFINED_INTENT_GROUP_KEY_LIST.includes(key)}
          />
        </div>
      );
    }

    return (
      <>
      {positionInput && <span style={{ fontSize: '12px' }}>({position || 0})</span>}
      <span
        style={{ marginLeft: '8px',
          backgroundColor: colorMode === 'dark' ? 'rgb(0,0,0)' : 'white',
        }}
      >
        {typeof title === 'object' && 'value' in title ? title.value : title}
        </span></>
    );
  };

  return (
    <Box data-testid="nodeTreeContainerBox">
      <div>
        <SecondaryCustomButton
          id="CreateEditFlowStatusManageCategory"
          buttonText={label}
          onClick={() => {
            selectedNode.current = { key: '', mode: 'Create' };
            setInputTitle('');
            onAddMainNode();
          }}
          mb="20px"
          leftIcon={<AddIcon />}
        />
        <ConfigProvider
          theme={{
            components: {
              Tree: {
                nodeSelectedBg: 'transparent',
                titleHeight: 32,
                fontSizeLG: 15,
              },
            },
          }}
        >
          <Tree
            showLine
            onSelect={onSelect}
            treeData={treeDataState}
            titleRender={titleRenderer}
            defaultExpandedKeys={defaultExpanded}
            onExpand={onExpand}
            style={useColorModeValue(
              { paddingLeft: '10px' },
              {
                backgroundColor: 'rgb(0,0,0)',
                color: '#fff',
                paddingLeft: '10px',
              }
            )}
          />
        </ConfigProvider>
      </div>

      {allowEditImage ? (
        <HierarchyEditImageDrawer
          open={showEditImageDrawer}
          setOpen={setShowEditImageDrawer}
          imageUrlInfo={currentNodeImageInfo.current}
          description={description}
          setDescription={setDescription}
          title={`Edit ${selectedTitle}`}
          onSave={onSaveImageInfo}
          key={showEditImageDrawer.toString()}
        />
      ) : null}
    </Box>
  );
};
HierarchyTree.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  showImage: PropTypes.bool,
  extraContent: PropTypes.node,
  allowEditImage: PropTypes.bool,
  onImageChange: PropTypes.bool,
  onExpand: PropTypes.func,
  defaultExpanded: PropTypes.array,
  isActorHierarchy: PropTypes.bool,
  positionInput: PropTypes.bool

};

HierarchyTree.defaultProps = {
  value: [],
  showImage: false,
  extraContent: null,
  allowEditImage: false,
  onImageChange: undefined,
  onExpand: undefined,
  defaultExpanded: undefined,
  isActorHierarchy: false,
  positionInput: false
};

export default HierarchyTree;
