/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router';
import { isEmpty, isArray } from 'lodash';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import styles from './mailServer.module.scss';
import useFetchTemplate from '../TicketMetaFields/LinkedMetaFieldDrawer/helper';
import {
  AUTH_TYPES,
  MAIL_SERVER_TAB,
  createPayloadForMailServer,
  customValidations,
  emptyMailObj,
  errorToTabIndexMap,
  getMailServer,
  mergeOptionalAndEmailMappings,
  processErrorWithMessage,
  splitOptionalAndEmailMappings,
  validateCreateMailPayload,
  validateUserName,
} from './utils';
import CustomToast from '../../components/Notification/Notification';
import axiosInstance from '../../utils/axios/axios';
import ConnectMailServer from './ConnectMailServer';
import CustomTabs from '../../components/Tabs/Tabs';

import MailConnectionPage from './MailConnectionPage';
import TicketMappingPage from './TicketMappingPage';
import { getOffers, getTemplate } from './mailService';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import { CustomDrawer } from '../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const AddNewMailServer = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [allMailList, setAllMailList] = useState([]);
  const [mailServerTabIndex, setMailServerTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [mailServerState, setMailServerState] = useState({ ...emptyMailObj });
  const [errorListWithKey, setErrorListWithKey] = useState({});
  const [templateAndRelatedData, setTemplateAndRelatedData] = useState({
    template: null,
    offersList: [],
    hierarchy: null,
    fieldHierarchyMap: [],
  });
  const [hierarchies, setHierarchies] = useState([]);
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [isTesting, setIsTesting] = useState(false);

  const [existsInTemplateIds, setExistsInTemplateIds] = useState([]);
  const { templateOptionList } = useFetchTemplate();

  const handleTestErrors = (errorList) => {
    [...(errorList || [])].forEach((item) => {
      if (item === 'authentication') {
        setErrorListWithKey((pre) => {
          if (mailServerState.authType === AUTH_TYPES.O_AUTH) {
            return {
              ...pre,
              refreshToken: 'Login failed',
            };
          }
          return {
            ...pre,
            password: 'Login failed',
          };
        });
      } else {
        setErrorListWithKey((pre) => ({
          ...pre,
          [item]: `${item} is not valid, choose another`,
        }));
      }
    });
  };

  const testMailServerConnection = async () => {
    setIsTesting(true);
    let testUrl;

    // Validate all the fields before testing the connection

    const testPayload = {
      userName: mailServerState.userName,
      host: mailServerState.host,
      port: mailServerState.port,
      timeout: mailServerState.timeout,
    };

    if (mailServerState.authType === AUTH_TYPES.O_AUTH) {
      testPayload.clientId = mailServerState.clientId;
      testPayload.clientSecret = mailServerState.clientSecret;
      testPayload.refreshToken = mailServerState.refreshToken;
      testPayload.scope = mailServerState.scope;
      testPayload.tokenEndpoint = mailServerState.tokenEndpoint;
      testPayload.azureDirId = mailServerState.azureDirId;
      testUrl = 'emailServerConfig/testConnectionOAuth';
    } else {
      testPayload.password = mailServerState.password;
      testUrl = 'emailServerConfig/testConnectionPassword';
    }

    try {
      const res = await axiosInstance.post(testUrl, testPayload);
      if (res?.status === 200 || res?.connectionStatus === 'OK') {
        addToast({
          title: 'Mail server connection is successful',
          type: 'success',
          duration: 2000,
          isClosable: true,
        });
      } else if (res?.status === 400 || res?.connectionStatus === 'FAILED') {
        const errorList = res.errorFields;
        handleTestErrors(errorList);
        addToast({
          title: 'Mail server connection failed',
          message: res?.message,
          type: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
      setIsTesting(false);
      return null;
    } catch (err) {
      if (err.response.data.connectionStatus === 'FAILED') {
        const errorList = err.response.data.errorFields;
        handleTestErrors(errorList);
        addToast({
          title: 'Mail server connection failed',
          message: err.response.data.message,
          type: 'info',
          duration: 2000,
          isClosable: true,
        });
      }

      setIsTesting(false);
      return null;
    }
  };
  const getAllMailServerList = async () => {
    const response = await axiosInstance.get(
      `${'emailServerConfig/list'}?$top=${1000}&$skip=${0}&$select=${'*'?.toString()}&$orderby=${'created_on desc'}`
    );
    if (response.data) {
      const linkedTemplates = response.data.rows.map((mail) => mail.itemTypeId);
      setExistsInTemplateIds(linkedTemplates);
      return {
        error: null,
        response: response?.data,
      };
    }
    return { error: 'some thing went wrong', response: null };
  };

  useEffect(() => {
    getHierarchies(setHierarchies);
  }, []);

  useEffect(() => {
    if (id) {
      getMailServer(id)
        .then((res) => {
          if (res.error) {
            addToast({
              title: 'Error',
              message: 'Failed to get mail server configuration',
              type: 'error',
            });
            navigate('/mail-server');
          } else {
            const apiData = res?.response?.rows[0] || {};

            getTemplate(apiData?.itemTypeId)
              .then((response) => {
                if (!response.error) {
                  getOffers(apiData?.itemTypeId).then((offerResponse) => {
                    const offers = offerResponse?.offers || [];

                    const selectedOffer = offers.find(
                      (offer) => offer.id === apiData.offerId
                    );

                    setMailServerState(() => ({
                      ...apiData,
                      fieldMapping: splitOptionalAndEmailMappings(
                        {
                          ...apiData.fieldMapping,
                          requiredMappings:
                            selectedOffer?.field_attributes || [],
                        },
                        response.template?.fields
                      ),
                    }));
                  });
                  setShowLoader(false);
                } else {
                  setMailServerState(apiData);
                  setShowLoader(false);
                }
              })
              .catch(() => {
                setMailServerState(apiData);
                setShowLoader(false);
              });
          }
        })
        .catch(() => {
          addToast({
            title: 'Error',
            message: 'Failed to get mail server configuration',
            type: 'error',
          });
          navigate('/mail-server');
        });
    } else if (isEmpty(mailServerState)) {
      setMailServerState({ ...emptyMailObj });
    }
    getAllMailServerList().then((res) => {
      if (res.response) {
        setAllMailList(res?.response?.rows || []);
      }
    });
  }, [id]);

  useEffect(() => {
    if (!mailServerState.itemTypeId) {
      return;
    }
    setTemplateAndRelatedData({
      template: null,
      offersList: [],
      fieldHierarchyMap: [],
    });
    Promise.all([
      getTemplate(mailServerState.itemTypeId),
      getOffers(mailServerState.itemTypeId),
    ])
      .then(([templateResponse, offersResponse]) => {
        if (templateResponse.error) {
          addToast({
            title: 'Error',
            message: 'Failed to load template data',
            type: 'error',
          });
          setTemplateAndRelatedData({
            template: null,
            offersList: [],
            fieldHierarchyMap: [],
          });
          return;
        }

        if (offersResponse.error) {
          addToast({
            title: 'Error',
            message: 'Failed to load offers data',
            type: 'error',
          });
          setTemplateAndRelatedData({
            template: null,
            offersList: [],
            hierarchy: null,
            fieldHierarchyMap: null,
          });
          return;
        }
        let fieldHierarchyMap = [];

        if (
          templateResponse?.template?.field_hierarchy_map &&
          isArray(templateResponse.template.field_hierarchy_map)
        ) {
          fieldHierarchyMap =
            templateResponse.template.field_hierarchy_map || [];
        }

        setTemplateAndRelatedData({
          template: templateResponse.template,
          offersList: offersResponse.offers,
          fieldHierarchyMap,
        });
      })
      .catch((error) => {
        addToast({
          title: 'Error',
          message: error?.message || 'Failed to load template data',
          type: 'error',
        });
      });
  }, [mailServerState.itemTypeId]);

  const handleInputWithPath = (value, path) => {
    setMailServerState((pre) => {
      if (path === 'authType') {
        return {
          ...pre,
          [path]: value,
          password: '',
          clientId: '',
          clientSecret: '',
          redirectUrl: '',
          refreshToken: '',
          azureDirId: '',
          scope: 'https://outlook.office365.com/.default',
          tokenEndpoint: '',
        };
      }
      if (path === 'itemTypeId' && existsInTemplateIds.includes(value)) {
        addToast({
          title: 'Template Already Exists',
          message: 'This template already exists in another mail server',
          type: 'info',
        });
        return {
          ...pre,
          [path]: '',
          fieldMapping: {
            emailMapping: [],
            attachmentFieldId: '',
            optionalMappings: [],
            requiredMappings: [],
          },
          offerId: '',
        };
      }

      if (path === 'itemTypeId' && pre.itemTypeId !== value) {
        return {
          ...pre,
          [path]: value,
          fieldMapping: {
            emailMapping: [],
            attachmentFieldId: '',
            optionalMappings: [],
            requiredMappings: [],
          },
          offerId: '',
        };
      }
      if (path === 'azureDirId') {
        return {
          ...pre,
          [path]: value,
          tokenEndpoint: `https://login.microsoftonline.com/${value}/oauth2/v2.0/token`,
        };
      }
      return { ...pre, [path]: value };
    });
    setErrorListWithKey((pre) => ({ ...pre, [path]: '' }));
  };

  const handleInputWithPaths = (pathValueMap) => {
    setMailServerState((pre) => ({ ...pre, ...pathValueMap }));
    const errorObj = {};

    Object.keys(pathValueMap).forEach((path) => {
      errorObj[path] = '';
    });
    setErrorListWithKey((pre) => ({ ...pre, ...errorObj }));
  };

  const updateTabForError = (error) => {
    const errorKeys = Object.keys(error);

    if (errorKeys.length) {
      setMailServerTabIndex(errorToTabIndexMap[errorKeys[0]]);
    }
  };

  const handleMailServerCreation = async () => {
    try {
      setIsLoading(true);
      const payload = createPayloadForMailServer(mailServerState);

      const { error } = validateCreateMailPayload.validate(payload, {
        abortEarly: false,
      });
      const customErrors = customValidations(
        payload,
        templateAndRelatedData.template
      );
      const customErrorForUser = validateUserName(payload, allMailList);
      if (
        !isEmpty(error) ||
        !isEmpty(customErrors) ||
        !isEmpty(customErrorForUser)
      ) {
        // TODO: Remove this line after verifying the error handling
        // const localError = processErrorWithMessage(error?.details);
        const allErrors = {
          ...processErrorWithMessage(error?.details),
          ...customErrors,
          ...customErrorForUser,
        };
        updateTabForError(allErrors);
        setErrorListWithKey(allErrors);
        // if (Object.values(localError).length === 1 && 'queueId' in localError) {
        //   setIsMailServerTab(2);
        // }
        setIsLoading(false);
        return;
      }

      const response = await axiosInstance.post('emailServerConfig/create', {
        data: mergeOptionalAndEmailMappings(payload),
      });
      if (response.data) {
        navigate('/mail-server');
        addToast({
          title: 'Success',
          message: 'Successfully created the mail server',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Error',
          message: 'Failed to create the mail server',
          type: 'error',
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      addToast({
        title: 'Something went wrong',
        message: 'Failed to create the mail server',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const handleMailServerUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = createPayloadForMailServer(mailServerState);
      const { error } = validateCreateMailPayload.validate(payload, {
        abortEarly: false,
      });
      const customErrors = customValidations(
        payload,
        templateAndRelatedData.template
      );
      const customErrorForUser = validateUserName(
        payload,
        allMailList.filter(
          (item) => item.id !== id && item.lifecycle_status === 'PUBLISHED'
        )
      );
      if (
        !isEmpty(error) ||
        !isEmpty(customErrors) ||
        !isEmpty(customErrorForUser)
      ) {
        const allErrors = {
          ...processErrorWithMessage(error?.details),
          ...customErrors,
          ...customErrorForUser,
        };
        updateTabForError(allErrors);
        setErrorListWithKey(allErrors);
        setIsLoading(false);
        return;
      }
      const response = await axiosInstance.patch(
        `emailServerConfig/update?$filter=id eq ${id}`,
        {
          data: mergeOptionalAndEmailMappings(payload),
        }
      );
      if (response.data) {
        navigate('/mail-server');
        addToast({
          title: 'Success',
          message: 'Successfully updated the mail server',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Error',
          message: 'Failed to update the mail server',
          type: 'error',
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      addToast({
        title: 'Something went wrong',
        message: 'Failed to updated the mail server',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const renderTabPanel = useCallback(
    (tab) => {
      if (showLoader && id) {
        return <Loader />;
      }
      if (tab === MAIL_SERVER_TAB[0].tabType) {
        return (
          <ConnectMailServer
            mailServerState={mailServerState}
            handleInputWithPath={handleInputWithPath}
            errorListWithKey={errorListWithKey}
            templateOptionList={templateOptionList}
          />
        );
      }
      if (tab === MAIL_SERVER_TAB[1].tabType) {
        return (
          <MailConnectionPage
            mailServerState={mailServerState}
            handleInputWithPath={handleInputWithPath}
            errorListWithKey={errorListWithKey}
            templateOptionList={templateOptionList}
          />
        );
      }
      if (tab === MAIL_SERVER_TAB[2].tabType) {
        return (
          <TicketMappingPage
            isEditMode={!!id}
            mailServerState={mailServerState}
            handleInputWithPath={handleInputWithPath}
            handleInputWithPaths={handleInputWithPaths}
            errorListWithKey={errorListWithKey}
            templateOptionList={templateOptionList}
            hierarchies={hierarchies}
            templateAndRelatedData={templateAndRelatedData}
            setErrorListWithKey={setErrorListWithKey}
          />
        );
      }
      return null;
    },
    [
      mailServerTabIndex,
      mailServerState,
      errorListWithKey,
      templateAndRelatedData,
      showLoader,
      id,
    ]
  );

  const getButtonLabel = (isMailServerTabIndex, mailId) => {
    if (isMailServerTabIndex === 0 || mailServerTabIndex === 1) {
      return 'Next';
    }
    if (mailId) {
      return 'Update';
    }
    return 'Save';
  };

  const getButtonAction = (isMailServerTabIndex, mailId) => {
    if (isMailServerTabIndex === 0 || mailServerTabIndex === 1) {
      setMailServerTabIndex(mailServerTabIndex + 1);
      return;
    }
    if (mailId) {
      handleMailServerUpdate();
    } else {
      handleMailServerCreation();
    }
  };

  return (
    <CustomDrawer
      size="xl"
      openDrawer={isOpen}
      setOpenDrawer={(e) => {
        setIsOpen(e);
        navigate('/mail-server');
      }}
      modalTitle={
        <Text style={{ fontWeight: 'bold' }}>
          {id
            ? 'Update Mail Server Configuration'
            : 'Add New Mail Server Configuration'}
        </Text>
      }
      drawerBody={
        <div>
          <CustomTabs
            tabsData={MAIL_SERVER_TAB.map((tab) => ({
              ...tab,
              content: renderTabPanel(tab.tabType),
            }))}
            onChange={(tabIndex) => {
              setMailServerTabIndex(tabIndex);
            }}
            variant="unstyled"
            specialTab
            defaultTabIndex={0}
            index={mailServerTabIndex}
            containerClassName={styles.filters__tab}
            customWidth="100%"
          />
        </div>
      }
      drawerFooter={
        <Box
          mb="20px"
          mt="20px"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap:'10px'
          }}
        >
          <SecondaryCustomButton
            isLoading={isTesting}
            buttonText="Test Connection"
            // variant="outline"
            // style={{ height: '32px' }}
            // color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={async () => {
              await testMailServerConnection();
            }}
            isDisabled={!mailServerState.authType}
            // mr="7px"
          />
          <PrimaryCustomButton
            // type="button"
            // style={{
            //   color: '#FFFFFF',
            //   fontWeight: '600',
            //   backgroundColor: '#2563EB',
            //   padding: '8px',
            //   borderRadius: '10px',
            //   width: '120px',
            //   height: '32px',
            // }}
            onClick={() => getButtonAction(mailServerTabIndex, id)}
            isLoading={isLoading}
            buttonText={getButtonLabel(mailServerTabIndex, id)}
         />
       </Box>
      }
    />
  );
};

export default AddNewMailServer;
