/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { border, Box, Flex, Radio, RadioGroup, Text, Textarea, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { LABELS } from '../../../utils/enums/labels';
// import { TemplateTypes } from '../../../utils/enums/types';
import Organization from '../../../assets/icons/Organization.svg';
import Team from '../../../assets/icons/Team.svg';
import Queue from '../../../assets/icons/Queue.svg';
import Group from '../../../assets/icons/Group.svg';
import User from '../../../assets/icons/User.svg';
import Role from '../../../assets/icons/Role.svg';
import Actors from '../../../assets/icons/Actors.svg';
import { checkForV2 } from '../../../utils/helper';
import { getNormalizedName } from '../../TicketTemplateBuilder/utils/checkForUniqueLabels';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { ALERT_COLOR, DARK, DRAFT_BG, LIGHT } from '../../../utils/enums/colors';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import CustomToolTip from '../../../components/Tooltip/Tooltip';
import { TemplateTypes } from '../../../utils/enums/types';

export const roleMapping = {
  org: 'Organization',
  teams: 'Team',
  queues: 'Queue',
  users: 'User',
  groups: 'Group',
  actors: 'Actor',
  roles: 'Role',
};

export const roleSettingType = {
  ORG: 'org',
  TEAMS: 'teams',
  QUEUES: 'queues',
  GROUPS: 'groups',
  USERS: 'users',
  ROLES: 'roles',
  ACTORS: 'actors',
  ATTRIBUTE: 'user attribute'
};

export const roleSettingSelectOpt = [
  { value: roleSettingType.ORG, label: 'Organization', icon: Organization },
  { value: roleSettingType.TEAMS, label: 'Team', icon: Team },
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ACTORS, label: 'Actors', icon: Actors },
  { value: roleSettingType.ATTRIBUTE, label: 'User Attribute', icon: Group },
  /** Disabling Actors as we don't support them in v1 */
  // { value: roleSettingType.ACTORS, label: 'Actors', icon: Actors },
];

export const CreateFlowRoleSettingOptions = [
  { value: roleSettingType.ORG, label: 'Organization', icon: Organization },
  { value: roleSettingType.TEAMS, label: 'Team', icon: Team },
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ACTORS, label: 'Actors', icon: Actors },
];

export const selectOptions = [
  { value: 'mary fox', label: 'mary fox' },
  { value: 'terry king', label: 'terry king' },
];

export const selectOptionsNoteTypes = [
  { value: 'public', label: 'Public' },
  { value: 'private', label: 'Private' },
  { value: 'chase', label: 'Chase' },
  { value: 'resolution', label: 'Resolution' },
];

export const CHANNEL_LABELS = {
  portalUser: 'Portal-User',
  portal: 'Portal',
  portalAgentEmail: 'Portal-Agent-Email',
  portalAgentWalkIn: 'Portal-Agent-Walk-In',
  portalAgentPhone: 'Portal-Agent-Phone',
  bot: 'ChatBot-Web',
  botMobile: 'ChatBot-Mobile',
  botMsTeams: 'ChatBot-MsTeams',
  email: 'Email',
  others: 'Others',
};

export const CHANNEL_VALUES = {
  portalUser: 'portal-user',
  portal: 'portal',
  portalAgentEmail: 'portal-agent-email',
  portalAgentWalkIn: 'portal-agent-walk-in',
  portalAgentPhone: 'portal-agent-phone',
  bot: 'bot',
  botMobile: 'bot-mobile',
  botMsTeams: 'bot-msteams',
  email: 'email',
  others: 'others',
};

export const selectOptionsChannels = [
  { value: CHANNEL_VALUES.bot, label: CHANNEL_LABELS.bot },
  { value: CHANNEL_VALUES.email, label: CHANNEL_LABELS.email },
  { value: CHANNEL_VALUES.portalAgentEmail, label: CHANNEL_LABELS.portalAgentEmail },
  { value: CHANNEL_VALUES.portalAgentWalkIn, label: CHANNEL_LABELS.portalAgentWalkIn },
  { value: CHANNEL_VALUES.portalAgentPhone, label: CHANNEL_LABELS.portalAgentPhone },
  { value: CHANNEL_VALUES.portal, label: CHANNEL_LABELS.portal },
  { value: CHANNEL_VALUES.portalUser, label: CHANNEL_LABELS.portalUser },
  { value: CHANNEL_VALUES.botMobile, label: CHANNEL_LABELS.botMobile },
  { value: CHANNEL_VALUES.botMsTeams, label: CHANNEL_LABELS.botMsTeams },
  { value: CHANNEL_VALUES.others, label: CHANNEL_LABELS.others },
];
export const PATH_TO_DYNAMICALLY_SET_OPTIONS_DATA = {
  CHILD_TEMPLATES_LIST: '[0][2].row[0].options',
};

export const ACCESS_KEY_NAME = {
  NAME: 'name',
  PREFIX: 'prefix',
  RUNNING_NUMBER: 'running_number',
  SUFFIX: 'suffix',
  START_AT: 'start_at',
  DESCRIPTION: 'description',
  NOTE_TYPES: 'note_types',
  CHANNELS: 'channels',
  TEMPLATE_TYPE: 'template_type',
  COLLABORATORS: 'collaborators',
  CHILD_TEMPLATES_LIST: 'child_templates_list',
  DO_AUTO_MOVE: 'doAutoMove',
  DIFF_BPM_PROCESS4CHILDREN: 'diffBpmProcess4Children',
  OWNERS: 'owners',
  STANDALONE_TEMPLATE: 'standalone_template',
};

export const checkIfNameIsDefault = (name) =>
  getNormalizedName(name || '')
    ?.toLowerCase()
    ?.trim() === 'default';

export const LayoutFields = [
  [
    {
      column: 12,
      row: [
        {
          label: LABELS.TEMPLATE_PROPERTIES.NAME,
          key: ACCESS_KEY_NAME.NAME,
          type: 'input',
          placeholder: '',
          isRequired: true,
          errorMessage: (value = '') => {
            if (!value) {
              return LABELS.LAYOUT_FIELD_ERROR.NAME_ERROR;
            }
            // if (checkIfNameIsDefault(value)) {
            //   return LABELS.LAYOUT_FIELD_ERROR.DEFAULT_NAME_ERROR;
            // }
            return '';
          },
          colSpan: 12,
        },
      ]
    },
    {
      column: 12,
      row: [
        {
          label: 'Related Ticket Only',
          key: ACCESS_KEY_NAME.STANDALONE_TEMPLATE,
          type: 'checkbox',
          colSpan: 12,
          hideFormLabel: true,
          isChildToolTip: true,
          toolTipInfo: `If checked, this ticket type can only be created in relation with a parent ticket.`,
          parentStyle: { alignItems: 'center', justifyContent: 'end' },
        },
      ],
    },
    {
      column: 24,
      row: [
        {
          label: 'Prefix',
          key: ACCESS_KEY_NAME.PREFIX,
          type: 'input',
          placeholder: '',
          isRequired: true,
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.PREFIX_ERROR,
          colSpan: 6,
          isToolTip: true,
          toolTipInfo: `This may act as an identifier that would be added before the ticket ID as the tickets get added to the list of existing tickets.`
        },
        {
          label: 'Ticket Digits',
          key: ACCESS_KEY_NAME.RUNNING_NUMBER,
          type: 'input',
          isRequired: true,
          // inputType: 'number',
          placeholder: '',
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.RUNNING_NUMBER_ERROR,
          colSpan: 10,
          isToolTip: true,
          toolTipInfo: `This would be the number of digits the ticket ID may run into.`
        },
        {
          label: 'Start at',
          key: ACCESS_KEY_NAME.START_AT,
          type: 'input',
          inputType: 'number',
          placeholder: '',
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.NAME_ERROR,
          colSpan: 4,
          isToolTip: true,
          toolTipInfo: `If you are migrating from another ITSM, you may want to Start at the last existing ticket ID + 1`
        },
        {
          label: 'Suffix',
          key: ACCESS_KEY_NAME.SUFFIX,
          type: 'input',
          placeholder: '',
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.NAME_ERROR,
          colSpan: 4,
          isToolTip: true,
          toolTipInfo: `This identifier would be added after the ticket ID.`
        },
      ],
    },
    // {
    //   column: 12,
    //   row: [
    //     {
    //       label: LABELS.TEMPLATE_PROPERTIES.TEMPLATE_TYPE,
    //       defaultValue: TemplateTypes.SINGLE,
    //       key: ACCESS_KEY_NAME.TEMPLATE_TYPE,
    //       type: 'radio',
    //       placeholder: '',
    //       isRequired: true,
    //       errorMessage: LABELS.LAYOUT_FIELD_ERROR.LAYOUT_TYPE_ERROR,
    //       colSpan: 6,
    //       width: 90,
    //       buttons: [
    //         {
    //           label: LABELS.TEMPLATE_PROPERTIES.SINGLE_TYPE,
    //           value: TemplateTypes.SINGLE,
    //           key: ACCESS_KEY_NAME.TEMPLATE_TYPE,
    //           placeholder: '',
    //           errorMessage: LABELS.LAYOUT_FIELD_ERROR.MIN_WIDTH_ERROR,
    //         },
    //         {
    //           label: LABELS.TEMPLATE_PROPERTIES.NESTED_TYPE,
    //           value: TemplateTypes.NESTED,
    //           key: ACCESS_KEY_NAME.TEMPLATE_TYPE,
    //           placeholder: '',
    //           errorMessage: LABELS.LAYOUT_FIELD_ERROR.MIN_WIDTH_ERROR,
    //         },
    //       ],
    //     },
    //     {
    //       label: 'Description',
    //       key: ACCESS_KEY_NAME.DESCRIPTION,
    //       type: 'textArea',
    //       placeholder: '',
    //       rows: 2,
    //       errorMessage: LABELS.LAYOUT_FIELD_ERROR.NAME_ERROR,
    //       colSpan: 6,
    //     },
    //   ],
    // },
    {
      ...(checkForV2()
        ? // {
          //     column: 12,
          //     row: [
          //       {
          //         label: 'Child Template(s)',
          //         key: ACCESS_KEY_NAME.CHILD_TEMPLATES_LIST,
          //         type: 'multi-select',
          //         placeholder: '',
          //         errorMessage: LABELS.LAYOUT_FIELD_ERROR.MAX_WIDTH_ERROR,
          //         colSpan: 12,
          //         width: 44,
          //       },
          //     ],
          //   }
          ''
        : {
            column: 24,
            row: [
              {
                label: 'Do Auto Move',
                iconText:
                  'Resolve Parent ticket once all Child ticket are Resolved',
                key: ACCESS_KEY_NAME.DO_AUTO_MOVE,
                type: 'checkbox',
                placeholder: '',
                colSpan: 5,
                hideFormLabel: true,
                forNested: true,
              },
              {
                label: 'Use different workflow for Children',
                key: ACCESS_KEY_NAME.DIFF_BPM_PROCESS4CHILDREN,
                type: 'checkbox',
                placeholder: '',
                colSpan: 10,
                hideFormLabel: true,
                forNested: true,
                style: { display: 'none' },
              },
            ],
          }),
    },
    {
      column: 12,
      row: [
        {
          label: 'Owners',
          key: ACCESS_KEY_NAME.OWNERS,
          type: 'input',
          placeholder: '',
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.MAX_WIDTH_ERROR,
          colSpan: 12,
        }
      ],
    },
    {
      column: 12,
      row: [
        {
          label: 'Collaborators',
          key: ACCESS_KEY_NAME.COLLABORATORS,
          type: 'select',
          placeholder: 'Select Collaborators',
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.MAX_WIDTH_ERROR,
          colSpan: 12,
          api: {
            path: `User/getUsersByRole/ticket_admin`,
            method: 'GET',
            response: {
              ref: 'data',
              labelKey: 'username',
              valueKey: 'username',
            },
          },
        },
      ]
    },
    {
      column: 12,
      row: [
        {
          label: 'Note Types',
          key: ACCESS_KEY_NAME.NOTE_TYPES,
          type: 'multi-select',
          placeholder: '',
          options: selectOptionsNoteTypes,
          errorMessage: LABELS.LAYOUT_FIELD_ERROR.MAX_WIDTH_ERROR,
          colSpan: 12,
          isRequired: true,
          value: selectOptionsNoteTypes,
        },
      ],
    },
    {
      column: 12,
      row: [
        {
          label: 'Channels',
          key: ACCESS_KEY_NAME.CHANNELS,
          type: 'multi-select',
          placeholder: '',
          options: selectOptionsChannels,
          colSpan: 12,
          isRequired: true,
          value: selectOptionsChannels,
        },
      ]
    },
    {
      column: 12,
      row: [
        {
          label: 'Description',
          key: ACCESS_KEY_NAME.DESCRIPTION,
          type: 'textArea',
          placeholder: '',
          colSpan: 12,
        }
      ]
    }
  ],
  [{}],
];
export const renderElement = (item, options, layoutState,
   updateSingle, errorState, disableIfTemplateWasApproved, getNotesOrChannelsValue, styles) => {
  let el = null;
  switch (item?.type) {
    case 'input':
      el = (
        <CustomInput
          type={item?.inputType || 'text'}
          value={layoutState[item?.key]}
          defaultValue={
            item?.key === ACCESS_KEY_NAME.OWNERS
              ? localStorage.getItem('userEmail')
              : ''
          }
          onChange={(e) =>
            updateSingle(item?.key, e.target.value, item?.isRequired)
          }
          isError={errorState[item?.key]}
          isDisabled={disableIfTemplateWasApproved(item)}
          id="CustomTemplateModalDivCInput"
        />
      );
      break;
    case 'textArea':
      el = (
        <Textarea
          type="text"
          inputType={item?.inputType}
          rows={item?.rows}
          value={layoutState[item?.key]}
          onChange={(e) =>
            updateSingle(item?.key, e.target.value, item?.isRequired)
          }
          isError={errorState[item?.key]}
        />
      );
      break;
    case 'select':
      el = (
        <CustomSelectBox
          // value={
          //   selectOptions[item.key]?.find(
          //     (option) => option.value === layoutState[item.key]
          //   ) || layoutState[item.key]
          // }
          // options={selectOptions[item.key] || item.options}
          options={item?.options}
          value={layoutState[item.key] ? { label: layoutState[item.key], value: layoutState[item.key] } : null}
          onChange={(selectedOption) =>
            updateSingle(item?.key, selectedOption.value, item?.isRequired)
          }
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          isDisabled={item?.isDisabled}
        />
      );
      break;
    case 'multi-select':
      el = (
        <CustomSelectBox
          options={
            item?.key === 'child_templates_list' ? options : item?.options
          }
          value={getNotesOrChannelsValue(layoutState, item)}
          isMulti
          onChange={(value) =>
            updateSingle(item?.key, value, item?.isRequired)
          }
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          isDisabled={item?.isDisabled}
        />
      );
      break;
    case 'checkbox':
      el = (
        <Flex style={item?.parentStyle}>
          <CustomCheckBox
            isChecked={layoutState[item?.key] || false}
            checkBoxText={item?.label || ''}
            onChange={(e) => {
              updateSingle(item?.key, e.target.checked, item?.isRequired);
            }}
            size="md"
            style={item?.style}
          >
            <Box display="flex">
              {item?.iconText && (
                <CustomToolTip id="tooltip-test" toolTipText={item?.iconText}>
                  <InfoOutlineIcon mr="2" mt="0.5" />
                </CustomToolTip>
              )}
              <span className="text-[14px] block">{item?.label}</span>
            </Box>
          </CustomCheckBox>
          {item?.isChildToolTip && (
             <Tooltip
             maxWidth="441px"
             borderRadius="6px"
             label={
               <Flex
                 justifyItems="center"
                 justifyContent="center"
                 alignItems="start"
                 p="10px"
               >
                 <InfoOutlineIcon
                   width="24px"
                   height="24px"
                   mr="10px"
                   cursor="pointer"
                   color={DRAFT_BG}
                 />
                 <Text className={styles.label}>{item?.toolTipInfo}</Text>
               </Flex>
             }
             className="shadow-lg"
             bg={useColorModeValue('white', '#374151')}
             color={useColorModeValue(ALERT_COLOR, 'white')}
           >
             <InfoOutlineIcon ml="5px" />
           </Tooltip>
          )}
        </Flex>
      );
      break;
    case 'radio':
      el = (
        <RadioGroup
          className={styles.radiobutton}
          value={layoutState[item?.key] || TemplateTypes.SINGLE}
          onChange={(e) => {
            updateSingle(item?.key, e, item?.isRequired);
          }}
        >
          {item.buttons?.map((btn) => (
            <Radio
              value={btn.value}
              key={btn.value}
              isDisabled={btn?.isDisabled}
            >
              {btn.label}
            </Radio>
          ))}
        </RadioGroup>
      );
      break;
    default:
  }
  return el;
};
