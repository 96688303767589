/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useColorModeValue } from '@chakra-ui/react';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';

const CustomOption = ({ children, innerProps, dataTestId, ...optionProps }) => (
  <components.Option
    {...optionProps}
    innerProps={{ ...innerProps, 'data-testid': dataTestId }}
  >
    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',gap:'5px'}}>
    { optionProps?.options?.find(data => data?.label === optionProps?.label)?.icon && <span className="input-select__icon">{optionProps?.options?.find(data => data?.label === optionProps?.label)?.icon }</span> }
    <p style={{fontSize:'14px',fontWeight:400}}>
    {children || null}
    </p>
    </div>
  </components.Option>
);

const CustomSelectBox = ({
  options,
  id,
  isMulti,
  value,
  label,
  onChange,
  isError,
  errorMessage,
  placeholder,
  isDisabled,
  isMandatory,
  isClearable,
  menuShouldBlockScroll,
  defaultValue,
  styles,
  className,
  sourceCustomise=false,
  ...props
}) => {
  const ref = useRef(null);
  const parentContainer = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const findScrollParent = useCallback((element) => {
    if (!element) return null;

    const style = window.getComputedStyle(element);
    const overflowRegex = /(auto|scroll)/;

    if (
      overflowRegex.test(style.overflow) ||
      overflowRegex.test(style.overflowX) ||
      overflowRegex.test(style.overflowY)
    ) {
      return element;
    }

    return findScrollParent(element.parentElement);
  }, []);

  useEffect(() => {
    if (!ref.current || !menuOpen) {
      return;
    }

    let scrollElem = parentContainer.current;
    if (!scrollElem) {
      scrollElem = findScrollParent(ref.current);
      parentContainer.current = scrollElem;
    }
    const updateBoundingRect = () => {
      setMenuOpen(false);
    };

    scrollElem?.addEventListener('scroll', updateBoundingRect);

    // eslint-disable-next-line consistent-return
    return () => scrollElem?.removeEventListener('scroll', updateBoundingRect);
  }, [findScrollParent, menuOpen]);

  const optionComponent = (componentProps) => (
    <CustomOption {...componentProps} dataTestId={`${componentProps.data.label}`} />
  );

  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 8 }}>{props.data.icon}</span> 
          {props.data.label} 
        </div>
      </components.SingleValue>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#DEEBFF' :'white',
      color: state.isFocused ? '#DEEBFF' : provided.color,
    }),
  }


  return (
    <div id={label} data-testid={id} ref={ref}>
      <div style={label ? { marginBottom: '.5rem' } : {}}>
        <label className="SCLabel">{label}</label>
        {isMandatory && (
          <span style={{ color: '#CF3626', marginLeft: '4px' }}>*</span>
        )}
      </div>
      <Select
        menuPlacement='bottom'
        placeholder={placeholder}
        id="customSelectBox"
        onChange={onChange}
        value={value}
        options={options}
        isMulti={isMulti}
        styles={styles || customStyleFromRuleForSelect}
        className={
          isError && errorMessage
            ? (className ? `${className} error-border-react-select` : 'error-border-react-select')
            : (className ? useColorModeValue('', `${className} react-select-dark`) : useColorModeValue('', 'react-select-dark'))
        }
        menuIsOpen={menuOpen}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        isDisabled={isDisabled}
        isMandatory={isMandatory}
        isClearable={isClearable}
        menuShouldBlockScroll={menuShouldBlockScroll}
        defaultValue={defaultValue}
        {...( sourceCustomise && sourceCustomise?.includes(['leftOperatorList']))&& {components:{ Option: optionComponent ,SingleValue: CustomSingleValue }} }
      />
      {isError && errorMessage && (
        <span className="field-error">{errorMessage}</span>
      )}
    </div>
  );
};

CustomSelectBox.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  menuShouldBlockScroll: PropTypes.bool,
  defaultValue:PropTypes.string,
  styles: PropTypes.object
};

CustomSelectBox.defaultProps = {
  options: [],
  isMulti: false,
  id: '',
  label: '',
  value: undefined,
  isMandatory: false,
  placeholder: '',
  isClearable: false,
  isError: false,
  errorMessage: '',
  isDisabled: false,
  menuShouldBlockScroll: true,
  defaultValue: '',
  styles: null,
};

export default CustomSelectBox;
