import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
// import CustomModal from '../../../components/Modal/Modal';
import styles from '../AddNewRoleModal/rolecontent.module.scss';
import RoleThree from '../../../assets/icons/Role_6.svg';
import {
  LABELS,
  ROLE_DESCRIPTIONS,
  ROLE_TITLE,
} from '../../../utils/enums/labels';
import CustomModalFooter from '../CreateEditFlow/CustomModalFooter';
import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import {
  addRuleToSelectedWorkFlowSate,
  changeSelectedRuleWithKey,
  initSelectedRule,
  removeRuleFromSelectedWorkFlowState,
  updateRuleToSelectedWorkFlowSate,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import CustomModalFooterWithDelete from '../../../components/CustomModalFooterWithDelete';
import RuleDeleteModal from '../ShowEditEdge/RuleDeleteModal';
import { RULE_BASIC_VALIDATIONS } from '../../../utils/validationSchemas/workflow/rule';
import { validationErrorMessage } from '../../../utils/validationSchemas/validationSchemas';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import RestricLinkedTicketContain from './RestricLinkedTicketContain';

const RestrictLinkedTicketSpecificStatusModal = ({
  isRestrictLinkedTicketSpecificStatusValue,
  setIsRestrictLinkedTicketSpecificStatusValue,
  edit,
}) => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [errorData, setErrorData] = useState({});

  return (
    <>
      <CustomDrawer
        id="selected-field-modal"
        modalTitle={edit ? LABELS.ACTIONS.EDIT_RULE : LABELS.ACTIONS.ADD_RULE}
        size="lg"
        drawerBody={
          <>
            <Flex data-testid="RestrictSpecificValueModalRoleFlex">
              <Box
                className={styles.boxSvg}
                bgColor="#FFF7EA"
                data-testid="RestrictSpecificValueModalRoleBox"
              >
                <ReactSVG
                  src={`${RoleThree}`}
                  alt="image"
                  data-testid="RestrictSpecificValueModalRoleReactSvg"
                />
              </Box>

              <Box
                className={styles.cardbody}
                data-testid="RestrictSpecificValueModalCardbodyBox"
              >
                <div
                  style={{ marginLeft: '20px' }}
                  data-testid="RestrictSpecificValueModalCardbodyDiv"
                >
                  <Heading
                    className={styles.cardheading}
                    data-testid="RestrictSpecificValueModalCardbodyHeading"
                  >
                    {ROLE_TITLE.RESTRICT_LINKED_TICKET}
                  </Heading>
                  <Text
                    className={styles.carddesc}
                    data-testid="RestrictSpecificValueModalCardbodyText"
                  >
                    {ROLE_DESCRIPTIONS.RESTRICT_LINKED_TICKET}
                  </Text>
                </div>
              </Box>
            </Flex>
            <RestricLinkedTicketContain
              errorData={errorData}
              setErrorData={setErrorData}
            />
          </>
        }
        drawerFooter={
          edit ? (
            <CustomModalFooterWithDelete
              btnLabel="Update Rule"
              pt="10px"
              // isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setErrorData({});
                setIsRestrictLinkedTicketSpecificStatusValue(false);
              }}
              onAddClick={() => {
                const { error } = RULE_BASIC_VALIDATIONS.validate(
                  selectedWorkFlowRule,
                  { abortEarly: false }
                );

                if (error) {
                  const errors = validationErrorMessage(error);
                  setErrorData({ ...errors });
                  return;
                }
                dispatch(updateRuleToSelectedWorkFlowSate(dispatch));
                setIsRestrictLinkedTicketSpecificStatusValue(false);
              }}
              onDeleteCllick={() => {
                setIsRestrictLinkedTicketSpecificStatusValue(false);
                setIsDeleteModal(true);
              }}
            />
          ) : (
            <CustomModalFooter
              btnLabel={LABELS.ACTIONS.ADD_RULE}
              pt="10px"
              // isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(updateRuleModalId(''));
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setErrorData({});
                setIsRestrictLinkedTicketSpecificStatusValue(false);
              }}
              onAddClick={() => {
                dispatch(updateRuleModalId(''));
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'id',
                    value: uuidv4(),
                  })
                );
                const { error } = RULE_BASIC_VALIDATIONS.validate(
                  selectedWorkFlowRule,
                  { abortEarly: false }
                );
                if (error) {
                  const errors = validationErrorMessage(error);
                  setErrorData({ ...errors });
                  return;
                }
                dispatch(addRuleToSelectedWorkFlowSate(dispatch));
                setIsRestrictLinkedTicketSpecificStatusValue(false);
              }}
            />
          )
        }
        openDrawer={isRestrictLinkedTicketSpecificStatusValue}
        setOpenDrawer={() => {
          setIsRestrictLinkedTicketSpecificStatusValue(
            !isRestrictLinkedTicketSpecificStatusValue
          );
          dispatch(updateRuleModalId(''));
          dispatch(
            updateStateOfWorkFlowWithKey({
              keyValue: 'selectedWorkFlowRule',
              value: initSelectedRule,
            })
          );
          setErrorData({});
        }}
      />
      <RuleDeleteModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        onConfirmation={() => {
          dispatch(removeRuleFromSelectedWorkFlowState());
          setIsDeleteModal(false);
        }}
        onDeleteCancelClick={() => {
          setIsDeleteModal(false);
          setIsRestrictLinkedTicketSpecificStatusValue(true);
        }}
        title={LABELS.TITLE.DELETE_RULE_WARNING}
        subTitle={ROLE_TITLE.SPECIFIC_VALUE}
      />
    </>
  );
};
RestrictLinkedTicketSpecificStatusModal.propTypes = {
  setIsRestrictLinkedTicketSpecificStatusValue: PropTypes.func.isRequired,
  isRestrictLinkedTicketSpecificStatusValue: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
};
RestrictLinkedTicketSpecificStatusModal.defaultProps = {
  edit: false,
};
export default RestrictLinkedTicketSpecificStatusModal;
