import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Flex, useColorMode, useColorModeValue } from '@chakra-ui/react';

import Upload from 'rc-upload';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { WarningIcon } from '@chakra-ui/icons';
import AttachmentPreView from './AttachmentPreView';

import { deleteAttachment, getImage, getMimeType, postImage } from './utils';
import { COLORS } from '../../utils/enums/colors';

import styles from './Attachment.module.scss';

const AttachmentUpload = ({
  onImageUpload,
  blobName,
  onImageDelete,
  fileSupportForUpload,
  maxFileSize,
  maxNoOfFileAllow,
}) => {
  const { colorMode } = useColorMode();
  const [tempImageUrl, setTempImageUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [errorString, setErrorString] = useState('');

  const onDelete = useCallback((blobFileName, reuploaded = false) => {
    if (!reuploaded) {
      setIsLoading(true);
    }
    deleteAttachment(blobFileName)
      .then((res) => {
        if (res && !reuploaded) {
          onImageDelete([...blobName].filter((item) => item !== blobFileName));
          setTempImageUrl((pre) => [
            ...pre.filter((item) => item.blobName !== blobFileName),
          ]);
        }
      })
      .finally(() => {
        if (!reuploaded) {
          setIsLoading(false);
        }
      });
  }, []);
  function beforeUpload(file) {
    setErrorString('');
    return new Promise((resolve, reject) => {
      const fileName = file?.name;
      const mimeType = getMimeType(fileName);

      if (file.size > maxFileSize) {
        setErrorString('Uploaded file exceeds max size');
        reject(new Error('File size exceeds the limit of 4 MB'));
        return;
      }

      if (!fileSupportForUpload.includes(mimeType)) {
        setErrorString('Uploaded file type not supported');
        reject(new Error('Unsupported file type'));
        return;
      }

      resolve(file);
    });
  }
  const customRequest = useCallback(
    ({ file }) => {
      setIsLoading(true);
      postImage(file)
        .then((res) => {
          if (res) {
            getImage(res.blobName).then((imgRes) => {
              setTempImageUrl((pre) => [
                ...pre,
                { ...imgRes, blobName: imgRes.blobName },
              ]);
            });
            onImageUpload([...blobName, res.blobName].filter(Boolean));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onImageUpload, onDelete, tempImageUrl, blobName]
  );

  const props = useMemo(
    () => ({
      multiple: false,
      beforeUpload,
      customRequest,
      onError: (err, response, file) => {
        console.warn('onError in  Upload', err, response, file);
      },
    }),
    [customRequest, beforeUpload]
  );

  useEffect(() => {
    if (!isEmpty(blobName)) {
      setIsImageLoading(true);
      const dataList = [];
      const promises = [...blobName]
        .filter(Boolean)
        .map((item) => getImage(item));

      Promise.all(promises)
        .then((responses) => {
          responses.forEach((res, index) => {
            if (!isEmpty(res)) {
              dataList.push({ ...res, blobName: blobName[index] });
            }
          });

          setTempImageUrl(dataList);
          setIsImageLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching images:', error);
          setIsImageLoading(false);
        });
    }
  }, [blobName]);

  return (
    <div>
      {isEmpty(tempImageUrl) && !isImageLoading ? (
        <Upload {...props}>
          <Flex w="100%">
            <Flex
              className="calculatedHeight"
              fontSize="14px"
              border={`1px solid ${COLORS.LIGHT_BORDER}`}
              borderRight="none"
              alignItems="center"
              p="0 8px"
              color={useColorModeValue('#6B7280','')}
              borderLeftRadius="6px"
              w="100%"
            >
              {isLoading ? 'Uploading...' : 'Choose Files to upload'}
            </Flex>
            <Button
              colorScheme="blackAlpha"
              height="32px"
              borderLeft="none"
              borderLeftRadius="none"
              isLoading={isLoading}
              className={`${classNames(styles.browse__files__button, {
                [styles.browse__files__button__dark]: colorMode === 'dark',
              })} calculatedHeight`}
            >
              Browse Files
            </Button>
          </Flex>
        </Upload>
      ) : (
        <AttachmentPreView
          uploadProps={props}
          attachment={tempImageUrl}
          createMode={isEmpty(tempImageUrl)}
          onDelete={onDelete}
          isLoading={isLoading}
          isImageLoading={isImageLoading}
          isDarkMode={colorMode === 'dark'}
          maxNoOfFileAllow={maxNoOfFileAllow}
        />
      )}
      {/* {uploading && <Progress hasStripe value={progress} />} */}
      {errorString && (
        <Box
          display="flex"
          p="8px"
          bgColor="#E53E3E21"
          mt="8px"
          data-testid="ShowEditStatusWarningBox"
          color="#E53E3E"
        >
          <WarningIcon color="#E53E3E" />
          <p
            className="text-[12px] pl-[5px]"
            data-testid="ShowEditStatusWarningP"
          >
            {errorString}
          </p>
        </Box>
      )}
    </div>
  );
};

AttachmentUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
  blobName: PropTypes.array,
  isMultipleFileAllow: PropTypes.bool,
  fileSupportForUpload: PropTypes.array,
  maxFileSize: PropTypes.number,
  maxNoOfFileAllow: PropTypes.number,
};

AttachmentUpload.defaultProps = {
  blobName: [],
  isMultipleFileAllow: false,
  fileSupportForUpload: [],
  maxFileSize: 4 * 1024 * 1024,
  maxNoOfFileAllow: 1,
};

export default AttachmentUpload;
