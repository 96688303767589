/* eslint-disable react/prop-types */
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CustomInput from '../../../components/InputBox/Input';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import { updateSelectedWorkFlowName } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const WorkflowNameChange = ({ isEditWorkflowName, setIsEditWorkflowName }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams({ name: '' });
  const { selectedWorkFlow } = useSelector((state) => state.workFlowMain);
  const [nameOfWorkflow, setNameOfWorkflow] = useState(
    selectedWorkFlow.name || searchParam.get('name') || ''
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setNameOfWorkflow(selectedWorkFlow.name);
    // setSearchParam((pre) => {
    //   pre.set('name', selectedWorkFlow.name);
    //   return pre;
    // });
  }, [selectedWorkFlow]);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={() => {
        setIsEditWorkflowName(!isEditWorkflowName);
      }}
      isOpen={isEditWorkflowName}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setIsEditWorkflowName(!isEditWorkflowName);
              }}
            />
            <Text fontSize="16px">Edit Workflow name</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Grid mt="38px" data-testid="AddEditWorkflowListContentGrid">
            <GridItem w="100%" data-testid="AddEditWorkflowListContentGridItem">
              {/* <FormLabel className={styles.formLabelForActor}>
              Name
              <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
            </FormLabel> */}
              <CustomInput
                label="Name"
                type="text"
                value={nameOfWorkflow}
                onChange={(e) => {
                  setNameOfWorkflow(e.target.value);
                }}
                isMandatory
                id="AddEditWorkflowListCustomInput"
              />
            </GridItem>
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <Flex gap="8px" data-testid="AddEditWorkflowListFooterFlex">
          <SecondaryCustomButton
              // variant="outline"
              // pl="25px"
              // pr="25px"
              // buttonColor="#868E96"
              buttonText={LABELS.ACTIONS.CANCEL}
              // style={{ border: 'none' }}
              // _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
              onClick={() => {
                setIsEditWorkflowName(false);
              }}
              // size="lg"
              id="AddEditWorkflowListFooterCanceltn"
            />
             <PrimaryCustomButton
              // variant="solid"
              // pl="25px"
              // pr="25px"
              // buttonColor="customBlue"
              isDisabled={isEmpty(nameOfWorkflow)}
              buttonText="Save"
              onClick={() => {
                setIsLoading(true);
                dispatch(
                  updateSelectedWorkFlowName({
                    value: nameOfWorkflow,
                    keyValue: 'name',
                  })
                );
                setSearchParam((pre) => {
                  pre.set('name', selectedWorkFlow.name);
                  return pre;
                });
                setIsLoading(false);
                setIsEditWorkflowName(false);
              }}
              // size="lg"
              id="AddEditWorkflowListFooternamebtn"
              isLoading={isLoading}
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default WorkflowNameChange;
