/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Text, Flex } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import {
  calc,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack
} from '@chakra-ui/react';
import { cloneDeep, isEmpty } from 'lodash';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CustomInput from '../../../components/InputBox/Input';
import styles from '../styles/templatebuilder.module.scss';
import { LayoutFields } from './utils/layoutTemplateJson';
import { DARK, LIGHT, RULE_CARD_BORDER } from '../../../utils/enums/colors';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { customStyles } from '../../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import { LAYOUT_PROPERTY_LABELS } from '../../../utils/enums/labels';
import { layoutTypes, layoutViewTypes } from '../../../utils/enums/selectOption';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const getLayoutTypeOptions = (layoutState, item, channels) => {
  if (item?.label === LAYOUT_PROPERTY_LABELS.LAYOUT_TYPE) {
    if (layoutState?.viewType === layoutViewTypes.INBOX) {
      return item?.option2;
    }

    if (layoutState?.viewType === layoutViewTypes.TRANSITION) {
      return item?.option3;
    }

    if (layoutState?.viewType === layoutViewTypes.DEFERRED) {
      return item?.option3;
    }
  }

  if (channels.includes('bot')) {
    return item?.options;
  }

  return item?.option4;
};

const AddEditTemplateLayoutModal = ({
  isOpenLayoutModal,
  setIsOpenLayoutModal,
  isEdit,
  layoutData,
  onConfirmation,
  channels,
  widthDrawer,
  heightDrawer,
  maxWidthDrawer,
  topDrawer,
  showOverlay,
  bottomDrawer,
  boxShadow
}) => {
  const [layoutState, setLayoutState] = useState(layoutData);
  const [errorState, setErrorState] = useState({});

  const updateLayoutState = (key, value) => {
    // eslint-disable-next-line prefer-const
    let newdata = { ...layoutState };
    newdata[key] = value;
    if (key === 'viewType') {
      if (value === layoutViewTypes.INBOX) {
        newdata.type = layoutTypes.DESKTOP;
      } else if (value === layoutViewTypes.TRANSITION) {
        newdata.type = layoutTypes.MOBILE;
      } else if (value === layoutViewTypes.DEFERRED) {
        newdata.type = layoutTypes.MOBILE;
      }
    }
    const errorData = { ...errorState, [key]: !value };
    setErrorState(errorData);
    setLayoutState(newdata);
  };
  const [isLoading, setIsloading] = useState(false);
  const onFinish = async () => {
    setIsloading(true);
    let upd = cloneDeep(layoutState)
    upd.name = upd.name?.replace(/\s+/g, ' ').trim()
    if (!layoutState?.name || !layoutState?.type) {
      const newError = {
        ...errorState,
        name: !layoutState?.name,
        type: !layoutState?.type,
      };
      setErrorState(newError);
      setTimeout(() => {
        setIsloading(false);
      }, 5000);
    } else if (isEmpty(layoutState.viewType)) {
      onConfirmation({ ...upd, viewType: 'GRID' });
      setTimeout(() => {
        setIsloading(false);
      }, 5000);
    } else {
      onConfirmation(upd);
      setTimeout(() => {
        setIsloading(false);
      }, 5000);
    }
  };

  const drawerFooter = (
    <Box className="flex gap-3">
      <SecondaryCustomButton
        onClick={() => {
          setIsOpenLayoutModal(false);
        }}
        buttonText="Cancel"
        variant="outline"
        _hover={{ background: 'transparent' }}
        id="AddEditTemplateLayoutModalNameTextCancelBtn"
      />
      <PrimaryCustomButton
        onClick={() => {
          onFinish();
        }}
        buttonText="Save"
        isLoading={isLoading}
        id="AddEditTemplateLayoutModalNameCreateBtn"
      />
    </Box>
  );

  const drawerBody = (
    <Stack direction="column" spacing={3}>
        {LayoutFields(layoutState)?.map((item) => (
          <FormControl
            isRequired={item?.isRequired}
            key={item?.key}
            isInvalid={errorState[item?.key]}
            data-testid="AddEditTemplateLayoutModalFormControl"
          >
            <FormLabel data-testid="AddEditTemplateLayoutModalFormLabel">
              {isEdit && item.label === 'View Type' ? null : item?.label}
            </FormLabel>
            {item?.type === 'input' ? (
              <CustomInput
                type="email"
                value={layoutState[item?.key]}
                onChange={(e) => updateLayoutState(item?.key, e.target.value)}
                isError={errorState[item?.key]}
                id="AddEditTempCIDivCInput"
              />
            ) : item?.type === 'radio' ? (
              isEdit !== true && (
                <Box w="200%">
                  <RadioGroup
                    className={styles.radiobutton}
                    value={layoutState[item?.key] || item?.defaultValue}
                    onChange={(e) => updateLayoutState(item?.key, e)}
                    data-testid="FieldContentRow2RadioGroup"
                  >
                    {' '}
                    {item?.options?.map((grid) => (
                      <Radio
                        value={grid.value}
                        key={grid.value}
                        isDisabled={grid?.isDisabled}
                      >
                        {grid.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </Box>
              )
            ) : (
              <CustomSelectBox
                options={getLayoutTypeOptions(layoutState, item, channels)}
                value={
                  getWholeOption(layoutState[item?.key], item?.options) ||
                  item?.defaultValue
                }
                onChange={(value) => updateLayoutState(item?.key, value?.value)}
                isClearable
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isDisabled={item?.isDisabled}
                styles={customStyles('100%')}
                id="AddEditTemplateSelectBox"
              />
            )}
            {errorState[item?.key] && item?.isRequired && (
              <FormErrorMessage>{item?.errorMessage}</FormErrorMessage>
            )}
          </FormControl>
        ))}
    </Stack>
  );

  const modalTitle = `${isEdit ? 'Edit' : 'Add'} Layout`;

  return (
    <CustomDrawer
      drawerBody={drawerBody}
      drawerFooter={drawerFooter}
      modalTitle={modalTitle}
      openDrawer={isOpenLayoutModal}
      setOpenDrawer={setIsOpenLayoutModal}
      widthDrawer={widthDrawer}
      heightDrawer={heightDrawer}
      maxWidthDrawer={maxWidthDrawer}
      topDrawer={topDrawer}
      showOverlay={showOverlay}
      bottomDrawer={bottomDrawer}
      boxShadow={boxShadow}
    />
  );
};

export const CustomDrawer = ({
  // eslint-disable-next-line react/prop-types
  drawerBody,
  // eslint-disable-next-line react/prop-types
  drawerFooter,
  // eslint-disable-next-line react/prop-types
  setOpenDrawer,
  // eslint-disable-next-line react/prop-types
  openDrawer,
  // eslint-disable-next-line react/prop-types
  modalTitle,
  // eslint-disable-next-line react/prop-types
  size,
  widthDrawer,
  heightDrawer,
  maxWidthDrawer,
  topDrawer,
  showOverlay,
  bottomDrawer,
  boxShadow
}) => (
    <Drawer
      size={size || 'lg'}
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      data-testid="AddOrEditEmailTemplateDrawerEmail"
      closeOnOverlayClick={false}
    >
     { showOverlay && <DrawerOverlay />  }
      <DrawerContent 
     style={{
      width: widthDrawer || 'auto',
      height: heightDrawer || 'auto',
      maxWidth: maxWidthDrawer || '100%',
      bottom: bottomDrawer || '0px',
      top: topDrawer || '0px',
      boxShadow : boxShadow || 'auto'
    }}
      >
        <DrawerHeader>
          <Flex alignItems="center" gap="10px" borderBottom={`1px solid ${RULE_CARD_BORDER}`} pb="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">
              {modalTitle}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody overflowX="hidden" overflowY="auto" paddingRight="15px">
          {drawerBody}
        </DrawerBody>
        {drawerFooter && (
<DrawerFooter>
          {drawerFooter}
</DrawerFooter>
        )
        }
      </DrawerContent>
    </Drawer>
);

AddEditTemplateLayoutModal.propTypes = {
  isOpenLayoutModal: PropTypes.bool.isRequired,
  setIsOpenLayoutModal: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  layoutData: PropTypes.object,
  channels: PropTypes.arrayOf(PropTypes.object),
  widthDrawer:PropTypes.string,
  heightDrawer:PropTypes.string,
  maxWidthDrawer:PropTypes.string,
  topDrawer:PropTypes.string,
  bottomDrawer:PropTypes.string,
  showOverlay:PropTypes.bool,
  boxShadow: PropTypes.string

};

AddEditTemplateLayoutModal.defaultProps = {
  isEdit: false,
  layoutData: {},
  channels: [],
};

export default AddEditTemplateLayoutModal;
