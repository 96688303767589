import { isDarkThemeApplied } from '../../pages/TicketTemplateBuilder/utils/helper';
import { COLORS } from '../../utils/enums/colors';

const getColorTheme = (isDark) => (isDark ? '#BB86FC' : '#2563EB');
const getBackgroundColor = (isDark, isDisabled) => {
  if (isDisabled) {
    return isDark ? '#201f22' : '#fafafb';
  }
  return isDark ? '#4F5765' : '#fff';
};

const getTextColor = (isDark, isDisabled) =>{
  if (isDisabled) {
    return isDark ? '#59606b' : '#c7c9cf';
  }
  return isDark ? 'white' : '#6B7280';
};

export const customStyleFromRuleForSelect = {
  control: (provided, state) => {
    const isSelected = state?.isSelected || state?.isFocused;
    const isDark = isDarkThemeApplied();
    const borderColor = state.isFocused || state.menuIsOpen ? '#d1d5db' : (isSelected ? getColorTheme(isDark) : '#d1d5db');
    return {
      ...provided,
      borderRadius: '6px',
      minHeight: '30px !important',
      height: 'auto', // Adjust height automatically based on content
      minWidth: '12.5rem',
      color: getTextColor(isDark),
      boxShadow: state.isFocused ? null : null,
      background: getBackgroundColor(isDark, state.isDisabled),
      borderColor,
      fontSize: '14px',
      overflow: 'hidden', // Hide overflow to prevent unwanted scroll bars
      // maxWidth:'300px'
    };
  },
  menu: (...props) => {
    const position = {};

    const minTop = 2;

    try {
      const {
        width,
        bottom,
        top: boundingTop,
      } = props[1].innerRef.current.previousSibling.getBoundingClientRect();
      const { height } = props[1].innerRef.current.getBoundingClientRect();
      const inputValue = props?.[1]?.selectProps?.inputValue || '';
      position.width = `${width}px`;
      const top = bottom + minTop;
      if (inputValue?.length === 1) position.top = `${top}px`;
      else if (height + top >= window.innerHeight) {
        position.top = `${boundingTop - height - 10}px`;
      } else {
        position.top = `${top}px`;
      }
    } catch (err) {
      position.width = '100%';
      position.top = `${minTop}px`;
    }

    return {
      marginTop: '4px', // Ensure there is a gap between the control and menu
      zIndex: 99999,
      position: 'fixed',
      background: isDarkThemeApplied() ? '#4F5765' : '#fff',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      ...position,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '30px !important',
    height: 'auto', // Adjust height automatically based on content,
    padding: '0 6px',
    color: isDarkThemeApplied() ? 'white' : `${COLORS.SECONDARY_TEXT}`,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' :`${COLORS.SECONDARY_TEXT}`,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: isDarkThemeApplied()
      ? 'hsl(0, 0%, 20%)'
      : 'hsl(0, 0%, 90%)',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  input: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : `${COLORS.SECONDARY_TEXT}`,
    margin: '0px',
    '::before': {
      position: 'relative',
    },
    '::after': {
      position: 'relative',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    paddingRight: '0',
    cursor: 'pointer',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingLeft: '0',
    marginLeft: '0',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto', // Adjust height automatically based on content
    minHeight: '30px !important',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: '#111827',               // color of the X icon
    ':hover': {
      color: '#111827',             // custom icon color on hover
      backgroundColor: 'none'
    },
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    padding: '0px',   
    paddingLeft: '0px',
    fontSize:'14px',
    color: '#2563EB',
    fontWeight: '400',
    lineHeight: 'normal'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#DEE8FC', // background of the badge
    color: '#2563EB',               // text color inside the badge
    borderRadius: '5px',
    padding: '4px 8px',
    gap: '4px'
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    const isDark = isDarkThemeApplied();
    const selectedBackgroundColor = isDark ? '#deebff' : '#deebff';
    const focusedBackgroundColor = isDark ? '#394353' : '#deebff';
    const backgroundColor = (() => {
      if (isDisabled) return 'blue';
      if (isSelected) return selectedBackgroundColor;
      if (isFocused) return focusedBackgroundColor;
      return undefined;
    })();

    const textColor = isSelected ? 'black' : (isDark ? 'white' : 'hsl(0, 0%, 20%)');

    const activeBackgroundColor =
      !isDisabled && (isSelected || isFocused) ? backgroundColor : undefined;

    return {
      ...provided,
      backgroundColor,
      color: textColor, 
      cursor: 'pointer',
      ':active': {
        ...provided[':active'],
        backgroundColor: activeBackgroundColor,
      },
      textAlign: 'start',
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    color: getTextColor(isDarkThemeApplied(), state.isDisabled),
  }),
};

export default customStyleFromRuleForSelect;
