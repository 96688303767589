import React,{useState} from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  useColorModeValue,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { Button } from '@chakra-ui/button';
import styles from '../../pages/TemplateCardListView/cardLayout.module.scss';
import NotificationView from './NotificationView';
import Notification from '../../assets/icons/Notification.svg';
import NotificationWhite from '../../assets/icons/Notification_white.svg';
import { HEADER_TOOLTIP_LABELS } from '../../utils/enums/labels';
import { HiOutlineBell } from "react-icons/hi2";

const RightNotification = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <Tooltip
        label={HEADER_TOOLTIP_LABELS.NOTIFICATIONS}
        placement="bottom"
        hasArrow
        data-testid="rightNotificationTooltip"
        isOpen={isTooltipVisible}
      >
        <Button
          variant="link"
          data-testid="notificationButton"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          {/* <ReactSVG
            src={useColorModeValue(Notification, NotificationWhite)}
            className={styles.menuIcons}
            data-testid="rightNotificationReacSVGMenuIcons"
          /> */}
          <HiOutlineBell size={20} color={'#94A3B8'}/>
          </Button>
      </Tooltip>

      <Drawer
        size="sm"
        placement="right"
        onClose={onClose}
        isOpen={isOpen}
        data-testid="drawer"
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent data-testid="rightNotificationDrawerContent">
          <DrawerHeader
            fontSize="26px"
            pt="1.25rem"
            pb="0px"
            data-testid="rightNotificationDrawerHeader"
          >
            <Flex alignItems="center" data-testid="rightNotificationFlex">
              <ReactSVG
                src={useColorModeValue(Notification, NotificationWhite)}
                className={styles.notificationSize}
                data-testid="rightNotificationNotificationSize"
              />
              <Text marginLeft="10px" data-testid="rightNotificationText">
                {' '}
                Notifications
              </Text>
            </Flex>
            <DrawerCloseButton data-testid="drawerCloseButton" />
          </DrawerHeader>
          <DrawerBody pr="10px" mt="1.25rem" data-testid="drawerBody">
            <NotificationView onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RightNotification;
