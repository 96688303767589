import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';

const TableViewWithSearch = ({
  placeholder,
  columns,
  tableData,
  onRowClick,
  searchKey,
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterData = debounce((e) => {
    const filteredFields = tableData.filter((field) =>
      field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  useEffect(() => {
    setFilteredData([...tableData]);
  }, [tableData]);
  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };
  return (
    <Box py="16px" data-testid="LinkedMetaFieldTableViewContainerBox">
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="LinkedMetaFieldTableViewContainerBoxFlex"
        alignItems="center"
      >
        <Text fontSize="16" fontWeight="600">
          Ticket Routing
        </Text>

        <Flex
          gap="15px"
          flexWrap="wrap"
          data-testid="LinkedMetaFieldTableViewContainerFlex"
          width="30%"
          justifyContent="end"
        >
          <InputGroup size="l">
            <CustomInput
              placeholder={placeholder}
              type="text"
              size="sm"
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
                filterData(e);
              }}
            />
            {searchInputValue ? (
              <InputRightElement
                style={{ right: 12, cursor: 'pointer' }}
                // eslint-disable-next-line react/no-children-prop
                children={<CloseIcon color="#94A3B8" />}
                size="l"
                onClick={handleClearInput}
                className="calculatedHeight"
              />
            ) : (
              <InputRightElement
                style={{ right: 12, cursor: 'pointer' }}
                // eslint-disable-next-line react/no-children-prop
                children={<SearchIcon color="#94A3B8" />}
                size="l"
                className="calculatedHeight"
              />
            )}
          </InputGroup>
        </Flex>
      </Box>

      <Box my="5px" data-testid="LinkedMetaFieldTableViewTableContainerBox">
        <CustomTable
          columns={columns}
          tableData={filteredData}
          onRowClick={onRowClick}
          title="Ticket Routing"
        />
      </Box>
    </Box>
  );
};

TableViewWithSearch.propTypes = {
  placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  searchKey: PropTypes.string,
};

TableViewWithSearch.defaultProps = {
  placeholder: 'Search',
  onRowClick: undefined,
  searchKey: 'label',
};

export default TableViewWithSearch;
