/* eslint-disable no-restricted-syntax */

export const addElementByKey = (key, state, newElement) => {
  state.forEach((parent) => {
    if (parent.key === key) {
      parent.children.push(newElement);
    } else if (parent.children && parent.children.length > 0) {
      addElementByKey(key, parent.children, newElement);
    }
  });
  return state;
};

export const editTitleByKey = (key, state, newTitle) => state.map((parent) => {
  if (parent.key === key) {
    return { ...parent, title: newTitle };
  } if (parent.children && parent.children.length > 0) {
    return { ...parent, children: editTitleByKey(key, parent.children, newTitle) };
  }
  return parent;
});

export const editNodeInfoByKey = ({ key, state, newImageInfo, description }) =>
  state.map((parent) => {
    if (parent.key === key) {
      return { ...parent, imageUrlInfo: newImageInfo, description };
    }
    if (parent.children && parent.children.length > 0) {
      return {
        ...parent,
        children: editNodeInfoByKey({
          key,
          state: parent.children,
          newImageInfo,
          description,
        }),
      };
    }
    return parent;
  });

export const editNumberValueByKey = (key, state, newValue) => state.map((node) => {
  if (node.key === key) {
    return { ...node, position: newValue };
  }
  if (node.children) {
    return {
      ...node,
      children: editNumberValueByKey(key, node.children, newValue),
    };
  }
  return node;
});

export const editImageInfoByKey = ({ key, state, newImageInfo }) => state.map((parent) => {
  if (parent.key === key) {
    return { ...parent, imageUrlInfo: newImageInfo };
  } if (parent.children && parent.children.length > 0) {
    return {
      ...parent,
      children: editImageInfoByKey({
        key,
        state: parent.children,
        newImageInfo,
      })
    };
  }
  return parent;
});

export const deleteElementByKey = (key, state) => state.filter((parent) => {
  if (parent.key === key) {
    return false; // Exclude the element with the matching key
  } if (parent.children && parent.children.length > 0) {
    // Recursively call deleteElementByKey for children
    parent.children = deleteElementByKey(key, parent.children);
  }
  return true; // Include all other elements
});

export const findElementAndPath = (data, key, path = []) => {
  for (const item of data) {
    const newPath = [...path, item.key];
    if (item.key === key) {
      return { element: item, path };
    }
    if (item.children && item.children.length > 0) {
      const result = findElementAndPath(item.children, key, newPath);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const addNewKey = (key, state, newElement) => {
  state = state.map((parent) => {
    if (parent.key === key) {
      parent = { ...parent, ...newElement };
    } else if (parent.children && parent.children.length > 0) {
      parent.children = addNewKey(key, parent.children, newElement);
    }
    return parent;
  });
  return state;
};

  export const updateChildUsers = (key, state, updatedUsersList) => {
    return state?.map(node => {
      if (node.key === key) {
        if (node?.children && node?.children?.length > 0) {
          node.children = node?.children?.map(child => {
            if (child.isActor) {
              const selectedUser = child?.title; // Current user
              const isUserValid = updatedUsersList?.some(user => user?.value === selectedUser);
  
              // Update the user to the first user in the updated queue if invalid
              if (!isUserValid && updatedUsersList?.length > 0) {
                child.title = updatedUsersList[0].label;
              }
            }
            if (child?.children && child?.children?.length > 0) {
              child.children = updateChildUsers(child?.key, child?.children, updatedUsersList);
            }
            return child;
          });
        }
        return { ...node };
      } else if (node?.children && node?.children?.length > 0) {
        return { ...node, children: updateChildUsers(key, node?.children, updatedUsersList) };
      }
      return node;
    });
  };
