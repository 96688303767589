import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@chakra-ui/react';

const CustomCheckBox = ({
  checkBoxText,
  size,
  colorScheme,
  onChange,
  isDisabled,
  dataTestId,
  isChecked,
  children,
  ...props
}) => (
  <Checkbox
    data-testid={dataTestId}
    size={size}
    isChecked={isChecked}
    isDisabled={isDisabled}
    colorScheme={colorScheme}
    onChange={onChange}
    sx={{
      '> span': {
        fontSize: '14px', 
      },
    }}
    {...props}
  >
   <Box fontSize="14px" color="#111827" fontWeight="normal" lineHeight="normal">{children || checkBoxText}</Box>
  </Checkbox>
);
CustomCheckBox.propTypes = {
  checkBoxText: PropTypes.string.isRequired,
  size: PropTypes.string,
  colorScheme: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

CustomCheckBox.defaultProps = {
  size: 'lg',
  colorScheme: `customBlue`,
  isDisabled: false,
  dataTestId: 'test_checkBox',
  children: null,
};

export default CustomCheckBox;
