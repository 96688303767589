import React from 'react';
import { Select } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const SelectComponent = ({ options, value, handleChange, ...rest }) => (
  <Select
    {...rest}
    value={value}
    onChange={(event) => {
      handleChange(event?.target?.value);
    }}
    className='calculatedHeight'
  >
    {options?.length > 0 &&
      options.map(({ value: currentValue, name }) =>
        currentValue === '' && name === '' ? (
          <option hidden value="" key={value}>
            {name}
          </option>
        ) : (
          <option key={Math.random()} value={currentValue}>
            {name}
          </option>
        )
      )}
  </Select>
);
SelectComponent.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default SelectComponent;
