import React from 'react';

import { EditIcon } from '@chakra-ui/icons';
import TableColumnText from '../../../components/Text/TableColumnText';

// eslint-disable-next-line import/prefer-default-export
export const columns = ({ showModal }) => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
  {
    name: 'Locales',
    col_name: 'locales',
    type: 'string',
    cellRenderer: ({ rowData }) => rowData?.locale_code,
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div
        className="flex justify-center items-center"
        style={{ gap: '0.8rem' }}
      >
        <EditIcon
          className="cursor-pointer !text-[#94A3B8]"
          onClick={() =>
            showModal({
              rowData,
            })
          }
        />
      </div>
    ),
  },
];
