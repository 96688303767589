import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Text,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import { debounce } from 'lodash';

const Offers = ({
  columns,
  tableData,
  onRowClick,
  sortableConfig,
  customSearchFn,
}) => {
  // State to hold filtered table data, initialized with original table data
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');
  // Ref to hold the current filter string, avoids re-renders on update
  const currentFilterString = useRef('');

  // Function to filter table data based on the search input, debounced for 500ms
  const filterData = useCallback(
    debounce((e) => {
      let filteredFields;
      if (typeof customSearchFn === 'function') {
        filteredFields = customSearchFn(tableData, e);
      } else {
        filteredFields = tableData.filter((field) =>
          field?.name?.toLowerCase()?.includes(e.toLowerCase())
        );
      }
      setFilteredData([...filteredFields]);
    }, 500), 
    [tableData, customSearchFn]
  );

  // Function to clear the search input and reset the filtered data
  const handleClearInput = useCallback(() => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  }, [tableData]);

  // Function to handle changes in the search input field
  const handleChangeInput = useCallback(
    (e) => {
      setSearchInputValue(e.target.value);
      currentFilterString.current = e.target.value;
      filterData(e.target.value);
    },
    [filterData]
  );

  useEffect(() => {
    filterData(currentFilterString.current);
  }, [filterData]);

  return (
    <>
      <style>
        {`
      .customHeight{
        padding-top:0px !important;
      }
      
      `}
      </style>
      <Box px="12px" data-testid="OffersTableViewContainerBox">
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid="OffersTableViewContainerBoxFlex"
        >
          <Text style={{ fontSize: '20px', fontWeight: '600' }}>Offers</Text>
          <Flex
            gap="15px"
            flexWrap="wrap"
            data-testid="OffersTableViewContainerFlex"
            alignItems="right"
          >
            <Box flex=".3" data-testid="FiltersFieldTableViewInnerBox">
              <InputGroup style={{ minWidth: '20rem' }} size="5">
                <CustomInput
                  placeholder="Search"
                  type="text"
                  size="lg"
                  value={searchInputValue}
                  onChange={handleChangeInput}
                />
                {searchInputValue ? (
                  <InputRightElement
                    style={{ right: 12, cursor: 'pointer' }}
                    children={<CloseIcon color="#94A3B8" />}
                    size="5"
                    onClick={handleClearInput}
                    className="calculatedHeight"
                  />
                ) : (
                  <InputRightElement
                    style={{ right: 12, cursor: 'pointer' }}
                    children={<SearchIcon color="#94A3B8" />}
                    size="5"
                    className="calculatedHeight"
                  />
                )}
              </InputGroup>
            </Box>
            {sortableConfig?.showReorder && (
              <CustomButton
                classname="calculatedHeight"
                isLoading={sortableConfig?.btnTitle?.isLoading}
                isDisabled={sortableConfig?.btnTitle?.isLoading}
                buttonText={
                  sortableConfig?.isSortable
                    ? sortableConfig?.btnTitle?.toggleOn
                    : sortableConfig?.btnTitle?.toggleOff
                }
                onClick={() => {
                  if (sortableConfig?.isSortable) {
                    // Reset the filteredData to the original tableData after save
                    setFilteredData([...tableData]);
                    setSearchInputValue('');
                    currentFilterString.current = '';
                    sortableConfig?.onOrderSave();
                    sortableConfig?.onToggleSortable(
                      !sortableConfig?.isSortable
                    );
                  } else {
                    sortableConfig?.onToggleSortable(
                      !sortableConfig?.isSortable
                    );
                  }
                }}
                variant="solid"
                style={{
                  backgroundColor: COLORS.LIGHT,
                  color: COLORS.WHITE,
                  borderColor: '#2563EB',
                }}
                color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              />
            )}
          </Flex>
        </Box>

        <Box my="16px" data-testid="OffersTableViewTableContainerBox">
          <CustomTable
            sortableConfig={sortableConfig}
            columns={columns}
            tableData={filteredData}
            onRowClick={onRowClick}
            title="Locales"
            height='calc(100vh - 14rem)'
          />
        </Box>
      </Box>
    </>
  );
};

Offers.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  customSearchFn: PropTypes.func,
  sortableConfig:  
  PropTypes.shape({
    isSortable: PropTypes.bool.isRequired,
    showReorder: PropTypes.bool.isRequired,
    onToggleSortable: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onOrderSave: PropTypes.func.isRequired,
    btnTitle:
      PropTypes.shape({
        toggleOff: PropTypes.string.isRequired,
        toggleOn: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
      }).isRequired
  })
};

Offers.defaultProps = {
  onRowClick: undefined,
  customSearchFn: undefined,
  sortableConfig:
  {
    isSortable: false,
    showReorder: false,
    onToggleSortable: () => {},
    onOrderChange: () => {},
    onOrderSave: () => {},
    btnTitle:
      {
        toggleOff: 'Reorder',
        toggleOn: 'Save Order',
        isLoading: false
      },
  },
};

export default Offers;
