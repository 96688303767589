/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable brace-style */
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import { v4 as uuidv4 } from 'uuid';

import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../../utils/axios/axios';
import {
  ACCESS_KEY_NAME,
  selectOptionsChannels,
  selectOptionsNoteTypes,
} from './CustomTemplateModalLayout';
import { getTenantData } from '../../../helpers/tenantUrl';
import { TemplateTypes } from '../../../utils/enums/types';
import { checkForV2 } from '../../../utils/helper';
import { checkIfUserCanEdit } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

export const createTemplate = async (data) => {
  try {
    const response = await axiosInstance.post(`templates/create`, data);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error?.response?.data?.message || 'Failed to create a template',
    };
  }
};

export const editTemplateApi = async (data, id) => {
  try {
    const response = await axiosInstance.patch(`templates/${id}`, data);
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const getAllTemplates = async () => {
  const response = await axiosInstance.get(
    `/templates/list?$top=100&$skip=0&$select=*`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getAllApprovedTickets = async () => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1000&$skip=0&$select=*&$filter=lifecycle_status eq 'APPROVED'`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1&$skip=0&$select=name,id&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getAllLayoutApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/templates/list?$top=100&$skip=0&$select=layouts,fields&$filter=id eq ${id}`
    );
    return {
      layouts: response?.data?.rows[0]?.layouts,
      fields: response?.data?.rows[0]?.fields,
    };
  } catch (err) {
    return err;
  }
};

export const getChildTemplatesWithoutParent = (templates) => {
  const childTemplatesWithoutParent = templates?.rows
    ?.filter((template) => template?.properties?.parent_id === null)
    .map((x) => ({ value: x.id, label: x.name }));
  return childTemplatesWithoutParent;
};

const getChildTemplatesList = (rowData) => {
  if (rowData?.template_type === TemplateTypes.SINGLE) {
    return [];
  }
  if (rowData?.template_type === TemplateTypes.NESTED) {
    if (!checkForV2()) {
      return rowData.child_templates_list;
    }
    return !_isEmpty(rowData.child_templates_list)
      ? _map(rowData.child_templates_list, (c) => c.value).filter((c) => c)
      : [];
  }
};

const getChildTemplates = (rowData) => {
  if (rowData?.template_type === TemplateTypes.SINGLE) {
    return [];
  }
  return rowData?.child_templates;
};

const getCheckboxValue = (rowData, key) => {
  if (rowData?.template_type === TemplateTypes.SINGLE) {
    return '';
  }
  if (key === ACCESS_KEY_NAME.DIFF_BPM_PROCESS4CHILDREN) {
    return true;
  }
  return rowData?.[key];
};

export const createPayload = (rowData) => {
  const payload = {
    name: rowData.name ? rowData.name.trim() : '',
    tenant_id: getTenantData()?.id,
    version: '1',
    properties: {
      runtime_version: checkForV2() ? '2' : '1',
      ...(rowData?.properties?.defaultTicket ? {  defaultTicket: rowData?.properties?.defaultTicket ?? {} } : {}),
      description: rowData.description,
      prefix: rowData.prefix ? rowData.prefix.trim() : '',
      running_number: rowData.running_number,
      start_at: rowData.start_at,
      suffix: rowData.suffix,
      child_templates_list: getChildTemplatesList(rowData),
      child_templates: getChildTemplates(rowData),
      template_type: rowData.template_type,
      owners: rowData.owners || localStorage.getItem('userEmail'),
      collaborators: rowData.collaborators,
      note_types: !_isEmpty(rowData.note_types)
        ? _map(rowData.note_types, (c) => c.value)
        : [],
      channels: !_isEmpty(rowData.channels)
        ? _map(rowData.channels, (c) => c.value)
        : [],
      permissions: {
        viewers: rowData.viewers || { org: [getTenantData()?.tenantId] },
        editors: rowData.editors || { org: [getTenantData()?.tenantId] },
        expressions: _map(rowData.expressions, (c) => {
          delete c.id;
          return c;
        }),
      },
      doAutoMove: getCheckboxValue(rowData, ACCESS_KEY_NAME.DO_AUTO_MOVE),
      diffBpmProcess4Children: getCheckboxValue(
        rowData,
        ACCESS_KEY_NAME.DIFF_BPM_PROCESS4CHILDREN
      ),
      standalone_template: rowData?.standalone_template,
    },
    fields: [
      {
        id: uuidv4(),
        type: 'TEXT',
        label: 'Subject',
        defaultFieldBot: 'BOT_SUBJECT',
        slug: 'subject',
        scope: 'TICKET',
        display: true,
        type_based_attributes: {
          required: true,
          is_searchable: true,
          is_reportable: true,
          dependency: { filterField: 'parentName' }
        },
        permissions: {
          viewers: rowData.viewers || { org: [getTenantData()?.tenantId] },
          editors: rowData.editors || { org: [getTenantData()?.tenantId] },
          expressions: [],
          restrictRead: {}
        },
        tenant_id: rowData.tenant_id || getTenantData()?.id,
      },
      {
        id: uuidv4(),
        type: 'RICH_TEXT',
        label: 'Summary',
        defaultFieldBot: 'BOT_SUMMARY',
        slug: 'summary',
        scope: 'TICKET',
        display: true,
        type_based_attributes: {
          required: true,
          is_searchable: true,
          include_in_report: true,
          dependency: { filterField: 'parentName' }
        },
        permissions: {
          viewers: rowData.viewers || { org: [getTenantData()?.tenantId] },
          editors: rowData.editors || { org: [getTenantData()?.tenantId] },
          expressions: [],
          restrictRead: {}
        },
        tenant_id: rowData.tenant_id || getTenantData()?.id,
      },
    ],
  };
  // payload.properties = _omitBy(payload.properties, (v, key) => key !== 'standalone_template' && !v);
  // payload.properties = _omitBy(payload.properties, (v) => !v);
  payload.permissions = _omitBy(payload.permissions, (v) => !v);

  // Added these two as the default values for these are taken as true
  payload.properties.doAutoMove = payload?.properties?.doAutoMove || false;
  payload.properties.diffBpmProcess4Children =
    payload?.properties?.diffBpmProcess4Children || false;
  payload.properties.standalone_template =
    payload?.properties?.standalone_template || false;  
  return payload;
};

export const transformAPIData = (APIResponse, callback) => {
  const renderableData = {
    ...APIResponse,
    template_name: APIResponse?.name,
    description: APIResponse?.properties?.description,
    prefix: APIResponse?.properties?.prefix,
    template_type: APIResponse?.properties?.template_type,
    running_number: APIResponse?.properties?.running_number,
    start_at: APIResponse?.properties?.start_at,
    suffix: APIResponse?.properties?.suffix,
    owners: APIResponse?.properties?.owners,
    wasApproved: APIResponse?.properties?.wasApproved,
    collaborators: APIResponse?.properties?.collaborators,
    viewers: APIResponse?.properties?.permissions?.viewers,
    editors: APIResponse?.properties?.permissions?.editors,
    expressions: APIResponse?.properties?.permissions?.expressions?.map(
      (expression) => ({
        ...expression,
        id: uuidv4(),
      })
    ),
    child_templates_list: APIResponse?.properties?.child_templates_list,
    child_templates: APIResponse?.properties?.child_templates,
    channels: !_isEmpty(APIResponse?.properties?.channels)
      ? _map(APIResponse?.properties?.channels, (c) => {
          const option = selectOptionsChannels.find(
            (channel) => channel.value === c
          );
          return option;
        })
      : undefined,
    note_types: !_isEmpty(APIResponse?.properties?.note_types)
      ? _map(APIResponse?.properties?.note_types, (c) => {
          const option = selectOptionsNoteTypes.find(
            (note) => note.value === c
          );
          return option;
        })
      : undefined,
    doAutoMove: APIResponse?.properties?.doAutoMove,
    diffBpmProcess4Children: APIResponse?.properties?.diffBpmProcess4Children,
    standalone_template: APIResponse?.properties?.standalone_template,
  };

  // Getting the properties only in v2 mode
  if (
    checkForV2() &&
    !_isEmpty(APIResponse?.properties?.child_templates_list)
  ) {
    const promiseList = _map(
      APIResponse?.properties?.child_templates_list,
      (c) => getTemplateById(c)
    );
    Promise.all(promiseList).then((responseList) => {
      if (!_isEmpty(responseList)) {
        renderableData.child_templates_list = responseList.map((r) => {
          if (r.count === 1) {
            return {
              value: r.rows[0].id,
              label: r.rows[0].name,
            };
          }
          return null;
        });
        if (typeof callback === 'function') callback(renderableData);
      }
    });
  } else if (typeof callback === 'function') callback(renderableData);
};

export const interactNotificationAPI = async (id) => {
  try {
    const response = await axiosInstance.post('notifications/interact', {
      data: { id },
    });
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const updateLifecycleStatus = async (data, id) => {
  const res = await checkIfUserCanEdit({ displayToastMessage: false, id });
  if (res?.error) {
    return { error: res.error };
  }

  try {
    const response = await axiosInstance.post(
      `templates/updateLifecycle?$filter=id eq ${id}`,
      data
    );
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return { error: message };
  }
};

export const getFieldDataFromAPI = async (url) => {
  try {
    const response = await axiosInstanceWithoutBaseUrl.get(
      `${url}`,
    );
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return { error: message };
  }
};
