import { isDarkThemeApplied } from '../../pages/TicketTemplateBuilder/utils/helper';
import customStyleFromRuleForSelect from './CustomStyleFromRuleForSelect';

const customSelectWithResponsiveHeight = {
  ...customStyleFromRuleForSelect,
  control: (provided) => ({
    ...provided,
    background: isDarkThemeApplied() ? '#4F5765' : '#fff',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    height:"10px",
    minHeight: '36px'
  }),
};
export default customSelectWithResponsiveHeight;
