// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TDcHf1EVTx9EtURpo5lT{display:inline;font-size:14px;line-height:18px;font-weight:500;width:172px}.gHbx91naF2DbDByfbn_x{font-weight:600 !important;font-size:16px !important;line-height:19px !important}.G0r7v7CuXBx321140YRA{font-size:14px;margin-top:40px}.hZn00WMSopFjcQZFL2Sd{display:flex;flex-direction:column}.fe0RxH0rVOTOO1NOTEOu{padding:5px;display:flex;flex-direction:row}.jz9mqTQ7puWdkrvixM0t{display:inline-flex;align-items:center;padding:0 10px}`, "",{"version":3,"sources":["webpack://./src/pages/TicketTemplateWorkflow/styles/RestrictMoveRole.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,WAAA,CAGF,sBACE,0BAAA,CACA,yBAAA,CACA,2BAAA,CAGF,sBACE,cAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,mBAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".roleTitleText {\n  display:inline;\n  font-size: 14px;\n  line-height: 18px;\n  font-weight: 500;\n  width: 172px;\n}\n\n.formLabelRestric {\n  font-weight: 600 !important;\n  font-size: 16px !important;\n  line-height: 19px !important;\n}\n\n.differentTransitionTitle {\n  font-size: 14px;\n  margin-top: 40px;\n}\n\n.radiobutton {\n  display: flex;\n  flex-direction: column;\n}\n\n.radioitem {\n  padding: 5px;\n  display: flex;\n  flex-direction: row;\n}\n\n.radiolabel {\n  display: inline-flex;\n  align-items: center;\n  padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roleTitleText": `TDcHf1EVTx9EtURpo5lT`,
	"formLabelRestric": `gHbx91naF2DbDByfbn_x`,
	"differentTransitionTitle": `G0r7v7CuXBx321140YRA`,
	"radiobutton": `hZn00WMSopFjcQZFL2Sd`,
	"radioitem": `fe0RxH0rVOTOO1NOTEOu`,
	"radiolabel": `jz9mqTQ7puWdkrvixM0t`
};
export default ___CSS_LOADER_EXPORT___;
