import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import CustomSelectBox from '../../../components/SelectBox/Select';
// import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import {
  makeApiCallForWorkflowList,
  reducerForState,
} from '../AddEditActorModal/helper';
// import { dummyDefaultRole } from '../../TicketTemplateWorkflow/DummyData';
// import _cloneDeep from 'lodash/cloneDeep';
import { patchDataForTemplate } from '../utils/templateAPIUtils';
import { updateSelectedWorkFlow } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { ConfirmModel } from './ConfirmModel';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { isDarkThemeApplied } from '../utils/helper';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '250px',
  }),
  control: (provided) => ({
    ...provided,
    width: '250px',
    overflow: 'hidden',
    height:'10px',
    minHeight:"32px",
    border: 'thin solid #D1D5DB'
  }),
   valueContainer: (provided) => ({
      ...provided,
      minHeight: '30px !important',
      height: 'auto', // Adjust height automatically based on content,
      padding: '0 6px',
      color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: isDarkThemeApplied()
        ? 'hsl(0, 0%, 20%)'
        : 'hsl(0, 0%, 90%)',
      color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
      margin: '0px',
      '::before': {
        position: 'relative',
      },
      '::after': {
        position: 'relative',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      paddingRight: '0',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingLeft: '0',
      marginLeft: '0',
      cursor: 'pointer',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'auto', // Adjust height automatically based on content
      minHeight: '30px !important',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: '#111827',               // color of the X icon
      ':hover': {
        color: '#111827',             // custom icon color on hover
        backgroundColor: 'none'
      },
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      padding: '0px',   
      paddingLeft: '0px',
      fontSize:'14px',
      color: '#2563EB',
      fontWeight: '400',
      lineHeight: 'normal'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#DEE8FC', // background of the badge
      color: '#2563EB',               // text color inside the badge
      borderRadius: '5px',
      padding: '4px 8px',
      gap: '4px'
    }),
  
};

const AssociateAnotherFlowBtn = ({ clearUndoRedoState, selectedTemplate }) => {
  const [workflowState, dispatchWorkflow] = useReducer(reducerForState, {});
  const [workflowMasterListOptions, setWorkflowMasterListOptions] = useState(
    []
  );
  const [selectedWokFlow, setSelectedWorkFlow] = useState({});
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchWorkflowListOption = async () => {
    setIsLoading(true)
    const response = await makeApiCallForWorkflowList(selectedTemplate.id);
    const options = response?.map((item) => ({
      value: item.id,
      label: item.name,
      cloned_from: item.cloned_from,
      template_id: item.template_id,
      workflow_status: item.workflow_status,
      workflow_category: item.workflow_category,
      workflow_transitions: item.workflow_transitions,
    }));
    setWorkflowMasterListOptions(options);
    setIsLoading(false)
  };
  useEffect(() => {
    fetchWorkflowListOption();
  }, [selectedTemplate]);

  const configWorkflowInTemplate = async (newObj) => {
    const workflowId = workflowState.id;
    delete workflowState.id;
    const newData = {
      ...newObj,
      // ...workflowState,
      workflow_rules: { list: [] },
      updated_on: new Date().toISOString(),
    };
    const res = await patchDataForTemplate({
      data: newData,
      id,
      key: 'workflow',
      successMessage: 'Workflow Configuration Successful',
      defaultErrorMessage: 'Failed to configure workflow',
    });
    if(res.error) return;
    newData.id = workflowId;
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: res?.response?.data,
      })
    );
    dispatch(updateSelectedWorkFlow(res?.response?.data?.workflow));
    dispatch(setActiveModal({ targetModalId: undefined }));
  };

  const handleWorkFlowSelect = async (e) => {
    const newObj = {
      id: e.value,
      name: e.label,
      cloned_from: e.cloned_from ? e.cloned_from : e.value,
      workflow_status: e.workflow_status,
      workflow_category: e.workflow_category,
      workflow_transitions: e.workflow_transitions,
    };
    clearUndoRedoState();
    dispatchWorkflow({
      type: 'SET_STATE',
      value: { ...newObj },
    });
    await configWorkflowInTemplate(newObj);
    //fetch dropdown list after changing work flow
    fetchWorkflowListOption();
  };

  const onConfirmOverWrite = (isOpen) => {
    handleWorkFlowSelect(selectedWokFlow);
    setOpenAlertModal(isOpen);
  };

  const onCancelOverWriteWorkflow =(isOpen)=>{
    setOpenAlertModal(isOpen)
    setSelectedWorkFlow({})
  }

  return (
    <>
      <CustomSelectBox
        styles={customStyles}
        options={workflowMasterListOptions ?? []}
        value={getWholeOption(workflowState?.id, workflowMasterListOptions)}
        isLoading={isLoading}
        onChange={(e) => {
          // if selected workflow already linked to template show alert for overwriting
          const clonedWorkflow = workflowMasterListOptions.find(
            (item) => item.cloned_from == e.value && item.template_id
          )
          if (
            clonedWorkflow
          ) {
            const event  = _cloneDeep(e)
            event.value = clonedWorkflow.value;
            event.label = `${clonedWorkflow.label}`
            setSelectedWorkFlow(event);
            setOpenAlertModal(true);
            return;
          }
          handleWorkFlowSelect(e);
        }}
       placeholder="Associate another flow"
        id="WorkFlowConfigSelectBox2"
      />
      <ConfirmModel
        openAlertModal={openAlertModal}
        onCancelOverWriteWorkflow = {onCancelOverWriteWorkflow}
        onConfirmOverWrite={onConfirmOverWrite}
      />
    </>
  );
};
export default AssociateAnotherFlowBtn;
