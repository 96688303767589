/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Container,
  Heading,
  Text,
  Button,
  Box
} from '@chakra-ui/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CustomToast from '../../components/Notification/Notification';
import DeleteIcon from '../../assets/icons/DeleteIcon.svg';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import {
  checkIfUserCanEdit,
  patchDataForTemplateWithPath,
} from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { QUICK_ACTION_DATA_KEYS } from '../QuickAction/quickAction.constants';
import { SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const TEMPLATE_DELETE_PATHS = {
  COMMUNICATION_TEMPLATES: 'communication_templates',
  EVENTS: 'events.events',
  ACTIONS: 'events.actions',
};
const DeleteModal = ({
  title,
  isOpen,
  setIsOpen,
  id,
  size,
  onModalClose,
  onDelete,
  fieldData,
  labelKey,
  masterType,
  targetUrl,
  listKey,
  patchFilterId,
  method,
  linkedType,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = CustomToast();
  const viewState = useSelector((state) => state.viewState);
  const dispatch = useDispatch();

  const onClose = () => {
    setIsOpen(false);
    onModalClose();
  };

  const makeApiCallForDelete = async (url) => {
    const updatedStr = url.replace('${id}', id);
    try {
      const res = await axiosInstance.delete(updatedStr);
      return {
        response: res?.data,
        error: null,
      };
    } catch (err) {
      return {
        response: null,
        error: err?.response?.data?.message || 'Failed to delete',
      };
    }
  };

  const checkIfFieldsAlreadyExists = () => {
    let templateValue = _.cloneDeep(viewState?.selectedTemplate);
    delete templateValue.fields;
    templateValue = JSON.stringify(templateValue);
    return templateValue?.includes(id);
  };

  const makeApiCallForPatchDelete = async (url, value) => {
    const requestBody = {
      data: {
        [masterType]: value,
      },
    };
    const updatedStr = url.replace('${id}', patchFilterId);

    if (masterType === 'fields') {
      const isExists = checkIfFieldsAlreadyExists();
      if (isExists) {
        addToast({
          type: 'error',
          title: 'Failed to delete',
          message: 'Field is already linked to some parts of Template',
        });
        return;
      }
    }

    // Check if the delete button is for the templates
    if (
      updatedStr.includes(`templates/update?$filter=id eq ${patchFilterId}`)
    ) {
      const res = await checkIfUserCanEdit({
        displayToastMessage: true,
        id: patchFilterId,
      });
      if (res?.error) {
        return;
      }
    }

    try {
      const res = await axiosInstance.patch(updatedStr, requestBody);
      return {
        response: res?.data,
        error: null,
      };
    } catch (err) {
      return {
        error: err?.response?.data?.message || 'Failed to delete',
        response: null,
      };
    }
  };

  const handleOnDelete = async () => {
    setIsLoading(true);
    if (
      masterType === 'events' &&
      method === 'patch' &&
      linkedType === 'actions'
    ) {
      const res = await patchDataForTemplateWithPath({
        id: patchFilterId,
        data: {
          path: TEMPLATE_DELETE_PATHS.ACTIONS,
          operation: 'delete',
          id,
        },
        displayToastMessage: false,
      });
      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else if (masterType === 'events' && method === 'patch') {
      const res = await patchDataForTemplateWithPath({
        id: patchFilterId,
        data: {
          path: TEMPLATE_DELETE_PATHS.EVENTS,
          operation: 'delete',
          id,
        },
        displayToastMessage: false,
      });
      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else if (masterType === 'communication_templates' && method === 'patch') {
      const res = await patchDataForTemplateWithPath({
        id: patchFilterId,
        data: {
          path: TEMPLATE_DELETE_PATHS.COMMUNICATION_TEMPLATES,
          operation: 'delete',
          id,
        },
        displayToastMessage: false,
      });
      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else if (masterType === 'fields' && method === 'patch') {
      const updatedList = _.cloneDeep(viewState[listKey]);
      const editIndex = _.findIndex(updatedList.data, {
        id,
      });
      if (editIndex !== undefined && editIndex !== -1) {
        updatedList.data.splice(editIndex, 1);
      }

      const res = await patchDataForTemplateWithPath({
        id: patchFilterId,
        data: {
          path: 'fields',
          operation: 'delete',
          id,
        },
        displayToastMessage: false,
      });
      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: listKey,
            value: updatedList,
          })
        );
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else if (
      masterType === QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS &&
      method === 'patch'
    ) {
      const updatedList = [...(viewState?.[listKey]?.[masterType] || [])];
      const rowIndex = updatedList.findIndex((e) => e.id === id);
      if (rowIndex !== undefined && rowIndex !== -1) {
        updatedList.splice(rowIndex, 1);
      }

      const res = await makeApiCallForPatchDelete(targetUrl, updatedList);
      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else if (method === 'patch') {
      const updatedList = _.cloneDeep(viewState[listKey]);
      const editIndex = _.findIndex(updatedList.data, {
        id,
      });
      if (editIndex !== undefined && editIndex !== -1) {
        updatedList.data.splice(editIndex, 1);
      }
      const res = await makeApiCallForPatchDelete(targetUrl, updatedList.data);

      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else if (res?.response) {
        dispatch(
          updateViewState({
            stateKey: listKey,
            value: updatedList,
          })
        );
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    } else {
      const res = await makeApiCallForDelete(targetUrl);

      if (res?.error) {
        addToast({
          type: 'error',
          title: 'Something went wrong',
          message: res.error,
        });
      } else {
        const COUNT_NO = viewState[listKey]?.count || 0;
        const updatedList = _.cloneDeep(viewState[listKey]);
        const editIndex = _.findIndex(updatedList.data, {
          id,
        });
        if (COUNT_NO > 0) {
          updatedList.count -= 1;
        }
        if (editIndex !== undefined && editIndex !== -1) {
          updatedList.data.splice(editIndex, 1);
        }
        dispatch(
          updateViewState({
            stateKey: listKey,
            value: updatedList,
          })
        );
        addToast({
          type: 'success',
          title: 'Success',
          message: 'Sucessfully deleted',
        });
      }
    }
    setIsLoading(false);
    setIsOpen(false);
    onModalClose();
  };
  const showCorrectMasterType = (str) => {
    if (str === 'actors') {
      return 'actor';
    }
    if (str === 'communication_templates') return 'communication template';
    return str;
  };
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      data-test-id={id}
      scrollBehavior="inside"
      {...props}
    >
      <ModalOverlay />
      <ModalContent maxWidth={size ?? '512px'} data-testid="DeleteModalContent">
        {/* <ModalHeader>{title}</ModalHeader> */}
        {/* <ModalCloseButton /> */}
        <ModalBody data-testid="DeleteModalBody" margin="0px" padding="24px">
           <Box width="400px"
             // marginTop="40px"
             data-testid="DeleteModalContainer"
            >
            <Box display="flex" alignItems="center" gap="10px">
                {/* <img src={DeleteIcon} alt="" /> */}
               <Box padding="7px" borderRadius="100px" backgroundColor="#FEE2E2"><ExclamationTriangleIcon height="24px" width="24px" color="#DC2626"/></Box>
                <Text fontSize="18px" color="#111827" fontWeight="500" lineHeight="normal">Delete</Text>
                </Box>
                
                
                <Text fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal" data-testid="DeleteModalHeading" paddingLeft="47px">
                 <span style={{fontSize:"14px",color:'#6B7280', fontWeight:"400"}}>Are you sure you want to delete this </span> {`${
                    showCorrectMasterType(linkedType) ||
                    showCorrectMasterType(masterType) ||
                    ''
                  }?`}
                  </Text>
            <Text
              paddingLeft="47px"
              fontSize="15px"
              color="#111827"
              marginTop="15px"
              data-testid="DeleteModalText"
            >
              {fieldData ? fieldData[labelKey] : ''}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter
          boxShadow="none"
          justifyContent="end"
          data-testid="DeleteModalFooter"
          margin="0px"
          padding="0px 24px 24px 0px"
        >
           <Box display="flex" alignItems="center" gap="10px">
           <SecondaryCustomButton
            onClick={onClose}
            data-testid="DeleteModalCancelButton"
            buttonText='Cancel'
          />         
          <Button
            style={{fontSize:"14px", fontWeight:"500", lineHeight:'normal', padding:"9px 17px", borderRadius:"5px"}}
            className="calculatedHeight"
            colorScheme="red"
            onClick={handleOnDelete}
            isLoading={isLoading}
            data-testid="DeleteModalDeleteButton"
          >
            Delete
          </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  viewLayout: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteModal;
