import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel } from '@chakra-ui/react';
import { isArray, cloneDeep } from 'lodash';
import { ReactSVG } from 'react-svg';
import styles from './mailServer.module.scss';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { MAIL_CONFIG } from '../../utils/enums/labels';
import { MAIL_FIELD_TYPES, getFieldType } from './utils';
import SelectFieldFromTemplateWithDelete from '../../components/selectFieldFromTemplateWithDelete';
import CustomCheckBox from '../../components/CheckBox/CheckBox';
import wraningInfo from '../../assets/icons/wraningInfo.svg';
import he from 'he';

const createImportanceMappingOptions = (
  value,
  selectedTemplate,
  setHighFieldOptions,
  setMediumFieldOptions,
  setLowFieldOptions
) => {
  if (!value.emailMapping) {
    setHighFieldOptions([]);
    setMediumFieldOptions([]);
    setLowFieldOptions([]);
  }

  const importanceMapping = value.emailMapping?.find(
    (mapObj) => mapObj.emailKey === 'importance'
  );

  if (!importanceMapping) {
    setHighFieldOptions([]);
    setMediumFieldOptions([]);
    setLowFieldOptions([]);
  }

  const importanceFieldId = importanceMapping?.fieldId;

  const importanceField = selectedTemplate?.fields.find(
    (field) => field.id === importanceFieldId
  );

  if (!importanceField) {
    setHighFieldOptions([]);
    setMediumFieldOptions([]);
    setLowFieldOptions([]);
    return;
  }

  const highValue = importanceMapping?.mapping?.high;
  const mediumValue = importanceMapping?.mapping?.medium;
  const lowValue = importanceMapping?.mapping?.low;

  let staticValues =
  importanceField.type_based_attributes?.collection?.staticValues.map((item) => ({
    label: he.decode(item.label),
    value: he.decode(item.value)
  })) || [];

  if (!isArray(staticValues)) {
    staticValues = staticValues.split('\n').map((staticValueString) => ({
      label: he.decode(staticValueString).trim(),
      value: he.decode(staticValueString).trim(),
    }));
  }

  setHighFieldOptions(
    staticValues.filter(
      (option) => option.value !== mediumValue && option.value !== lowValue
    )
  );
  setMediumFieldOptions(
    staticValues.filter(
      (options) => options.value !== highValue && options.value !== lowValue
    )
  );
  setLowFieldOptions(
    staticValues.filter(
      (options) => options.value !== highValue && options.value !== mediumValue
    )
  );
};

const FieldMappingSection = ({
  value,
  onChange,
  selectedTemplate,
  teamQueueMap,
  hierarchies,
  fieldHierarchyMap,
  errorListWithKey,
  setErrorListWithKey,
}) => {
  const [attachmentFieldOptions, setAttachmentFieldOptions] = useState([]);
  const [subjectFieldOptions, setSubjectFieldOptions] = useState([]);
  const [bodyFieldOptions, setBodyFieldOptions] = useState([]);
  const [senderFieldOptions, setSenderFieldOptions] = useState([]);
  const [importanceFieldOptions, setImportanceFieldOptions] = useState([]);
  const [highFieldOptions, setHighFieldOptions] = useState([]);
  const [mediumFieldOptions, setMediumFieldOptions] = useState([]);
  const [lowFieldOptions, setLowFieldOptions] = useState([]);
  const [isShowEmailAsAttachment, setIsShowEmailAsAttachment] = useState(
    !!value?.emailAttachmentFieldId
  );
  const [emailAsAttachmentOptions, setEmailAsAttachmentOptions] = useState([]);

  const getSelectedValue = (emailKey) => {
    const options = {
      attachment: attachmentFieldOptions,
      subject: subjectFieldOptions,
      body: bodyFieldOptions,
      importance: importanceFieldOptions,
      emailAttachmentFieldId: emailAsAttachmentOptions,
      sender: senderFieldOptions,
    };

    if (emailKey === 'attachment') {
      return getWholeOption(value.attachmentFieldId, options[emailKey]);
    }
    if (emailKey === 'emailAttachmentFieldId') {
      return getWholeOption(value.emailAttachmentFieldId, options[emailKey]);
    }
    if (!value.emailMapping) {
      return null;
    }
    const selectedValue = value.emailMapping.find(
      (mapObj) => mapObj.emailKey === emailKey
    )?.fieldId;

    return getWholeOption(selectedValue, options[emailKey]);
  };

  const setSelectedValue = (emailKey) => (selectedValue) => {
    setErrorListWithKey((pre) => ({ ...pre, [emailKey]: '' }));
    const valueCopy = cloneDeep(value);
    if (emailKey === 'attachment') {
      valueCopy.attachmentFieldId = selectedValue.value;
    } else if (emailKey === 'emailAttachmentFieldId') {
      valueCopy.emailAttachmentFieldId = selectedValue.value;
    } else {
      if (!valueCopy.emailMapping) {
        valueCopy.emailMapping = [];
      }

      const index = valueCopy.emailMapping.findIndex(
        (mapObj) => mapObj.emailKey === emailKey
      );

      const fieldMapping = {
        emailKey,
        fieldId: selectedValue.value,
      };
      if (
        emailKey === 'body' &&
        selectedValue.value === '00000000-0000-0000-0000-000000000000'
      ) {
        fieldMapping.noteType = 'public';
      }

      if (emailKey === 'importance') {
        fieldMapping.mapping = {
          high: null,
          medium: null,
          low: null,
        };
      }

      if (index === -1) {
        valueCopy.emailMapping.push(fieldMapping);
      } else {
        valueCopy.emailMapping[index] = fieldMapping;
      }
    }

    onChange(valueCopy);
  };

  const getImportanceValue = (importanceKey) => {
    if (!value.emailMapping) {
      return null;
    }

    const options = {
      high: highFieldOptions,
      medium: mediumFieldOptions,
      low: lowFieldOptions,
    };

    const importanceMapping = value.emailMapping.find(
      (mapObj) => mapObj.emailKey === 'importance'
    );

    if (!importanceMapping) {
      return null;
    }

    return getWholeOption(
      importanceMapping.mapping[importanceKey],
      options[importanceKey]
    );
  };

  const setImportanceValue = (importanceKey) => (selectedValue) => {
    setErrorListWithKey((pre) => ({ ...pre, [importanceKey]: '' }));
    const valueCopy = cloneDeep(value);
    if (!valueCopy.emailMapping) {
      return null;
    }

    const importanceMappingIndex = valueCopy.emailMapping.findIndex(
      (mapObj) => mapObj.emailKey === 'importance'
    );

    const importanceMapping = valueCopy.emailMapping[importanceMappingIndex];

    if (!importanceMapping) {
      return null;
    }

    if (!valueCopy.emailMapping[importanceMappingIndex].mapping) {
      valueCopy.emailMapping[importanceMappingIndex].mapping = {
        high: null,
        medium: null,
        low: null,
      };
    }

    valueCopy.emailMapping[importanceMappingIndex].mapping[importanceKey] =
      selectedValue.value;
    onChange(valueCopy);

    return null;
  };

  const filterOutUsedFieldsForOptionalMappings = () => {
    const fields = selectedTemplate?.fields || [];

    const mailMappedFields = (value?.emailMapping || []).map(
      (mapObj) => mapObj.fieldId
    );
    const optionalMappedFields = (value?.optionalMappings || []).map(
      (mapObj) => mapObj.field?.value
    );

    return fields.filter(
      (field) =>
        // if field is already mapped in emailMapping, then no need of this field
        // However, if it is in optionalMappings, then it is required.
        // In all other cases, the field is needed
        !mailMappedFields.includes(field.id) ||
        optionalMappedFields.includes(field.id)
    );
  };

  const filterOutUsedFieldsForEmailMappings = () => {
    const fields = selectedTemplate?.fields || [];

    const mailMappedFields = (value?.emailMapping || []).map(
      (mapObj) => mapObj.fieldId
    );
    const optionalMappedFields = (value?.optionalMappings || []).map(
      (mapObj) => mapObj.field?.value
    );

    return fields.filter(
      (field) =>
        // if field is already mapped in emailMapping, then no need of this field
        // However, if it is in optionalMappings, then it is required.
        // In all other cases, the field is needed
        !optionalMappedFields.includes(field.id) ||
        mailMappedFields.includes(field.id)
    );
  };

  useEffect(() => {
    const fields = filterOutUsedFieldsForEmailMappings();

    const mediaFieldOptions = []; // Media fields - Attachments
    const dropdownFieldOptions = []; // Static dropdown fields - Importance
    const textFieldOptions = []; // Text and Text Area - Subject
    const stringFieldOptions = []; // Text, Text Area and Rich Text - Body
    const mediaAllOptions = [];
    const nonActorsQueueOptions = [];

    fields.forEach((field) => {
      const fieldType = getFieldType(field);

      if (fieldType === MAIL_FIELD_TYPES.MEDIA) {
        mediaFieldOptions.push({ label: field.label, value: field.id });
        const requiredElements = [
          'images',
          'videos',
          'documents',
          'compressed_files',
        ];
        if (
          requiredElements.every((element) =>
            field.type_based_attributes.media_type.includes(element)
          ) ||
          field.type_based_attributes.media_type.includes('documents')
        ) {
          mediaAllOptions.push({ label: field.label, value: field.id });
        }
      }

      if (fieldType === MAIL_FIELD_TYPES.DROPDOWN) {
        dropdownFieldOptions.push({ label: field.label, value: field.id });
      }

      if (fieldType === MAIL_FIELD_TYPES.STRING) {
        stringFieldOptions.push({ label: field.label, value: field.id });
      }

      if (fieldType === MAIL_FIELD_TYPES.TEXT) {
        textFieldOptions.push({ label: field.label, value: field.id });
        stringFieldOptions.push({ label: field.label, value: field.id });
      }
    });
    stringFieldOptions.push({
      value: '00000000-0000-0000-0000-000000000000',
      label: 'Note',
    });
    const bodyValue = getSelectedValue('body');
    const subjectValue = getSelectedValue('subject');
    const senderValue = getSelectedValue('sender');
    const actor = selectedTemplate?.actors || [];
    if (actor?.length > 0) {
      actor.every((act) => {
        if (!act.log_field_id) {
          nonActorsQueueOptions.push({ label: act.name, value: act.associated_field_id });
        }
        return true;
      });
    }

    setAttachmentFieldOptions(mediaFieldOptions);
    setEmailAsAttachmentOptions(mediaAllOptions);
    setSubjectFieldOptions(
      textFieldOptions.filter((option) => option.value !== bodyValue?.value)
    );
    setBodyFieldOptions(
      stringFieldOptions.filter(
        (option) => option.value !== subjectValue?.value
      )
    );
    setSenderFieldOptions(nonActorsQueueOptions);
    setImportanceFieldOptions(dropdownFieldOptions);

    createImportanceMappingOptions(
      value,
      selectedTemplate,
      setHighFieldOptions,
      setMediumFieldOptions,
      setLowFieldOptions
    );
  }, [value.emailMapping, selectedTemplate]);

  const setOptionalMappings = (optionalMappings) => {
    const valueCopy = cloneDeep(value);
    valueCopy.optionalMappings = optionalMappings;
    onChange(valueCopy);
  };

  return (
    <Box w="750px" marginTop="24px">
      <FormLabel className="SCLabel">
        {MAIL_CONFIG.FIELD_MAPPING_LABEL}
      </FormLabel>
      <div className={styles.fieldMapContainer}>
        <div className={styles.mappingRow}>
          <FormLabel className={styles.mappingLabel}>Attachment: </FormLabel>
          <CustomSelectBox
            options={attachmentFieldOptions}
            onChange={setSelectedValue('attachment')}
            value={getSelectedValue('attachment')}
            isError={errorListWithKey?.attachment || false}
            errorMessage={errorListWithKey?.attachment || ''}
          />
        </div>
        <div className={styles.mappingRow}>
          <FormLabel className={styles.mappingLabel}>Subject: </FormLabel>
          <CustomSelectBox
            options={subjectFieldOptions}
            onChange={setSelectedValue('subject')}
            value={getSelectedValue('subject')}
            isError={errorListWithKey?.subject || false}
            errorMessage={errorListWithKey?.subject || ''}
          />
        </div>
        <div className={styles.mappingRow}>
          <FormLabel className={styles.mappingLabel}>Body: </FormLabel>
          <CustomSelectBox
            options={bodyFieldOptions}
            onChange={setSelectedValue('body')}
            value={getSelectedValue('body')}
            isError={errorListWithKey?.body || false}
            errorMessage={errorListWithKey?.body || ''}
          />
        </div>
        <div className={styles.mappingRow}>
          <FormLabel className={styles.mappingLabel}>Sender: </FormLabel>
          <CustomSelectBox
            options={senderFieldOptions}
            onChange={setSelectedValue('sender')}
            value={getSelectedValue('sender')}
            isError={errorListWithKey?.sender || false}
            errorMessage={errorListWithKey?.sender || ''}
          />
        </div>

        <div className={styles.mappingRow}>
          <FormLabel className={styles.mappingLabel}>Importance: </FormLabel>
          <CustomSelectBox
            options={importanceFieldOptions}
            onChange={setSelectedValue('importance')}
            value={getSelectedValue('importance')}
            isError={errorListWithKey?.importance || false}
            errorMessage={errorListWithKey?.importance || ''}
          />
        </div>
        {!!getSelectedValue('importance') && (
          <div className={styles.importanceSection}>
            <div className={styles.mappingRow}>
              <FormLabel className={styles.mappingLabel}>High: </FormLabel>
              <CustomSelectBox
                options={highFieldOptions}
                onChange={setImportanceValue('high')}
                value={getImportanceValue('high')}
                isError={errorListWithKey?.high || false}
                errorMessage={errorListWithKey?.high || ''}
              />
            </div>
            <div className={styles.mappingRow}>
              <FormLabel className={styles.mappingLabel}>Medium: </FormLabel>
              <CustomSelectBox
                options={mediumFieldOptions}
                onChange={setImportanceValue('medium')}
                value={getImportanceValue('medium')}
                isError={errorListWithKey?.medium || false}
                errorMessage={errorListWithKey?.medium || ''}
              />
            </div>
            <div className={styles.mappingRow}>
              <FormLabel className={styles.mappingLabel}>Low: </FormLabel>
              <CustomSelectBox
                options={lowFieldOptions}
                onChange={setImportanceValue('low')}
                value={getImportanceValue('low')}
                isError={errorListWithKey?.low || false}
                errorMessage={errorListWithKey?.low || ''}
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          alignItems: 'end',
          display: 'flex',
        }}
      >
        <FormLabel mt="24px" className="SCLabel">
          Add email as attachment :
        </FormLabel>
        <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
          <CustomCheckBox
            isChecked={isShowEmailAsAttachment}
            onChange={(e) => {
              setErrorListWithKey((pre) => ({
                ...pre,
                emailAttachmentFieldId: '',
              }));
              setIsShowEmailAsAttachment(e.target.checked);
              if (e.target.checked === false) {
                const valueCopy = cloneDeep(value);
                onChange({
                  ...valueCopy,
                  emailAttachmentFieldId: '',
                  isEmailAttachmentShow: false,
                });
              } else {
                onChange({
                  ...value,
                  isEmailAttachmentShow: true,
                });
              }
            }}
            checkBoxText=""
            isDisabled={false}
            style={{
              marginBottom: isShowEmailAsAttachment === false ? '6px' : '0',
            }}
          />
          {isShowEmailAsAttachment && (
            <CustomSelectBox
              options={emailAsAttachmentOptions}
              onChange={setSelectedValue('emailAttachmentFieldId')}
              value={getSelectedValue('emailAttachmentFieldId')}
              // isError={errorListWithKey?.emailAttachmentFieldId || false}
              // errorMessage={errorListWithKey?.emailAttachmentFieldId || ''}
            />
          )}
        </div>
      </div>
      {isShowEmailAsAttachment && emailAsAttachmentOptions.length === 0 && (
        <div className="flex space-x-2 ml-36 mt-1">
          <ReactSVG src={wraningInfo} />
          <p className="text-orange-400 text-sm">
            {' '}
            No Attachment field support html is present
          </p>
        </div>
      )}
      {errorListWithKey?.emailAttachmentFieldId && (
        <FormLabel className="SCLabel" color="red.500" mb="16px">
          {errorListWithKey?.emailAttachmentFieldId}
        </FormLabel>
      )}
      <FormLabel mt="24px" className="SCLabel">
        {MAIL_CONFIG.OPTIONAL_MAPPING_LABEL}
      </FormLabel>
      <SelectFieldFromTemplateWithDelete
        fieldList={filterOutUsedFieldsForOptionalMappings()}
        teamQueueMap={teamQueueMap}
        value={value?.optionalMappings || []}
        workFlowStatusList={
          selectedTemplate?.workflow?.workflow_status
            ?.filter((item) => item.id !== 'start_1')
            .map((ele) => ({ value: ele.id, label: ele.data.label })) || []
        }
        onChange={setOptionalMappings}
        isRequired
        preventFieldRepeat
        hierarchies={hierarchies}
        fieldHierarchyMap={fieldHierarchyMap}
        actorAssociationMap={selectedTemplate?.actors || []}
      />
    </Box>
  );
};

FieldMappingSection.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  teamQueueMap: PropTypes.array.isRequired,
  hierarchies: PropTypes.array.isRequired,
  fieldHierarchyMap: PropTypes.object.isRequired,
  errorListWithKey: PropTypes.object.isRequired,
  setErrorListWithKey: PropTypes.func.isRequired,
};

export default FieldMappingSection;
