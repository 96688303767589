/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Wrap,
  WrapItem,
  Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import CustomInput from '../InputBox/Input';
import { LIGHT } from '../../utils/enums/colors';

const SearchListWithButtonTags = ({
  data,
  placeholder,
  label,
  onChange,
  valueData,
  onSelectFirstValue,
  ...props
}) => {
  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setQuery(searchTerm);

    const filteredResults = filter(data, (item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredResults);
  };

  const handleClearInput = () => {
    setQuery('');
    setFilteredData([...data]);
  };

  return (
    <div style={{ marginRight: '15px'}}>
      <div style={label ? { marginBottom: '.5rem' } : {}}>
        <label className="SCLabel">{label}</label>
        {props?.isMandatory && (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        )}
      </div>
      <InputGroup size="l" data-testid="TbleViewWithSearchInputGrp" mb="30px">
        <CustomInput
          placeholder={placeholder}
          type="text"
          size="md"
          value={query}
          onChange={handleSearch}
          id="TableWithSearchCInput"
        />
        {query && (
          <InputRightElement
            style={{ right: 12, top: 8, cursor: 'pointer' }}
            // eslint-disable-next-line react/no-children-prop
            children={<CloseIcon color="#94A3B8" />}
            size="l"
            onClick={handleClearInput}
          />
        )}
      </InputGroup>
      {filteredData.length > 0 && (
        <Wrap
          spacing="15px"
          mt="22px"
          data-testid="DynamicMutliSelectWithTagsContainerWrap"
        >
          <Wrap
            spacing={2}
            pl="10px"
            pt="3px"
            pb="3px"
            pr="10px"
            maxW="380px"
            border={`1px solid ${LIGHT}`}
            borderRadius="4px"
            key={uuidv4()}
            data-testid="DynamicMutliSelectWithTagsWrap"
          >
            <WrapItem data-testid="DynamicMutliSelectWithTagsWrapItem">
              <Wrap
                data-testid="DynamicMutliSelectWithTagsInnerWrap"
                direction="column"
              >
                {filteredData.map((value) => (
                  <Flex
                    // key={value}
                    key={uuidv4()}
                    justifyContent="center"
                    alignItems="center"
                    bg={valueData === value?.label ? '#EBEBEB' : null}
                    borderRadius="4px"
                    fontWeight={500}
                    height="28px"
                    maxW="350px"
                    data-testid="DynamicMutliSelectWithTagsInnerMapFlex"
                  >
                    <Button
                      data-testid="DynamicMutliSelectWithTagsLabelBox"
                      onClick={() => {
                        onSelectFirstValue(value);
                      }}
                      variant="unstyled"
                      alignSelf="center"
                    >
                      {value?.label}
                    </Button>
                  </Flex>
                ))}
              </Wrap>
            </WrapItem>
          </Wrap>
        </Wrap>
      )}
    </div>
  );
};

SearchListWithButtonTags.propTypes = {
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  valueData: PropTypes.string.isRequired,
  onSelectFirstValue: PropTypes.func.isRequired,
};

SearchListWithButtonTags.defaultProps = {
  placeholder: 'Search',
  label: '',
};

export default SearchListWithButtonTags;
