import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Tag, TagLabel, TagCloseButton, useColorMode } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';

import CheckboxTree from './CheckboxTree';

import {
  checkboxStatus,
  getIdNameMap,
  getCheckedNodes,
  rootTraversal,
  uncheckAll,
} from './utils';

import styles from './IndeterminateCheckbox.module.scss';

const CustomInderminateCheckBox = ({
  data,
  onChange,
  value,
  label,
  errorMessage,
  isMandatory,
  parentSelectsChild,
  isMulti,
}) => {
  const { colorMode } = useColorMode();

  const [items, setItems] = useState(cloneDeep(data));
  const [values, setValues] = useState(Array.isArray(value) ? value : []);
  const [showTree, setShowTree] = useState(false);
  const [isChangemade, setIsChangemade] = useState(false);

  // This is the select box ref which on click will open the options
  const container = useRef(null);
  const treeContainer = useRef(null);
  const removedNode = useRef(null);
  const addedNode = useRef(null);
  const isInitialLoad = useRef(true);

  const labelMap = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return getIdNameMap(data);
  }, [data]);

  const compute = useCallback((checkboxId, status) => {
    if (status === checkboxStatus.checked) {
      addedNode.current = checkboxId;
      if (!isMulti) {
        const temp = uncheckAll(items);
        setItems(temp);
        setShowTree(false);
      }
    } else {
      removedNode.current = checkboxId;
    }

    setIsChangemade(true);
  }, [isMulti]);

  const closeShowTree = useCallback((e) => {
    const isOutsideTreeContainer =
      treeContainer.current && !treeContainer.current.contains(e.target);

    if (isOutsideTreeContainer) {
      const isOutsideContainer =
        container.current && !container.current.contains(e.target);

      if (isOutsideContainer) {
        setShowTree(false);
      }
    }
  }, []);

  const handleInputClick = useCallback(() => {
    setShowTree((prev) => !prev);
  }, []);

  const initialUpload = useCallback(() => {
    if (!Array.isArray(values) || values.length === 0) {
      return;
    }

    let temp = items;
    values.forEach((val) => {
      temp = rootTraversal({
        root: temp,
        objId: val,
        status: checkboxStatus.checked,
        parentSelectsChild,
      });
    });

    setItems(temp);
  }, [values, items, parentSelectsChild]);

  useEffect(() => {
    if (!isChangemade) {
      return;
    }

    let temp = items;
    if (addedNode.current) {
      temp = rootTraversal({
        root: temp,
        objId: addedNode.current,
        status: checkboxStatus.checked,
        parentSelectsChild,
      });
    }

    if (removedNode.current) {
      temp = rootTraversal({
        root: temp,
        objId: removedNode.current,
        status: checkboxStatus.unchecked,
        parentSelectsChild,
      });
    }

    const checkedNodeIfNotMulti = addedNode.current ? [addedNode.current] : [];

    addedNode.current = null;
    removedNode.current = null;

    const checkedNodes = getCheckedNodes({ items: temp, parentSelectsChild });

    setIsChangemade(false);
    setItems(temp);
    setValues(isMulti ? checkedNodes : checkedNodeIfNotMulti);
    onChange(isMulti ? checkedNodes : checkedNodeIfNotMulti);
  }, [isChangemade, items, parentSelectsChild, isMulti]);

  useEffect(() => {
    // Placing the option on the top/bottom of the select box based on the screen height available
    // if (showTree && container.current && treeContainer.current) {
    //   const MIN_TOP = 2;
    //   const MAX_HEIGHT_FOR_TREE = 300;
    //   const {
    //     width,
    //     bottom,
    //     top: boundingTop,
    //   } = container.current.getBoundingClientRect();
    //   const height = treeContainer.current?.getBoundingClientRect().height || 0;
    //   let top = bottom + MIN_TOP;
    //   if (height + top >= window.innerHeight) {
    //     top = `${Math.abs(boundingTop - height - 220)}px`;
    //   } else if (bottom >= 500) {
    //     top = `${bottom - 200}px`;
    //   } else {
    //     top = `${top}px`;
    //   }
    //   treeContainer.current.style.top = top;
    //   treeContainer.current.style.maxHeight = `${MAX_HEIGHT_FOR_TREE}px`;
    //   treeContainer.current.style.width = `${width}px`;
    // }

    // Adding the click event when the showTree is true & removing the same
    if (showTree) {
      window.addEventListener('mousedown', closeShowTree);
    } else {
      window.removeEventListener('mousedown', closeShowTree);
    }
  }, [closeShowTree, showTree]);

  useEffect(
    () => () => {
      window.removeEventListener('mousedown', closeShowTree);
    },
    [closeShowTree]
  );

  useEffect(() => {
    if (!isInitialLoad.current) {
      return;
    }

    isInitialLoad.current = false;
    initialUpload();
  }, [initialUpload]);

  const displayData = useMemo(() => {
    if (!Array.isArray(values) || values.length === 0) {
      return <p> Select... </p>;
    }

    return (
      <div className="flex flex-wrap gap-1">
        {values.map((val, idx) => (
          <Tag
            key={`tag_${idx + 1}_${val}`}
            size="md"
            borderRadius="full"
            variant="solid"
            className={classNames(styles.tag__container, {
              [styles.tag__container__dark]: colorMode === 'dark',
            })}
          >
            <TagLabel>{labelMap?.[val] || val || ''}</TagLabel>
            <TagCloseButton
              onClick={(e) => {
                e.stopPropagation();
                compute(val, checkboxStatus.unchecked);
              }}
            />
          </Tag>
        ))}
      </div>
    );
  }, [values, labelMap, colorMode, onChange, isMulti]);

  return (
    <div className={styles.container__wrapper}>
      {/** Rendering Label */}
      <div className="mb-2">
        {label ? <span className="SCLabel">{label}</span> : null}
        {isMandatory ? <span className="ml-1 text-[#Cf3626]">*</span> : null}
      </div>

      <div
        className={classNames(styles.container, {
          [styles.container__dark]: colorMode === 'dark',
        })}
      >
        <div
          ref={container}
          onClick={handleInputClick}
          className={styles.inner__container}
        >
          {displayData}
          <div className={styles.icon__container}>
            <ChevronDownIcon w="20px" h="20px" />
          </div>
        </div>
      </div>

      {showTree ? (
        <div
          ref={treeContainer}
          className={classNames(styles.tree__container, {
            [styles.tree__container__dark]: colorMode === 'dark',
          })}
        >
          <div className={styles.checkboxListsContainer}>
            <CheckboxTree items={items} compute={compute} />
          </div>
        </div>
      ) : null}

      <div>
        {errorMessage ? (
          <span className="field-error">{errorMessage}</span>
        ) : null}
      </div>
    </div>
  );
};

/**
 * Data Format
    type data {
      id: string;
      name: string;
      items?: data[]
    }

 * Value Format
    array of selected ids

 * onChange Format
    return an array of selected ids

 */
CustomInderminateCheckBox.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  isMandatory: PropTypes.bool,
  parentSelectsChild: PropTypes.bool,
  isMulti: PropTypes.bool,
};

CustomInderminateCheckBox.defaultProps = {
  value: [],
  label: '',
  errorMessage: '',
  isMandatory: false,
  parentSelectsChild: true,
  isMulti: true,
};

export default CustomInderminateCheckBox;
