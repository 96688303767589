import React from 'react';

import { Text, useColorModeValue ,Box} from '@chakra-ui/react';
import { CopyIcon, EditIcon } from '@chakra-ui/icons';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';

import TableColumnText from '../../../../components/Text/TableColumnText';

import { deleteRenderer, createMap } from '../../MetricsAndSLA/Metrics/utils';
import {
  WRAP_OPERATOR_BG,
  WRAP_OPERATOR_BG_DARK,
} from '../../../../utils/enums/colors';

export const AUDIENCE_KEY_MAP = {
  NAME: 'name',
  SELECTED_INTENT: 'selected_intent',
  INTENT: 'intent',
  ROLES: 'roles',
  FILTERS: 'filters',
  DESCRIPTION: 'description',
};

export const INTENT_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'create', label: 'Create' },
  { value: 'update', label: 'Update' },
  { value: 'delete', label: 'Delete' },
  { value: 'close', label: 'Close' },
  { value: 'reassign', label: 'Reassign' },
  { value: 'state_transition', label: 'State Transition' },
  { value: 'public_notes', label: 'Public Notes' },
  { value: 'private_notes', label: 'Private Notes' },
];

export const INTENT_OPTIONS_MAP = createMap(INTENT_OPTIONS);

export const convertRolesToArray = (roles) => {
  const convertedArray = Object.entries(roles).flatMap(([key, values]) =>
    Array.isArray(values)
      ? values.map((value) => ({
        leftOperand: key,
        operator: 'IS',
        rightOperand: _startCase(_camelCase(value)),
      }))
      : [
        {
          leftOperand: key,
          operator: 'IS',
          rightOperand: values,
        },
      ]
  );

  return convertedArray;
};

export const renderCriteria = (data) => {
  if (data.length > 0) {
    const { leftOperand, operator, rightOperand } = data[0];

    const classList = 'p-[5px] rounded-[5px] font-medium';
    const operatorMapList = [{name:'org',value:'Organization'},{name:'groups',value:'Group'},{name:'users',value:'User'}] 
    const textColor = useColorModeValue('#111827','#fffff') 

    return (
      <div style={{display:'flex',alignItems:'center',justifyContent:'flex-start',flexWrap:'wrap',padding:'5px'}}>
          {(Array.isArray(data) && Array.isArray(data[0])) && data?.map((audiences,mainIndex)=>{
              return <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px',marginBottom:'10px',marginRight:data.length >= 2 ? '10px':'none'}} key={mainIndex}>
                <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px',border: data.length >= 2 ? '1px solid rgba(37, 99, 235, 1)':'none',padding:data.length >= 2 ?'5px':'none',borderRadius:data.length >= 2 ?'5px':'none',overflow:'hidden',paddingTop:data.length >= 2 ?'none':'10px'}}>
                {audiences?.map((audience,subIndex)=>{
                   return <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px'}} key={subIndex}>
                    {!(subIndex === 0) && <div style={{width:'31px',height:'17px'}}><p style={{color:'rgba(37, 99, 235, 1)',fontWeight:'500',fontSize:'14px'}}>AND</p></div>}
                     <p style={{fontWeight:500,fontSize:'14px',textTransform:'capitalize',...(textColor === '#111827')&&{color:'#111827'} }}>{operatorMapList?.find((data)=>data.name===audience.leftOperand)?.value ?? `User Attribute:${audience.leftOperand}`}&nbsp;:</p>
                     {Array.isArray( audience.rightOperand)? audience?.rightOperand?.map((value,childIndex)=>{
                         return <div key={childIndex} style={{display:'flex',flexWrap:'wrap',flexShrink:0,alignItems:'center',justifyContent:'center',minWidth:'29px',minHeight:'25px',maxWidth:'161px',width:'auto',borderRadius:'5px',backgroundColor:'rgba(222, 232, 252, 1)'}}><p style={{color:'rgba(37, 99, 235, 1)',fontSize:'14px',fontWeight:400,padding:'5px 10px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{value}</p></div>
                     }) : 
                     <div style={{display:'flex',alignItems:'center',justifyContent:'center',minWidth:'29px',minHeight:'25px',maxWidth:'161px',width:'auto',borderRadius:'5px',backgroundColor:'rgba(222, 232, 252, 1)'}}><p style={{color:'rgba(37, 99, 235, 1)',fontSize:'14px',fontWeight:400,padding:'5px 10px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{audience?.rightOperand}</p></div>}  
                   </div>
                 })}
              </div>
              {!(mainIndex === data?.length-1) && <div style={{width:'36px',height:'25px',borderRadius:'5px',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'rgba(242, 243, 244, 1)',marginRight:'12px'}}><p style={{color:'rgba(107, 114, 128, 1)',fontWeight:'500',fontSize:'14px'}}>OR</p></div>}
              </div>
          })}

          {(Array.isArray(data) && !Array.isArray(data[0])) && <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px',marginBottom:'10px'}}>
            <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px',overflow:'hidden',paddingTop:'10px'}}>
              {data?.map((audience,subIndex)=>{
                return <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',gap:'10px'}} key={subIndex}>
                  {!(subIndex === 0) && <div style={{width:'31px',height:'17px'}}><p style={{color:'rgba(37, 99, 235, 1)',fontWeight:'500',fontSize:'14px'}}>AND</p></div>}
                  <p style={{fontWeight:500,fontSize:'14px',textTransform:'capitalize',...(textColor === '#111827')&&{color:'#111827'} }}>{operatorMapList?.find((data)=>data.name===audience.leftOperand)?.value ?? `User Attribute:${audience.leftOperand}`}&nbsp;:</p>
                  {Array.isArray(audience.rightOperand)? audience?.rightOperand?.map((value,childIndex)=>{
                      return <div key={childIndex} style={{display:'flex',flexWrap:'wrap',flexShrink:0,alignItems:'center',justifyContent:'center',minWidth:'29px',minHeight:'25px',maxWidth:'161px',width:'auto',borderRadius:'5px',backgroundColor:'rgba(222, 232, 252, 1)'}}><p style={{color:'rgba(37, 99, 235, 1)',fontSize:'14px',fontWeight:400,padding:'5px 10px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{value}</p></div>
                  }) : 
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center',minWidth:'29px',minHeight:'25px',maxWidth:'161px',width:'auto',borderRadius:'5px',backgroundColor:'rgba(222, 232, 252, 1)'}}><p style={{color:'rgba(37, 99, 235, 1)',fontSize:'14px',fontWeight:400,padding:'5px 10px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{audience?.rightOperand}</p></div>}  
                </div>             
              })}
            </div>
          </div>}

      </div>
    );
  }

  return null;
};

export const convertFiltersToArray = (filters) => {
  const convertedArray = filters.flatMap((filter) =>
    filter.rightOperand.map((value) => ({
      leftOperand: filter.leftOperand,
      operator: filter.operator,
      rightOperand: value,
    }))
  );

  return convertedArray;
};

export const columns = ({ onClone, onDelete, onEditClick }) => [
  {
    col_name: AUDIENCE_KEY_MAP.NAME,
  },
  {
    col_name: AUDIENCE_KEY_MAP.INTENT,
    cellRenderer: ({ rowData }) => {
      let data = rowData?.[AUDIENCE_KEY_MAP.INTENT];
      data = Array.isArray(data) ? data : [];
      const columnText = data
        .reduce((prev, curr) => `${prev} ${INTENT_OPTIONS_MAP[curr]}, `, '')
        .trim()
        .slice(0, -1);

      return <TableColumnText text={columnText} />;
    },
  },
  {
    col_name: 'Criteria',
    cellRenderer: ({ rowData }) => {
      const roles = rowData?.[AUDIENCE_KEY_MAP.ROLES] || {};
      const filters = rowData?.[AUDIENCE_KEY_MAP.FILTERS] || [];

      const data = [];

      if (roles.length > 0) {
        data.push(roles);
      }
      if (filters.length > 0) {
        data.push(...convertFiltersToArray(filters));
      }

      return renderCriteria(data);
    },
  },
  {
    col_name: AUDIENCE_KEY_MAP.DESCRIPTION,
    cellRenderer: ({ rowData }) => {
      const columnText = rowData?.[AUDIENCE_KEY_MAP.DESCRIPTION] || '';
      return <TableColumnText text={columnText} />;
    },
  },
  {
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px]">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            onEditClick(rowData);
          }}
        />
        {deleteRenderer({
          title: 'Delete this audience',
          description: 'Are you sure you want to delete this audience?',
          onConfirm: () => {
            onDelete(rowData.id);
          },
        })}
        <CopyIcon
          className="cursor-pointer"
          onClick={() => {
            onClone(rowData);
          }}
        />
      </div>
    ),
  },
];
