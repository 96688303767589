import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Button,
  Text
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa';
import { DangerCustomButton, PrimaryCustomButton, SecondaryCustomButton } from '../Button/PrimarySecondaryButton';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  deleteModalPayload,
  size
}) => {
  const { title, description, payload } = deleteModalPayload;

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={size ?? '512px'}
      >
        {/* <Box
            m="3rem auto .5rem auto"
            data-testid="metaDeleteModalReactSvgBox"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              border: '1px solid red',
              borderRadius: '7px',
              padding: '8px',
            }}
          >
            <FaTrashAlt
              color="red"
              size="30px" // Adjust size to make the icon appear thinner
              style={{
                // Simulate a thinner appearance by reducing the size
                lineHeight: 1,
                fontWeight: 'normal', // Ensure the icon is not bold
              }}
            />
          </Box> */}
        {/* <ModalHeader
            boxShadow="none"
            fontSize="30px"
            fontWeight="600"
            color="rgba(0, 0, 0, 0.88)"
            lineHeight={'35px'}
            padding="10px 0px 0px 33px"
            marginTop={'15px'}
          >
            {title}
          </ModalHeader> */}

        <ModalBody
          margin="0px" padding="24px"
        >
          <Box>
            <Box display="flex" alignItems="start" gap="10px">
              <Box padding="7px" borderRadius="100px" backgroundColor="#FEE2E2"><ExclamationTriangleIcon height="24px" width="24px" color="#DC2626" /></Box>

              <Box display="flex" flexDir="column" alignItems="start" gap="10px">
                <Text fontSize="18px" color="#111827" fontWeight="500" lineHeight="normal">{title}</Text>
                <Box style={{ fontSize: "14px", color: '#6B7280', fontWeight: "400", lineHeight: "normal" }}>
                  {description}
                </Box>
              </Box>

            </Box>


          </Box>

        </ModalBody>

        <ModalFooter display="flex" alignItems="center" justifyContent="end" boxShadow="none" gap="10px">
          <SecondaryCustomButton
            onClick={onClose}
            buttonText='Cancel'
          />
          <DangerCustomButton
            // isLoading={loading}
            onClick={() => {
              onConfirm(payload);
              onClose();
            }}
            buttonText='Delete'
          />

        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteConfirmationModal;