import React, { useCallback, useMemo, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomInput from '../../components/InputBox/Input';
import DynamicSelectWithInput from '../Audience/DynamicSelectWithInput';
import CustomTextArea from '../../components/Textarea/CustomTextArea';
import CustomDrawer from '../TenantFilters/CustomDrawer';
import CustomInderminateCheckBox from '../../components/IndeterminateCheckbox';

import { roleSettingSelectOpt } from '../../utils/enums/selectOption';
import { FORM_MAP, validatePayload } from './utils';

import styles from './audience.module.scss';
import { getAudience } from '../../redux/FieldDefaults';
import { makeApiCallForActor } from '../TicketTemplateBuilder/AddEditActorModal/helper';
import { IoAddOutline } from "react-icons/io5";
import { Box, Flex, Text } from '@chakra-ui/layout';


const AddNewAudience = ({
  selectedAudience,
  onCreateOrEditAudience,
  intentOptions,
  open,
  setOpen,
  isLoading,
  setActorsList,
  actorsList
}) => {
  const [data, setData] = useState({ ...(selectedAudience || {}) });
  const [multi, setMulti] = useState([{ id: 0, value: [] ,error:null ,isRequired:false }])
  const [errorKeyListWithMessage, setErrorKeyListWithMessage] = useState({});
  
  useEffect(() => { splitMulti() }, [])

  const handleMultiStepValue = (keyId,value) => {
    resetErrorMulti()
    setMulti((prev)=>{
      return prev.map((record)=>{
        if (keyId === record.id)record.value = value
        return record
      })   
    })
  }

  const removeMultiStepValue = (keyId) => {
    resetErrorMulti()
    setMulti((prev)=>{
      return prev.filter((record)=>keyId !== record.id)   
    })
  }
  
  const handleAdd = () => {
    resetErrorMulti()
    if((multi[multi?.length-1]?.value) && (multi[multi?.length - 1].value).length !== 0){
      setMulti((prev) => [
        ...prev,
        { id: (prev[prev.length - 1]?.id ?? 0) + 1, value: [] },
      ]);
    }else{
      setMulti((prev)=>{
        return prev.map((record)=>{
          if ((prev[prev.length - 1]?.id ?? 0) === record.id)record.error = 'please fill the field'
          return record
        })   
      })
    }
  };

  const resetErrorMulti = () => {
    setMulti((prev)=>{
      return prev.map((record)=>{
        record.error = null
        record.isRequired = false
        return record
      })   
    })
  }

  const audiences = useSelector(getAudience);
  const onDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback((key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrorKeyListWithMessage((prev) => ({
      ...prev,
      [key]: '',
    }));

    resetErrorMulti()
  }, []);
  const [actorOption, setActorOption] = useState([]);
  const fetchDataForActorOption = async () => {
    const result = await makeApiCallForActor();
    setActorOption(result?.rows || []);
    setActorsList(result?.rows || []);
  };
  useEffect(() => {
    fetchDataForActorOption();
  },[]);
  
  const drawerBody = useMemo(
    () => (
      <>
        <div className="mb-4">
          <CustomInput
            customStyling={true}
            label="Name"
            value={data?.[FORM_MAP.NAME] || ''}
            onChange={(e) => handleChange(FORM_MAP.NAME, e.target.value)}
            isError={errorKeyListWithMessage?.[FORM_MAP.NAME] || false}
            errorMessage={errorKeyListWithMessage?.[FORM_MAP.NAME] || ''}
            isMandatory
          />
        </div>

       {/* <div className="mb-4">
          <CustomInderminateCheckBox
            label="Intent Groups"
            data={intentOptions}
            value={data?.[FORM_MAP.INTENT_GROUPS] || []}
            onChange={(e) => handleChange(FORM_MAP.INTENT_GROUPS, e)}
            isMandatory
            errorMessage={
              errorKeyListWithMessage?.[FORM_MAP.INTENT_GROUPS] || ''
            }
            parentSelectsChild={false}
          />
        </div>*/}

        <div>
          <CustomTextArea
            customStyling={true}
            label="Description"
            value={data?.[FORM_MAP.DESCRIPTION] || ''}
            onChange={(e) => handleChange(FORM_MAP.DESCRIPTION, e.target.value)}
          />
        </div>

      
        <div style={{minWidth:'698px',padding:'10px',border:`1px solid ${multi.filter((record)=> record?.isRequired === true).length != 0   ? 'red' : '#d1d5db'}`,boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',borderRadius:'6px',
          display:'flex',flexDirection:'column',justifyContent:'space-between',marginTop:'20px'}}>
          <div {...(multi.length === 1) && {style:{borderBottom:'1px solid rgba(209, 213, 219, 1)'}}}>
          {multi.map((record,index)=>{
            return <DynamicSelectWithInput
            key = {index}
            multiKey={record.id}
            roleMapindex={index}
            values={Array.isArray(data?.[FORM_MAP.ROLE]) && Array.isArray(data?.[FORM_MAP.ROLE][0]) ? data?.[FORM_MAP.ROLE] : [data?.[FORM_MAP.ROLE]] ?? []}
            label="Audience Criteria"
            isMandatory
            showQueueMapField={false}
            selectOptions={roleSettingSelectOpt}
            onChange={(e) => handleChange(FORM_MAP.ROLE, e)}
            actorOption={actorOption}
            includeUserAttributes
            audience={record.value}
            setAudience={handleMultiStepValue} 
            actorsList={actorsList} 
            removeMultiStepValue={removeMultiStepValue}
            currentFieldError = {record.error}
            resetErrorMulti={resetErrorMulti}
          />
          })}
          </div>
          <div {...(multi.length === 1) ? {style:{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'flex-end'}} :{style:{display:'flex',alignItems:'center',justifyContent:'flex-end'}}}>
            <div onClick={()=>handleAdd()} style={{height:'36px',width:'187px',borderRadius:'5px',padding:'9px 10px',color:'rgba(37, 99, 235, 1)',display:'flex',alignItems:'center',justifyContent:'center',gap:4,border:'1px solid rgba(37, 99, 235, 1)'}}>
              <Box>
                <IoAddOutline size={'24px'} color={'rgba(7,99,235,1)'}/>
              </Box>
              <p style={{fontWeight:500,fontSize:'14px',cursor:'pointer',flexShrink:0}}>Add Another Criteria</p>
            </div>
          </div>
        </div>
        {multi.filter((record)=> record?.isRequired === true).length != 0  && <div style={{fontSize:'14px',fontWeight:'400',marginTop:'10px' ,color:'red'}}>Audience criteria is required.</div>}
         
          {errorKeyListWithMessage?.[FORM_MAP.ROLE] ? (
            <span className="field-error">
              {errorKeyListWithMessage?.[FORM_MAP.ROLE]}
            </span>
          ) : null}

      </>
    ),
    [errorKeyListWithMessage, data, handleChange, intentOptions, actorOption,multi]
  );

  const handleSave = useCallback(() => {
    const isNameExist = !selectedAudience?.name && audiences?.some(x=>x?.name === data?.name);
    const validationCheck = validatePayload(data, isNameExist);
    resetErrorMulti()
    if (!validationCheck.success) {
      setErrorKeyListWithMessage(validationCheck.message);
      return;
    }
    let payload={}
    payload=validationCheck.payload

    const transformData = multi.map((record)=>{
      const data = record.value
      if(data.length !== 0) return data
    })

    let checkValueIsNotPresented = false
    
    if(multi.length === 1  && multi[0]?.value?.length === 0){
      setMulti((prev)=>{
        return prev.map((record)=>{
          record.isRequired = true
          return record
        })   
      })
      return
    }  

      multi?.forEach((element,index) => {
        if(element?.value?.length === 0 ){
          checkValueIsNotPresented = true
          setMulti((prev)=>{
            return prev.map((record,prevIndex)=>{
              if (prevIndex === index ){
                record.error = 'please fill the field'
              }
              return record
            })   
          })
        }
      });   
    
    payload.role=transformData

    if(!checkValueIsNotPresented){
      onCreateOrEditAudience(payload);
    }
  }, [data, onCreateOrEditAudience, selectedAudience , multi]);

  const memoizedMulti = useMemo(() => {
    return multi
  },[handleMultiStepValue,removeMultiStepValue,handleAdd,handleSave])

  const splitMulti = () =>{
    const dynamicFieldEntry = []
    if (Array.isArray(data.role) && !Array.isArray(data.role[0])) { 
        dynamicFieldEntry.push({ id:0 , value: data.role ?? [] ,error:null,isRequired:false})
        setMulti(dynamicFieldEntry)
    }else{
      if(data?.role?.length){
         data.role.forEach((record,index) => {
          dynamicFieldEntry.push({ id: index , value: [],error:null,isRequired:false})
         });
         setMulti(()=>{
          return dynamicFieldEntry.map((record,index)=>{
            record.value = data.role[index] ?? []
            return record
          })
         })
      }else{
        setMulti([{ id: 0, value: [] ,error:null ,isRequired:false }])
      }
    }
  }

  return (
    <CustomDrawer
      title="Audience"
      closeDrawer={onDrawerClose}
      handleSave={handleSave}
      open={open}
      drawerBody={drawerBody}
      customStyling = {true}
      isLoading={isLoading}
      size="xl"
    />
  );
};

AddNewAudience.propTypes = {
  selectedAudience: PropTypes.object,
  intentOptions: PropTypes.array,
  onCreateOrEditAudience: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setActorsList: PropTypes.func,
  actorsList:PropTypes.array
};

AddNewAudience.defaultProps = {
  selectedAudience: {},
  intentOptions: [],
  isLoading: false,
  setActorsList: () => {}
    
};

export default AddNewAudience;
