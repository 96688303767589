import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  Drawer,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  DrawerFooter,
  DrawerBody,
  Box,
  DrawerOverlay,
  HStack
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { LABELS } from '../../utils/enums/labels';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import {
  QUICK_ACTION_DATA_KEYS,
  QUICK_ACTION_LABEL,
  QUICK_ACTION_TYPE,
  QUICK_ACTION_TYPE_OPTIONS,
} from './quickAction.constants';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import WrapperForSelectBoxWithAddButton from './WrapperForSelectBoxWithAddButton';
import WrapperForCanned from './WraperForCannedNote';
import WrapperForFieldChange from './WrapperForFieldChange';
import WrapperForAttachment from './WrapperForAttachment';
import { getTeamQueueMap } from '../../components/selectFieldFromTemplateWithDelete/utils';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const EditQuickActionDrawer = ({
  setOpenDrawer,
  openDrawer,
  selectedRowData,
  onSaveData,
  errorWithPathAsKey,
  setErrorWithPathAsKey,
  isLoading,
  clearSelectedData,
}) => {
  const [teamQueueData, setTeamQueueData] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);

  const [rowData, setRowData] = useState(
    {
      ...selectedRowData,
    } || {}
  );
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const handleOnChangeWithPath = (value, path) => {
    setRowData((pre) => ({
      ...pre,
      [path]: value,
    }));
    setErrorWithPathAsKey((pre) => ({
      ...pre,
      [path]: '',
    }));
  };
  const [selectedActionType, setSelectedActionType] = useState('');

  const cannedNoteOptions =
    selectedTemplate?.communication_templates
      ?.filter((item) => item.type === 'CANNED_NOTES')
      .map((item) => ({ value: item.id, label: item.name })) || [];

  const updateRowDataActionItem = (value, index, key = 'list_data') => {
    setRowData((pre) => {
      const dataList = [...(pre.action_list || [])];
      dataList[index] = {
        ...dataList[index],
        action_type: dataList[index].action_type,
        [key]: value,
      };
      return { ...pre, action_list: dataList };
    });
  };
  const deleteActionItem = (index) => {
    const newActionList = [...rowData.action_list];
    newActionList.splice(index, 1);
    setRowData((pre) => ({ ...pre, action_list: newActionList }));
  };
  useEffect(() => {
    getTeamQueueMap()
      .then((value) => {
        setTeamQueueData(value);
      })
      .catch(() => setTeamQueueData([]));
  }, []);

  useEffect(() => {
    getHierarchies(setHierarchies);
  }, []);
  const renderElementByActionType = (item, index) => {
    switch (item.action_type) {
      case QUICK_ACTION_TYPE.ADD_ACTOR:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Add Actor"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
            restrictLengthToBeOne
          />
        );
      case QUICK_ACTION_TYPE.REMOVE_ACTOR:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Remove Actor"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.ADD_TO_WATCHERS:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Add To Watchers"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.REMOVE_TO_WATCHERS:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Remove To Watchers"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.ADD_CC_WATCHERS:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Add CC Watchers"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.REMOVE_CC_WATCHERS:
        return (
          <WrapperForSelectBoxWithAddButton
            label="Remove CC Watchers"
            optionList={availableUsersListInTenantLevel()}
            values={item.list_data}
            onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.ADD_NOTE:
        return (
          <WrapperForCanned
            label="Add Note"
            values={item.canned_noted_id}
            optionList={cannedNoteOptions}
            onUpdatedValue={(value) =>
              updateRowDataActionItem(value, index, 'canned_noted_id')
            }
            onDelete={() => deleteActionItem(index)}
          />
        );
      case QUICK_ACTION_TYPE.FIELD_CHANGE:
        return (
          !isEmpty(teamQueueData) && (
            <WrapperForFieldChange
              label="Field Change"
              onUpdatedValue={(value) => updateRowDataActionItem(value, index)}
              values={item.list_data}
              onDelete={() => deleteActionItem(index)}
              teamQueueMap={teamQueueData}
              hierarchies={hierarchies}
              fieldHierarchyMap={selectedTemplate?.field_hierarchy_map || []}
            />
          )
        );
      case QUICK_ACTION_TYPE.ADD_ATTACHMENT:
        return (
          <WrapperForAttachment
            label="Add Attachment"
            onUpdatedValue={(value) => {
              updateRowDataActionItem(value, index);
            }}
            onUpdatedFieldId={(value) =>
              updateRowDataActionItem(value, index, 'fieldId')
            }
            values={item}
            onDelete={() => deleteActionItem(index)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      size="xl"
      placement="right"
      onClose={() => {
        setOpenDrawer(false);
        clearSelectedData({});
        setErrorWithPathAsKey({});
      }}
      isOpen={openDrawer}
      data-testid="EditQueueFieldDrawer"
      closeOnOverlayClick={false}
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setOpenDrawer(false);
                clearSelectedData({});
              }}
            />
            <Text fontSize="16px">{QUICK_ACTION_LABEL.ADD_EDIT_TITLE}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <div>
            <CustomInput
              type="text"
              label={QUICK_ACTION_LABEL.NAME}
              placeholder={QUICK_ACTION_LABEL.NAME}
              value={rowData?.[QUICK_ACTION_DATA_KEYS.NAME] || ''}
              onChange={(e) => {
                handleOnChangeWithPath(
                  e.target.value,
                  QUICK_ACTION_DATA_KEYS.NAME
                );
              }}
              isError={
                errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.NAME] || false
              }
              errorMessage={
                errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.NAME] || ''
              }
              isMandatory
            />
          </div>
          <Box mt="10px">
            <CustomInput
              type="text"
              label={QUICK_ACTION_LABEL.DESCRIPTION}
              placeholder={QUICK_ACTION_LABEL.DESCRIPTION}
              value={rowData?.[QUICK_ACTION_DATA_KEYS.DESCRIPTION] || ''}
              onChange={(e) => {
                handleOnChangeWithPath(e.target.value, [
                  QUICK_ACTION_DATA_KEYS.DESCRIPTION,
                ]);
              }}
              isError={
                errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.DESCRIPTION] ||
                false
              }
              errorMessage={
                errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.DESCRIPTION] || ''
              }
            />
          </Box>
          <Flex gap="20px" mt="10px" mb="10px">
            <Box w="90%">
              <CustomSelectBox
                options={QUICK_ACTION_TYPE_OPTIONS}
                value={getWholeOption(
                  selectedActionType,
                  QUICK_ACTION_TYPE_OPTIONS
                )}
                label="Action Types"
                onChange={(e) => {
                  setSelectedActionType(e.value);
                  setErrorWithPathAsKey((pre) => ({
                    ...pre,
                    [QUICK_ACTION_DATA_KEYS.ACTION_LIST]: '',
                  }));
                }}
                isError={
                  errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] ||
                  false
                }
                errorMessage={
                  errorWithPathAsKey?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] || ''
                }
              />
            </Box>
            <CustomButton
              buttonText="Add"
              variant="outline"
              style={{ height: '38px', marginTop: '32px' }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={() => {
                setRowData((pre) => ({
                  ...pre,
                  action_list: [
                    ...(pre?.action_list || []),
                    {
                      action_type: selectedActionType,
                    },
                  ],
                }));
                setSelectedActionType('');
                setErrorWithPathAsKey((pre) => ({
                  ...pre,
                  [QUICK_ACTION_DATA_KEYS.ACTION_LIST]: '',
                }));
              }}
            />
          </Flex>
          {[...(rowData?.action_list || [])].map((item, index) =>
            renderElementByActionType(item, index)
          )}
        </DrawerBody>
        <DrawerFooter>
          <HStack>
          <SecondaryCustomButton
            // variant="outline"
            pl="25px"
            pr="25px"
            // buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            // style={{
            //   border: 'none',
            //   background: COLORS.SIGNATURE_DRAWER_BG,
            //   height: '38px',
            //   marginRight: '10px',
            //   color: COLORS.SIGNATURE_DRAWER_COLOR,
            // }}
            // _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenDrawer(false);
              clearSelectedData({});
              setErrorWithPathAsKey({});
            }}
            size="lg"
          />
          <PrimaryCustomButton
            buttonText="Save"
            // variant="solid"
            onClick={() => onSaveData(rowData)}
            // style={{
            //   backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
            //   height: 40,
            //   color: '#fff',
            // }}
            isLoading={isLoading}
            id="FilterEditModalButtonSaveBtn"
          />
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

EditQuickActionDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  selectedRowData: PropTypes.object,
  onSaveData: PropTypes.func.isRequired,
  errorWithPathAsKey: PropTypes.object,
  setErrorWithPathAsKey: PropTypes.func,
  isLoading: PropTypes.bool,
  clearSelectedData: PropTypes.func,
};
EditQuickActionDrawer.defaultProps = {
  selectedRowData: {},
  errorWithPathAsKey: {},
  setErrorWithPathAsKey: () => {},
  isLoading: false,
  clearSelectedData: () => {},
};
export default EditQuickActionDrawer;
