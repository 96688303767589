import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  Tooltip
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { TbFilterCog } from "react-icons/tb"
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
// import { ReactSVG } from 'react-svg';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import CustomButton from '../../../components/Button/SimpleButton';
import AddQuickFilter from '../QuickFilters/AddQuickFilters';
import QuickFiltersList from '../QuickFilters/QuickFiltersList';

import { COLORS, GRAY_800, GREY_BACKGROUND } from '../../../utils/enums/colors';
import {
  addNewQuickFilter,
  setSelectedFilterId,
} from '../../../redux/CardReducer/quickFilterSlice';
import { createFilterDropdownMap, statusesMap } from '../utils';
import { QUICK_FILTERS_LABELS } from '../../../utils/enums/labels';

// import FilterIcon from '../../../assets/icons/FilterIcon.svg';
// import FilterDarkIcon from '../../../assets/icons/filterDarkIcon.svg';

import styles from '../cardLayout.module.scss';
import quickFilterStyles from '../QuickFilters/quickFilterStyles.module.scss';

const QuickFilterPopOver = ({
  // iconButtonClass,
  onApplyQuickFilter,
  // standardHeight,
}) => {
  const dispatch = useDispatch();
  const { quickFiltersList, selectedFilterId } = useSelector(
    (state) => state.quickFilter
  );

  const [activeTab, setActiveTab] = useState(0);
  const [selectedQuickFilter, setSelectedQuickFilter] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const TABS = useMemo(
    () => [
      QUICK_FILTERS_LABELS.SAVED_FILTERS,
      QUICK_FILTERS_LABELS.CREATE_FILTERS,
    ],
    []
  );

  const onEditClicked = useCallback((filter) => {
    setSelectedQuickFilter({ ...filter });
    setActiveTab(1);
  }, []);

  const onCreateFiltersFromEmptyListClick = useCallback((event) => {
    event.stopPropagation();
    setSelectedQuickFilter(null);
    setActiveTab(1);
  }, []);

  const closeDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const onChangeTab = useCallback((index) => {
    setActiveTab(index);
    if (index === 1) {
      setSelectedQuickFilter(null);
    }
  }, []);

  const resetTabPanel = (filterData) => {
    onApplyQuickFilter(filterData);
    setActiveTab(0);
    closeDrawer();
  };

  const saveQuickFilters = async (filterData) => {
    const selectedFilterData = {
      id: filterData.id || uuidv4(),
      filterName: filterData.filterName,
      dateRanges: filterData.dateRanges,
      dateRangeColumn: filterData.dateRangeColumn?.value,
      isDefault: filterData.isDefault || false,
      selectedLifeCycle: filterData.selectedLifeCycle?.value,
      isEdited: !!filterData.id,
    };

    await dispatch(addNewQuickFilter(selectedFilterData));
    await dispatch(setSelectedFilterId(selectedFilterData.id));

    // Applying the filters
    resetTabPanel(filterData);
  };

  const onApplyFilter = () => {
    const quickFilter = quickFiltersList.find((f) => f.id === selectedFilterId);

    const tempData = {
      dateRanges: quickFilter.dateRanges,
      selectedLifeCycle: {
        label: statusesMap[quickFilter.selectedLifeCycle],
        value: quickFilter.selectedLifeCycle,
      },
      dateRangeColumn: {
        label: createFilterDropdownMap[quickFilter.dateRangeColumn],
        value: quickFilter.dateRangeColumn,
      },
    };

    resetTabPanel(tempData);
  };

  return (
    <>
    <Tooltip
            hasArrow
            placement="top"
            label={'Filter'}
            data-testid="template-header-filter"
          >
      <Box
        onClick={() => setOpenDrawer(true)}
        // className={iconButtonClass}
        className={`${useColorModeValue(
          quickFilterStyles.quickfilterPopoverButtonLight,
          quickFilterStyles.quickfilterPopoverButtonDark
        )}`}
        data-testid="template-header-filter"
        // h={standardHeight}
      >
        {/* <ReactSVG src={useColorModeValue(FilterIcon, FilterDarkIcon)} /> */}
        <TbFilterCog
          size="20px"
          color={useColorModeValue(
            quickFilterStyles.quickfilterPopoverButtonLight,
            quickFilterStyles.quickfilterPopoverButtonDark
          )}
        />
      </Box>
      </Tooltip>
      <Drawer
        size="md"
        placement="right"
        onClose={closeDrawer}
        isOpen={openDrawer}
        closeOnOverlayClick={false}
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent>
          <DrawerHeader>
            <Flex alignItems="center" gap="10px">
              <IconButton
                colorScheme="blue"
                aria-label="Close Drawer"
                size="sm"
                borderRadius="50%"
                minW="1.5rem"
                height="1.5rem"
                variant="outline"
                icon={<ArrowBackIcon />}
                onClick={closeDrawer}
              />
              <Text fontSize="16px"> Filters </Text>
            </Flex>
          </DrawerHeader>

          <DrawerBody className={quickFilterStyles.drawer__body}>
            {/* Tabs container */}
            <Box
              className={quickFilterStyles.tab__parent}
              background={useColorModeValue('white', 'var(--drawer-bg)')}
            >
              <Flex
                bg={GREY_BACKGROUND}
                className={quickFilterStyles.tab__container}
              >
                {TABS.map((tab, index) => (
                  <Box
                    onClick={() => onChangeTab(index)}
                    role="tab"
                    className={quickFilterStyles.tab}
                    key={tab}
                  >
                    <Text
                      className={`${quickFilterStyles.tab__text} ${
                        activeTab === index
                          ? quickFilterStyles.tab__selected
                          : ''
                      }`.trim()}
                      color={GRAY_800}
                    >
                      {tab}
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>

            {/* Quick Filters List */}
            {activeTab === 0 ? (
              <Box>
                {quickFiltersList?.length > 0 ? (
                  <QuickFiltersList
                    quickFilters={quickFiltersList}
                    onEdit={onEditClicked}
                    onApplyFilter={onApplyFilter}
                  />
                ) : (
                  <Box className={quickFilterStyles.no__filters}>
                    <Text className={styles.quickfilterTitle}>
                      You have no quick filters added
                    </Text>
                    <Text
                      className={styles.quickfilterText}
                      mt="10px"
                      data-testid="QuickFilterPopOverQuickFilterText"
                    >
                      Click &apos;Create Filter&apos; to get started
                    </Text>
                    <CustomButton
                      mt="25px"
                      buttonText={QUICK_FILTERS_LABELS.CREATE_FILTER}
                      variant="solid"
                      color={useColorModeValue(COLORS.WHITE, COLORS.WHITE)}
                      colorScheme="customBlue"
                      className={styles.createButton}
                      onClick={onCreateFiltersFromEmptyListClick}
                      onMouseDown={(e) => e.preventDefault()}
                      data-testid="QuickFilterPopOverButtonGroupCreatBtvb"
                    />
                  </Box>
                )}
              </Box>
            ) : null}

            {/* Create Filters */}
            {activeTab === 1 ? (
              <AddQuickFilter
                selectedQuickFilter={selectedQuickFilter}
                onSaveApplyFilterClick={saveQuickFilters}
                onApplyQuickFilter={resetTabPanel}
                activeTab={activeTab}
              />
            ) : null}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

QuickFilterPopOver.propTypes = {
  // iconButtonClass: PropTypes.string,
  onApplyQuickFilter: PropTypes.func.isRequired,
};

QuickFilterPopOver.defaultProps = {
  // iconButtonClass: '',
};

export default QuickFilterPopOver;
