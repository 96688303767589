/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  useColorModeValue,
  Checkbox,
} from '@chakra-ui/react';
import { components } from 'react-select';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  EMPTY_PERMISSION_ITEM,
  PERMISSION_KEY_MAP,
  PERMISSION__MAP_WITH_LABEL_KEY_LIST,
  removeMatchingOptionForPermission,
  createActionPermissionValidation,
  MAP_PERMISSION_WITH_ROLE,
  ALL_PERMISSION_DENIED,
} from '../TemplatePermissionMap/util';
import {
  getStatusObject,
  processErrors,
} from '../DynamicRenderer/AddEditSignatureDrawer/utils';
import allCompositeService from '../../redux/TicketTemplate/AllCompositeReducer/AllCompositeService';
import {
  createTenantPermission,
  editTenantPermission,
  getTenantPermission,
} from './tenantPermission.service';
import { getTenantData } from '../../helpers/tenantUrl';
import Loader from '../DynamicRenderer/Dashboard/Loader';

// eslint-disable-next-line no-unused-vars
const TenantPermissionMap = ({ label }) => {
  const [permissionList, setPermissionList] = useState([]);
  const [errorKeyLIst, setErrorKeyList] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [permissionObject, setPermissionObject] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (isEmpty(permissionObject)) {
      getTenantPermission().then((res) => {
        if (res) {
          setPermissionObject(res || {});
          setPermissionList(res?.action_permissions || []);
        }
      });
    }
  }, [permissionObject]);

  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const addActionPermission = async () => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    setIsLoading(true);
    const newData = [...(permissionList || [])];
    const temp = cloneDeep(permissionObject);
    const id = temp?.id;
    const promise = id
      ? editTenantPermission({
        id,
        payload: { action_permissions: permissionList, tenant_id: tenantId },
      })
      : createTenantPermission({
        payload: { action_permissions: permissionList, tenant_id: tenantId },
      });

    promise
      .then((res) => {
        if (res) {
          if (id) {
            setPermissionObject(res || {});
            setPermissionList(res?.action_permissions || []);
          } else {
            setPermissionObject(res || {});
            setPermissionList(res?.action_permissions || []);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const getRoleList = async () => {
    try {
      const responseList = await allCompositeService.getRoleListApi();
      setRoleList(responseList.map((role) => role.name));
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    let data = permissionList || [];
    data = Array.isArray(data) ? [...data] : [];
    // get role list
    getRoleList();

    if (!data.length) {
      data.push(EMPTY_PERMISSION_ITEM);
    }
    setPermissionList(data);
    setLoader(false);
  }, []);
  const ROLE_OPTION_LIST = useMemo(() => {
    const roles =
      roleList?.map((item) => ({
        value: item,
        label: item,
        type: 'ROLE',
      })) || [];
    const uniqueRoles = uniqBy([...roles], 'value');
    return uniqueRoles;
  }, [roleList]);

  const { Option } = components;
  // eslint-disable-next-line react/no-unstable-nested-components
  const IconOption = (props) => {
    const { data } = props;

    const { label: newLabel, type } = data;
    return (
      <Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{newLabel}</div>
          <div
            style={{ marginLeft: '10px', color: '#ccc', fontStyle: 'italic' }}
          >
            {type}
          </div>
        </div>
      </Option>
    );
  };
  const onAddPermissionInPermissionList = () => {
    const IS_VALID_FIELD_LIST = permissionList.every(
      (item) =>
        item[PERMISSION_KEY_MAP.USER_ID] && item[PERMISSION_KEY_MAP.USER_TYPE]
    );
    // if (!IS_VALID_FIELD_LIST) {
    //   return;
    // }

    const tempData = cloneDeep([...permissionList, EMPTY_PERMISSION_ITEM]);
    setPermissionList(tempData);
  };
  const getPermissionMap = (roleType) => {
    const type = _startCase(_camelCase(roleType));
    if (MAP_PERMISSION_WITH_ROLE[type]) {
      return MAP_PERMISSION_WITH_ROLE[type];
    }
    return {
      ...ALL_PERMISSION_DENIED
    };
  };
  const handleOnChangeSelectBox = (value, permissionIndex) => {
    setErrorKeyList({});
    setPermissionList((prevList) =>
      prevList.map((item, index) =>
        index === permissionIndex
          ? {
            ...item,
            [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: getPermissionMap(
              value.value
            ),
            [PERMISSION_KEY_MAP.USER_ID]: value.value,
            [PERMISSION_KEY_MAP.USER_TYPE]: value.type,
          }
          : item
      )
    );
  };
  const handleOnChangeCheckBox = (checked, key, permissionIndex) => {
    setPermissionList((prevList) =>
      prevList.map((item, index) =>
        index === permissionIndex
          ? {
            ...item,
            [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: {
              ...item[PERMISSION_KEY_MAP.PERMISSION_MAPPING],
              [key]: checked,
            },
          }
          : item
      )
    );
  };
  const handleSelectAllCheckbox = (checked, permissionIndex) => {
    setPermissionList((prevList) =>
      prevList.map((item, index) =>
        index === permissionIndex
          ? {
            ...item,
            [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: {
              ...item[PERMISSION_KEY_MAP.PERMISSION_MAPPING],
              ...PERMISSION__MAP_WITH_LABEL_KEY_LIST.reduce((acc, curr) => {
                acc[curr.key] = checked;
                return acc;
              }, {}),
            },
          }
          : item
      )
    );
  };
  const onSavePermission = () => {
    setIsLoading(true);
    const { error } = createActionPermissionValidation.validate(
      permissionList,
      {
        abortEarly: false,
      }
    );
    if (!isEmpty(error)) {
      const tempError = processErrors(error.details, 1);
      setErrorKeyList(tempError);
      setIsLoading(false);
    } else {
      addActionPermission();
    }
  };
  const onDeleteSelectedPermissionFromList = (index) => {
    let newStaticValue = [...permissionList];
    if (newStaticValue.length === 1) {
      newStaticValue = [{ ...EMPTY_PERMISSION_ITEM }];
    } else {
      newStaticValue.splice(index, 1);
    }
    setPermissionList(newStaticValue);
  };
  const isCheckAllPermission = useCallback(
    (permissionIndex) =>
      Object.values(
        permissionList[permissionIndex][PERMISSION_KEY_MAP.PERMISSION_MAPPING]
      ).every((value) => value === true),
    [permissionList]
  );

  if (loader) {
    return <Loader />;
  }

  return (
    <Box p="15px" height="calc(100vh - 13rem)" overflow="auto">
      <Flex align="center" justify="space-between">
        <Text fontWeight="500">
{' '}
{label}
        </Text>
      </Flex>
      <Box pt="20px" maxH="600px" overflowX="scroll">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'scroll',
            paddingBottom: '20px',
          }}
          className="list-scroll"
        >
          <Text fontSize="16px" color="#111827" fontWeight="500" lineHeight="normal" margin="20px 0px 10px 0px">Permission</Text>
          <table className="w-full">
            <thead>
              <tr>
                <th />
                <th className="w-[400px] whitespace-nowrap !px-2 !text-center">
                  <Text fontWeight="500" fontSize="15px">
                    Select All
                  </Text>
                </th>
                {PERMISSION__MAP_WITH_LABEL_KEY_LIST.map((label1) => (
                  <th className="w-[400px] whitespace-nowrap !px-2 !text-center">
                    <Text fontWeight="500" fontSize="14px" color={useColorModeValue('#111827','')}>
                      {label1.label}
                    </Text>
                  </th>
                ))}
                <th><Text fontSize="14px" color={useColorModeValue('#111827','')} fontWeight="500" lineHeight="normal">Action</Text></th>
              </tr>
            </thead>
            <tbody>
              {permissionList.map((permission, permissionIndex) => (
                <tr>
                  <td className="!text-center !px-2">
                    <Box width="100%" height="40px" marginTop="10px" mx="10px">
                      <CustomSelectBox
                        options={removeMatchingOptionForPermission(
                          ROLE_OPTION_LIST,
                          permissionList
                        )}
                        components={{ Option: IconOption }}
                        value={getStatusObject(
                          permission[PERMISSION_KEY_MAP.USER_ID],
                          ROLE_OPTION_LIST
                        )}
                        onChange={(e) => {
                          handleOnChangeSelectBox(e, permissionIndex);
                        }}
                        isError={
                          errorKeyLIst[PERMISSION_KEY_MAP.USER_ID] &&
                          !permission[PERMISSION_KEY_MAP.USER_ID]
                        }
                        errorMessage="Please select the user role"
                      />
                    </Box>
                  </td>
                  <td style={{paddingTop:"28px", textAlign:"center" }}>
                    <Checkbox
                      key={permission[PERMISSION_KEY_MAP.USER_ID]}
                      _checked={{
                        "& .chakra-checkbox__control": { background: "#2563EB", borderColor: '#2563EB' }
                      }} 
                      onChange={(e) => {
                        handleSelectAllCheckbox(
                          e.target.checked,
                          permissionIndex
                        );
                      }}
                      isChecked={isCheckAllPermission(permissionIndex)}
                      isDisabled={!permission[PERMISSION_KEY_MAP.USER_ID]}
                    />
                  </td>
                  {PERMISSION__MAP_WITH_LABEL_KEY_LIST.map((item) => (
                    <td style={{textAlign:"center", paddingTop:"28px" }}>
                      <Checkbox
                        isChecked={
                          permission[PERMISSION_KEY_MAP.PERMISSION_MAPPING][
                            item.key
                          ]
                        }
                        _checked={{
                          "& .chakra-checkbox__control": { background: "#2563EB", borderColor: '#2563EB' }
                        }} 
                        onChange={(e) => {
                          handleOnChangeCheckBox(
                            e.target.checked,
                            item.key,
                            permissionIndex
                          );
                        }}
                        isDisabled={!permission[PERMISSION_KEY_MAP.USER_ID]}
                      />
                    </td>
                  ))}
                  <td style={{paddingTop:"28px"}}>
                    <DeleteIcon
                      mx="4"
                      color="red"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        onDeleteSelectedPermissionFromList(permissionIndex);
                      }}
                      data-testid="permissionListDeleteIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
      <Flex
        justify="end"
        marginTop="20px"
        gap="10px"
        position="fixed"
        right="10"
        top={window.innerHeight - 130}
      >
        <CustomButton
          buttonText="Add"
          variant="outline"
          style={{ height: '32px' }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={() => {
            onAddPermissionInPermissionList();
          }}
          leftIcon={<AddIcon />}
        />
        <CustomButton
          buttonText="Save"
          variant="outline"
          style={{ height: '32px' }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={() => {
            onSavePermission();
          }}
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
};

TenantPermissionMap.propTypes = {
  label: PropTypes.string,
};
TenantPermissionMap.defaultProps = {
  label: '',
};
export default TenantPermissionMap;
