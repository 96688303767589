import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
} from '@chakra-ui/react';

const CustomAccordion = ({
  accordionData,
  allowMultiple,
  allowToggle,
  initialExpandedIndexList,
}) => (
  <Accordion
    allowMultiple={allowMultiple}
    border="2px solid #E2E8F0"
    borderRadius="7px"
    allowToggle={allowToggle}
    reduceMotion
    defaultIndex={allowMultiple ? [initialExpandedIndexList]: 0} // tells us which panel(s) should expand on first render , should be an array in case allowMultiple is true
    data-testid="CustomAccordion"
  >
    {accordionData.map((item, index) => (
      <AccordionItem key={index} data-testid="CustomAccordionItem">
        <AccordionButton data-testid="CustomAccordionButton">
          <Box
            display="flex"
            textAlign="left"
            className='w-full'
            justifyContent="space-between"
            data-testid="CustomAccordionBox"
          >
            <Text fontWeight="bold" data-testid="CustomAccordionText">
              {item.title}
            </Text>
            <AccordionIcon data-testid="CustomAccordionIcon" />
          </Box>
        </AccordionButton>
        <AccordionPanel pb={4} data-testid="CustomAccordionPanel">
          {item.content}
        </AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);
CustomAccordion.propTypes = {
  accordionData: PropTypes.instanceOf(Array).isRequired,
  allowMultiple: PropTypes.bool,
  allowToggle: PropTypes.bool,
  initialExpandedIndexList: PropTypes.number,
};
CustomAccordion.defaultProps = {
  allowMultiple: true,
  allowToggle: true,
  initialExpandedIndexList: 0,
};
export default CustomAccordion;
