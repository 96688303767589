/* eslint-disable import/prefer-default-export */
import React from 'react';
import { CopyIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';

// commented these fields may be used in Future
export const specialFields = [
  { id: uuidv4(), label: 'Notes', type: 'SF_NOTES', width: 6 },
  { id: uuidv4(), label: 'History', type: 'SF_HISTORY', width: 6 },
  // { id: uuidv4(), label: 'Add to Knowledge Article', type: 'SF_KNOWLEDGEARTICLE', width: 6 },
  {
    id: uuidv4(),
    label: 'Linked Tickets',
    type: 'SF_LINKED_TICKETS',
    width: 6,
  },
  { id: uuidv4(), label: 'Watchers', type: 'SF_WATCHERS', width: 6 },
  { id: uuidv4(), label: 'Submitted By', type: 'SF_SUBMITTED_BY', width: 6 },
  { id: uuidv4(), label: 'Submitted On', type: 'SF_SUBMITTED_ON', width: 6 },
  { id: uuidv4(), label: 'Log Time', type: 'SF_LOG_TIME', width: 6 },
  {
    id: uuidv4(),
    label: 'Last Updated on',
    type: 'SF_LAST_UPDATED_ON',
    width: 6,
  },
  {
    id: uuidv4(),
    label: 'Last Updated By',
    type: 'SF_LAST_UPDATED_BY',
    width: 6,
  },
  {
    id: uuidv4(),
    label: 'Profile',
    type: 'SF_PROFILE',
    width: 6,
  },
  // { id: uuidv4(), label: 'Approvers', type: 'SF_APPROVERS', width: 6 },
  { id: uuidv4(), label: 'Chat Transcript', type: 'SF_CHAT', width: 6 },
  { id: uuidv4(), label: 'Smart Summary', type: 'SF_SMARTSUMMARY', width: 6 },
  // { id: uuidv4(), label: 'Linked Tickets Custom', type: 'SF_LINKED_TICKETS_CUSTOM', width: 6 }
  { id: uuidv4(), label: 'Feedback Ratings', type: 'SF_FEEDBACK_RATING', width: 12 },
  { id: uuidv4(), label: 'Feedback Comments', type: 'SF_FEEDBACK_COMMENT', width: 12 },

];

export const specialFieldsDeferred = [
  { id: uuidv4(), label: 'Feedback Ratings', type: 'SF_FEEDBACK_RATING', width: 12 },
  { id: uuidv4(), label: 'Feedback Comments', type: 'SF_FEEDBACK_COMMENT', width: 12 },
]

export const specialFieldsTransition = [
  { id: uuidv4(), label: 'Notes', type: 'SF_NOTES', width: 12 },
  { id: uuidv4(), label: 'Add to Knowledge Article', type: 'SF_KNOWLEDGEARTICLE', width: 12 },
];

export const checkForEditedFields = (arr, selectedTemplate) =>
  arr.map((i) => {
    if (i?.type === 'component' && i.subItems) {
      const updatedSubItems = i.subItems.map((ins) => {
        const matchedItem = selectedTemplate?.fields?.find(
          (item) => item.id === ins.id
        );
        if (matchedItem) {
          return { ...ins, label: matchedItem.label };
        }
        return ins;
      });
      return { ...i, subItems: updatedSubItems };
    }
    const matchedItem = selectedTemplate?.fields?.find(
      (item) => item.id === i.id
    );
    if (matchedItem) {
      return { ...i, label: matchedItem.label };
    }

    return i;
  });

export const switchObjectsOrderById = (array, id1, id2) => {
  const newArray = [...array];
  const index1 = newArray.findIndex((obj) => obj.id === id1);
  const index2 = newArray.findIndex((obj) => obj.id === id2);

  // Check if both IDs were found in the array
  if (index1 === -1 || index2 === -1) {
    return newArray; // Return the original array
  }
  // Swap the objects at the two indices
  [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]];

  return newArray;
};

export const LAYOUT_COLUMN = (showModal, onClone, onDelete) => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
  },
  {
    name: 'View type',
    col_name: 'viewType',
    type: 'string',
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px]">
        <EditIcon
          color="#94A3B8"
          className="cursor-pointer"
          data-testid={`template-layout-${rowData.name}-edit`}
          onClick={() => showModal({ isEdit: true, rowData })}
        />
       <DeleteIcon color="#94A3B8" data-testid={`template-layout-${rowData.name}-delete`} className="cursor-pointer" onClick={() => onDelete(rowData)} />
        <CopyIcon color="#94A3B8" data-testid={`template-layout-${rowData.name}-clone`} className="cursor-pointer" onClick={() => onClone(rowData)} />
      </div>
    ),
  },
];
