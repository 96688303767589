import React, { useEffect, useState } from 'react';

import { Box, Flex, InputGroup, InputRightElement } from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../../components/InputBox/Input';
import CustomTable from '../../../components/Table/Table';

const LayoutTableView = ({
  placeholder,
  columns,
  tableData,
  isLoading,
  onRowClick,
  searchKey,
  title,
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterData = debounce((e) => {
    const filteredFields = tableData.filter((field) =>
      field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  useEffect(() => {
    setFilteredData([...tableData]);
  }, [tableData]);

  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };

  return (
    <Box py="16px" data-testid="LayoutTableViewContainerBox">
      <Flex
        gap="15px"
        flexWrap="wrap"
        data-testid="LayoutTableViewContainerFlex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize="16px" fontWeight="600" lineHeight="normal">
          {title}
        </Box>
        <Box flex=".25" data-testid="LayoutTableViewInnerBox">
          <InputGroup size="l">
            <CustomInput
              placeholder={placeholder}
              type="text"
              size="sm"
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
                filterData(e);
              }}
              id="LayoutTableViewDivCInput"
            />
            {searchInputValue ? (
              <InputRightElement
                style={{ right: 12, cursor: 'pointer' }}
                // eslint-disable-next-line react/no-children-prop
                children={<CloseIcon color="#94A3B8" />}
                size="l"
                onClick={handleClearInput}
                className="calculatedHeight"
              />
            ) : (
              <InputRightElement
                style={{ right: 12, cursor: 'pointer' }}
                // eslint-disable-next-line react/no-children-prop
                children={<SearchIcon color="#94A3B8" />}
                size="l"
                className="calculatedHeight"
              />
            )}
          </InputGroup>
        </Box>
        {/* <CustomButton
          buttonText={buttonText}
          variant="outline"
          style={{ height: 40 }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={onCreateNew}
        /> */}
      </Flex>

      <Box my="16px" data-testid="LayoutTableViewTableContainerBox">
        <CustomTable
          columns={columns}
          height="calc(100vh - 20rem)"
          isTableLoading={isLoading}
          tableData={filteredData}
          onRowClick={onRowClick}
          title={title}
        />
      </Box>
    </Box>
  );
};

LayoutTableView.propTypes = {
  placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  searchKey: PropTypes.string,
  title: PropTypes.string,
};

LayoutTableView.defaultProps = {
  placeholder: 'Search',
  isLoading: false,
  onRowClick: undefined,
  searchKey: 'label',
  title: '',
};

export default LayoutTableView;
