/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { DARK, LIGHT } from '../../utils/enums/colors';

const CustomTextArea = ({
  id,
  label = '',
  value,
  placeholder,
  type = 'text',
  onChange,
  isInvalid,
  size,
  leftIcon,
  isError,
  helperText,
  errorMessage,
  isMandatory,
  customStyling,
  ...props
}) => (
  <FormControl
    isInvalid={isError}
    data-testid="CustomTextAreaFormControl"
    isRequired={isMandatory}
  >
    {label && <FormLabel className="SCLabel">{label}</FormLabel>}
    <InputGroup data-testid="CustomTextAreaInputGroup">
      {leftIcon && (
        <InputLeftElement pointerEvents="none" children={leftIcon} />
      )}
      <Textarea
        data-testid={id}
        placeholder={placeholder}
        _placeholder={{ opacity: 0.4, color: 'inherit' }}
        type={type}
        value={value}
        onChange={onChange}
        size={size}
        {...(customStyling)?{
          border:'1px solid #D1D5DB'
        }:{
          focusBorderColor:useColorModeValue(LIGHT, DARK),
          sx:{_focusVisible: {
            border:"1px solid #D1D5DB",
            boxShadow:"none"
          },}
        }}
        {...props}
      />
    </InputGroup>
    {!isError ? (
      <FormHelperText data-testid="CustomTextAreaFormHelperText">
        {helperText}
      </FormHelperText>
    ) : (
      <FormErrorMessage data-testid="CustomTextAreaFormErrorMessage">
        {errorMessage}
      </FormErrorMessage>
    )}
  </FormControl>
);
CustomTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  size: PropTypes.string,
  leftIcon: PropTypes.node,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isMandatory: PropTypes.bool,
  customStyling: PropTypes.bool,
};

CustomTextArea.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  size: 'md',
  leftIcon: '',
  isInvalid: false,
  isError: false,
  helperText: '',
  errorMessage: '',
  onBlur: () => {},
  onFocus: () => {},
  isMandatory: false,
};

export default CustomTextArea;
